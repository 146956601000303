import React from "react";
import BreadCrumb from "../components/common/BreadCrumb";
import CreateItemForm from "../components/CreateItemForm";
import Layout from "../components/layout/Layout";

const CreateItem = () => {
  return (
    <Layout title="Create Item">
      <BreadCrumb
        pageTitle="Create Item"
        parentPage="Home"
        prevPage="Pages"
        currentPage="Create Item"
      />
      <CreateItemForm />
    </Layout>
  );
};

export default CreateItem;
