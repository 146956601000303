import React from "react";
import ExploreLiveAuction from "../../components/auction/ExploreLiveAuction";
import LatestBlog from "../../components/blogs/LatestBlog";
import CollectionAlbumTwo from "../../components/collections/CollectionAlbumTwo";
import ExploreByCollector from "../../components/ExploreByCollector";
import Layout from "../../components/layout/Layout";
import CreatingStepTwo from "../../components/nft-create-step/CreatingStepTwo";
import ExploreTabs from "../../components/tabs/ExploreTabs";
import HeroThree from "./HeroThree";

export const HomeThree = () => {
  return (
    <Layout title="Home Three">
      <HeroThree />
      <ExploreByCollector />
      <ExploreTabs />
      <CollectionAlbumTwo />
      <CreatingStepTwo />
      <section className="auction-with-blog position-relative overflow-hidden">
        <img
          src="assets/img/shapes/circle-left-top-shape.svg"
          alt="shape"
          className="position-absolute auction-shape-top"
        />
        <img
          src="assets/img/shapes/circle-left-bottom-shape.svg"
          alt="shape"
          className="position-absolute auction-shape-bottom"
        />
        <img
          src="assets/img/shapes/circle-shape.svg"
          alt="shape"
          className="position-absolute auction-shape-right"
        />
        <ExploreLiveAuction />
        <LatestBlog />
      </section>
    </Layout>
  );
};
