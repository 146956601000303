import React from "react";
import { Link } from "react-router-dom";

export default function CtaOne() {
  return (
    <section className="nft3-call-to-action position-relative overflow-hidden"
    >
      <div className="container"
      style={{ marginTop: '200px' }}>
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="cta-img-wrap d-none d-lg-block">
              <img
                src="https://bafybeigej5h64zb4xecvadc342kf32sobd4d475xdku3manlw7vswfepxi.ipfs.nftstorage.link/"
                alt="cta "
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="cta-content ptb-50">
              <h2>Fractionalized Contract Farming</h2>
              <p>
              
PT. Padigital Graha Utama collaborates with farmers through Aggregators in contract farming. Conducting meticulous due diligence on land and crops, 
Padigital establishes legal contracts specifying crops, seeds, and terms. Shouldering production costs, Padigital becomes the offtaker, aiding farmers in optimizing crop growth. This unique model empowers farmers, ensures product quality, 
and fosters sustainable agriculture through transparent and equitable agreements, guaranteeing a market and fair partnerships in the agricultural value chain.
              </p>
           
            </div>
          </div>
        </div>
      </div>
      <img
        src="assets/img/shapes/cta-shape-right.png"
        alt="cta shape"
        className="cta-shape-right position-absolute"
      />
    </section>
  );
}
