import React from "react";
import Layout from "../components/layout/Layout";
import NftDNA1 from "../components/NftDNA1";
import NftNBA1 from "../components/NftNBA1";
import NftDNA2 from "../components/NftDNA2";
const ProductDNA2 = () => {
  return (
    <Layout title="Product DNA 2">
      <NftDNA2 />
      
      
    </Layout>
  );
};

export default ProductDNA2;
