import { blogDataTwo } from "../../utils/data";
import React from "react";
import { Link } from "react-router-dom";

export default function LatestBlog() {
  return (
    <div className="nft-blog-area pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="text-center title-heading">
              <h2>Latest NFTs Blog.</h2>
              <p>
                Bonorum et MalorumPartner with one of the worlds largest
                retailers to strategize prospective e-tiket showcase your brand
                and products.
              </p>
            </div>
          </div>
        </div>
        <div className="blog-content">
          <div className="row g-4">
            {blogDataTwo.map((data, i) => (
              <div className="col-lg-4 col-md-6" key={i + 1}>
                <div className="nft-blog-column">
                  <article>
                    <div className="figure-img mb-4">
                      <Link to={data.details}>
                        <img
                          src={data.thumb}
                          className="img-fluid"
                          alt={data.auhtorName}
                        />
                      </Link>
                    </div>
                    <Link to={data.details} className="blog-title">
                      <h2 className="h4">{data.title}</h2>
                    </Link>
                    <p>{data.exerpt}</p>
                    <div className="blog-btns">
                      <Link to="/blog-list">{data.tagOne}</Link>
                      <Link to="/blog-list">{data.tagTwo}</Link>
                    </div>
                    <hr className="spacer" />
                    <div className="blog-author d-flex align-items-center justify-content-between">
                      <div className="author-box d-flex align-items-center">
                        <img
                          src={data.authorImg}
                          className="img-fluid rounded-circle"
                          alt="author"
                        />
                        <div className="author-info">
                          <h6>{data.auhtorName}</h6>
                          <span>{data.date}</span>
                        </div>
                      </div>
                      <div className="viewmore-btn">
                        <Link to={data.details}>
                          <i className="fas fa-link"></i>
                        </Link>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
