import React from "react";

export default function Tags() {
  return (
    <div className="blog-sidebar-widget tags-widget">
      <div className="widget-title">
        <h4 className="h5">popular tags</h4>
      </div>
      <div className="tags">
        <a href="#!!">Creative</a>
        <a href="#!">art</a>
        <a href="#!">Development</a>
        <a href="#!">Digital product</a>
        <a href="#!">Agency</a>
        <a href="#!">Blockchain</a>
        <a href="#!">Crypto</a>
        <a href="#!">Landing</a>
      </div>
    </div>
  );
}
