import React from "react";

export default function CommentForm() {
  return (
    <div className="comment_form margin-top-60">
      <div className="comment-title margin-bottom-30">
        <h2 className="h5">Add your comment</h2>
      </div>
      <form action="#">
        <div className="row">
          <div className="col-md-6">
            <div className="input-field">
              <input type="text" placeholder="Name" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-field">
              <input type="email" placeholder="Email" />
            </div>
          </div>
          <div className="col-md-12">
            <div className="input-field">
              <textarea rows="6" placeholder="Write a Comments"></textarea>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="submit-btn">
              <button type="submit">Post comment</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
