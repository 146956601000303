import Pagination from "../Pagination";
import React from "react";
import { Link } from "react-router-dom";

export default function BlogPostList() {
  return (
    <div className="blog-content-part blog-list-bg-none p-0">
      <div className="blog-item">
        <div className="nft-blog-column">
          <article>
            <div className="figure-img mb-4">
              <Link to="/blog-details">
                <img
                  src="assets/img/blog-list-1.jpg"
                  className="img-fluid"
                  alt="feature"
                />
              </Link>
            </div>
            <Link to="/blog-details" className="blog-title">
              <h2 className="h4">
                Virtual Land in the Metaverse Is Selling for Millions of Dollars
              </h2>
            </Link>

            <p>
              Purpose of lorem ipsum is to create a natural looking block of
              text sentence, paragraph, page, A practice laying out pages with
              meaningless filler text.
            </p>
            <div className="blog-btns">
              <Link to="/blog-grid">Cryptocurrency</Link>
              <Link to="/blog-grid">NFT token</Link>
            </div>
            <hr className="spacer"></hr>
            <div className="blog-author">
              <div className="author-box d-flex align-items-center">
                <div className="thumb-wrapper">
                  <img
                    src="assets/img/blog-author-1.jpg"
                    className="img-fluid rounded-circle"
                    alt="author"
                  />
                </div>
                <div className="author-info">
                  <h6>Victor b.Dimouro</h6>
                  <span>08 April 2022</span>
                </div>
              </div>
              <div className="viewmore-btn">
                <Link to="/blog-details">
                  <i className="fa-solid fa-link"></i>
                </Link>
              </div>
            </div>
          </article>
        </div>
      </div>
      <div className="blog-item">
        <div className="nft-blog-column">
          <article>
            <div className="figure-img mb-4">
              <Link to="/blog-details">
                <img
                  src="assets/img/blog-list-2.jpg"
                  className="img-fluid"
                  alt="feature"
                />
              </Link>
            </div>
            <Link to="/blog-details" className="blog-title">
              <h2 className="h4">
                Virtual Land in the Metaverse Is Selling for Millions of Dollars
              </h2>
            </Link>
            <p>
              Purpose of lorem ipsum is to create a natural looking block of
              text sentence, paragraph, page, A practice laying out pages with
              meaningless filler text.
            </p>
            <div className="blog-btns">
              <Link to="/blog-grid">Nftically</Link>
              <Link to="/blog-grid">NFT token</Link>
            </div>
            <hr className="spacer"></hr>
            <div className="blog-author">
              <div className="author-box d-flex align-items-center">
                <div className="thumb-wrapper">
                  <img
                    src="assets/img/blog-author-2.jpg"
                    className="img-fluid rounded-circle"
                    alt="author"
                  />
                </div>
                <div className="author-info">
                  <h6>Victor b.Dimouro</h6>
                  <span>08 April 2022</span>
                </div>
              </div>
              <div className="viewmore-btn">
                <Link to="/blog-details">
                  <i className="fa-solid fa-link"></i>
                </Link>
              </div>
            </div>
          </article>
        </div>
      </div>
      <div className="blog-item">
        <div className="nft-blog-column">
          <article>
            <div className="figure-img mb-4">
              <Link to="/blog-details">
                <img
                  src="assets/img/blog-list-3.jpg"
                  className="img-fluid"
                  alt="feature"
                />
              </Link>
            </div>
            <Link to="/blog-details" className="blog-title">
              <h2 className="h4">
                Virtual Land in the Metaverse Is Selling for Millions of Dollars
              </h2>
            </Link>
            <p>
              Purpose of lorem ipsum is to create a natural looking block of
              text sentence, paragraph, page, A practice laying out pages with
              meaningless filler text.
            </p>
            <div className="blog-btns">
              <Link to="/blog-grid">Airdropes</Link>
              <Link to="/blog-grid">NFT</Link>
            </div>
            <hr className="spacer"></hr>
            <div className="blog-author">
              <div className="author-box d-flex align-items-center">
                <div className="thumb-wrapper">
                  <img
                    src="assets/img/blog-author-1.jpg"
                    className="img-fluid rounded-circle"
                    alt="author"
                  />
                </div>
                <div className="author-info">
                  <h6>Victor b.Dimouro</h6>
                  <span>08 April 2022</span>
                </div>
              </div>
              <div className="viewmore-btn">
                <Link to="/blog-details">
                  <i className="fa-solid fa-link"></i>
                </Link>
              </div>
            </div>
          </article>
        </div>
      </div>
      <div className="blog-item">
        <div className="nft-blog-column">
          <article>
            <div className="figure-img mb-4">
              <Link to="/blog-details">
                <img
                  src="assets/img/blog-list-4.jpg"
                  className="img-fluid"
                  alt="feature"
                />
              </Link>
            </div>
            <Link to="/blog-details" className="blog-title">
              <h2 className="h4">
                Virtual Land in the Metaverse Is Selling for Millions of Dollars
              </h2>
            </Link>
            <p>
              Purpose of lorem ipsum is to create a natural looking block of
              text sentence, paragraph, page, A practice laying out pages with
              meaningless filler text.
            </p>
            <div className="blog-btns">
              <Link to="/blog-grid">Metavers</Link>
              <Link to="/blog-grid">NFT token</Link>
            </div>
            <hr className="spacer"></hr>
            <div className="blog-author">
              <div className="author-box d-flex align-items-center">
                <div className="thumb-wrapper">
                  <img
                    src="assets/img/blog-author-2.jpg"
                    className="img-fluid rounded-circle"
                    alt="author"
                  />
                </div>
                <div className="author-info">
                  <h6>Victor b.Dimouro</h6>
                  <span>08 April 2022</span>
                </div>
              </div>
              <div className="viewmore-btn">
                <Link to="/blog-details">
                  <i className="fa-solid fa-link"></i>
                </Link>
              </div>
            </div>
          </article>
        </div>
      </div>
      <Pagination />
    </div>
  );
}
