import React from "react";

export default function Comments() {
  return (
    <div className="blog-comments">
      <div className="comment-title">
        <h4 className="h5">Comments(3)</h4>
      </div>
      <ul className="comments-list">
        <li>
          <div className="comment-box">
            <div className="thumb-wrapper">
              <img
                src="assets/img/cc-author-1.png"
                className="img-fluid"
                alt="author"
              />
            </div>
            <div className="comment-box-right">
              <div className="user_box_top">
                <div className="user_title">
                  <h6>Rosalina kelian</h6>
                </div>
                <div className="comment_date">
                  <span>Oct 28, 2020</span>
                </div>
              </div>
              <div className="user_comment">
                <p>
                  We are excited to announce two new investment programs aimed
                  at advancing the growth of web3.
                </p>
              </div>
              <div className="reply-btn">
                <button type="button">reply</button>
              </div>
            </div>
          </div>
        </li>
        <li className="margin-top-20 margin-left-80">
          <div className="comment-box">
            <div className="thumb-wrapper">
              <img
                src="assets/img/cc-author-2.png"
                className="img-fluid"
                alt="author"
              />
            </div>
            <div className="comment-box-right">
              <div className="user_box_top">
                <div className="user_title">
                  <h6>Rosalina kelian</h6>
                </div>
                <div className="comment_date">
                  <span>Oct 28, 2020</span>
                </div>
              </div>
              <div className="user_comment">
                <p>
                  We are excited to announce two new investment programs aimed
                  at advancing the growth of web3.
                </p>
              </div>
              <div className="reply-btn">
                <button type="button">reply</button>
              </div>
            </div>
          </div>
        </li>
        <li className="margin-top-20">
          <div className="comment-box">
            <div className="thumb-wrapper">
              <img
                src="assets/img/cc-author-3.png"
                className="img-fluid"
                alt="author"
              />
            </div>
            <div className="comment-box-right">
              <div className="user_box_top">
                <div className="user_title">
                  <h6>Rosalina kelian</h6>
                </div>
                <div className="comment_date">
                  <span>Oct 28, 2020</span>
                </div>
              </div>
              <div className="user_comment">
                <p>
                  We are excited to announce two new investment programs aimed
                  at advancing the growth of web3.
                </p>
              </div>
              <div className="reply-btn">
                <button type="button">reply</button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}
