import React from "react";

const BrandOne = () => {
  return (
    <div className="container">
      <div className="brand-section position-relative box-shadow-color w-100 rounded-custom p-4 p-md-5">
        <div className="container">
          <div className="row g-3">
            <div className="col-lg-3 col-md-4 col-6">
              <div className="brands-thumb mx-auto text-center">
                <img
                  src="public\assets\img\logo\skytopia logo 1.2.jpg"
                  alt="brand "
                  className="img-fluid"
                  width="120"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <div className="brands-thumb mx-auto text-center">
                <img
                  src="public\assets\img\logo\skytopia logo 1.2.jpg"
                  alt="brand "
                  className="img-fluid"
                  width="120"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <div className="brands-thumb mx-auto text-center">
                <img
                  src="assets/img/home2/brand-3.png"
                  alt="brand "
                  className="img-fluid"
                  width="120"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <div className="brands-thumb mx-auto text-center">
                <img
                  src="assets/img/home2/brand-4.png"
                  alt="brand "
                  className="img-fluid"
                  width="120"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandOne;
