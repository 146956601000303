import React from "react";
import Layout from "../components/layout/Layout";
import NftDNA3 from "../components/NftDNA3";

const ProductDNA3 = () => {
  return (
    <Layout title="Product DNA 3">
      <NftDNA3 />
      
      
    </Layout>
  );
};

export default ProductDNA3;
