import React from "react";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";

const counter = ({ days, hours, minutes, seconds, completed }) => {
  if (!completed) {
    // Render a countdown
    return (
      <ul className="downcount-timer d-flex align-items-center mt-2">
        <li className="rounded">
          <h5 className="days mb-0">{days}</h5>
        </li>
        <li className="rounded">
          <h5 className="hours mb-0">{hours}</h5>
        </li>
        <li className="rounded">
          <h5 className="minutes mb-0">{minutes}</h5>
        </li>
        <li className="rounded">
          <h5 className="seconds mb-0">{seconds}</h5>
        </li>
      </ul>
    );
  }
};

const AuctionProductDetails = () => {
  return (
    <section className="nft-product-details position-relative overflow-hidden ptb-120">
      <img
        src="assets/img/shapes/shape-blog-detail-left-bottom.svg"
        alt="shape"
        className="shape-top-left position-absolute"
      />
      <div className="container">
        <div className="product-details rounded">
          <div className="row">
            <div className="col-lg-6">
              <div className="pd-left">
                <div className="pd-slider">
                  <div
                    id="controls"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img
                          src="assets/img/product-1.jpg"
                          className="img-fluid"
                          alt="product thumbnail"
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src="assets/img/product-2.jpg"
                          className="img-fluid"
                          alt="product thumbnail"
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src="assets/img/product-3.jpg"
                          className="img-fluid"
                          alt="product thumbnail"
                        />
                      </div>
                    </div>
                    <div className="carousel-indicators">
                      <button
                        type="button"
                        data-bs-target="#controls"
                        data-bs-slide-to="0"
                        className="active"
                        aria-current="true"
                        aria-label="Slide 1"
                      >
                        <img
                          src="assets/img/product-thumb-1.jpg"
                          className="img-fluid"
                          alt="product"
                        />
                      </button>
                      <button
                        type="button"
                        data-bs-target="#controls"
                        data-bs-slide-to="1"
                        aria-label="Slide 2"
                      >
                        <img
                          src="assets/img/product-thumb-2.jpg"
                          className="img-fluid"
                          alt="product"
                        />
                      </button>
                      <button
                        type="button"
                        data-bs-target="#controls"
                        data-bs-slide-to="2"
                        aria-label="Slide 3"
                      >
                        <img
                          src="assets/img/product-thumb-3.jpg"
                          className="img-fluid"
                          alt="product"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="pd-right">
                <div className="pd-title">
                  <h2 className="h3">Digital 3D Cryptoplastic</h2>
                </div>
                <div className="pd-author-box">
                  <div className="single-author d-flex align-items-center">
                    <div className="thumb-wrapper">
                      <img
                        src="assets/img/creator-1.jpg"
                        alt="nft creator"
                        width="45"
                        className="img-fluid"
                      />
                    </div>
                    <div className="author-info">
                      <span>Collectors</span>
                      <h6>Molly J.Brown</h6>
                    </div>
                  </div>
                  <div className="single-author d-flex align-items-center">
                    <div className="thumb-wrapper">
                      <img
                        src="assets/img/creator-2.jpg"
                        alt="nft creator"
                        width="45"
                        className="img-fluid"
                      />
                    </div>
                    <div className="author-info">
                      <span>Creator</span>
                      <h6>Sport Palstic</h6>
                    </div>
                  </div>
                </div>
                <div className="pd-desc margin-top-30">
                  <p>
                    NFT collections, including EtherRocks and CryptoPunks are
                    examples of generative art, where many different s can be
                    created by
                  </p>
                </div>
                <div className="product-auction d-block d-sm-flex align-items-center justify-content-between margin-top-30">
                  <div className="bid-amount">
                    <span>Current price</span>
                    <h3 className="text-white">1.05 ETH</h3>
                  </div>
                  <div className="pd-auction-timing mt-3 mt-sm-0">
                    <span>Auction Ending In</span>
                    <Countdown
                      date={Date.now() + 50000000000}
                      renderer={counter}
                    />
                  </div>
                </div>
                <div className="pd-btns margin-top-30">
                  <div className="primary-btn">
                    <Link to="/wallet">Place a Bid</Link>
                  </div>
                  <div className="offer-btn">
                    <Link to="/wallet">Make an offer</Link>
                  </div>
                </div>
                <div className="pd-tab">
                  <ul className="nav nav-tabs">
                    <li>
                      <button
                        className="active"
                        data-bs-toggle="tab"
                        data-bs-target="#history"
                      >
                        Bids History
                      </button>
                    </li>
                    <li>
                      <button data-bs-toggle="tab" data-bs-target="#details">
                        Details
                      </button>
                    </li>
                    <li>
                      <button data-bs-toggle="tab" data-bs-target="#activity">
                        Activity
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div className="tab-pane fade active show" id="history">
                      <ul className="history-list">
                        <li className="d-block d-sm-flex align-items-center justify-content-between pt-3">
                          <div className="history-left d-flex align-items-center">
                            <img
                              src="assets/img/pd-author-1.png"
                              className="img-fluid rounded-circle"
                              alt="pd author"
                            />
                            <div className="history-info ms-3">
                              <p className="mb-0">
                                <Link to="/creator-profile">
                                  @bessie cooper <span>placed a bid</span>
                                </Link>
                              </p>
                              <span className="d-block">2 hours ago</span>
                            </div>
                          </div>
                          <div className="history-amount mt-3 mt-sm-0">
                            <h5 className="mb-0">3.25 ETH</h5>
                            <span className="d-block">(= $795.48)</span>
                          </div>
                        </li>
                        <li className="d-block d-sm-flex align-items-center justify-content-between pt-3">
                          <div className="history-left d-flex align-items-center">
                            <img
                              src="assets/img/pd-author-2.png"
                              className="img-fluid rounded-circle"
                              alt="pd author"
                            />
                            <div className="history-info ms-3">
                              <p className="mb-0">
                                <Link to="/creator-profile">
                                  @guy hawkins <span>placed a bid</span>
                                </Link>
                              </p>
                              <span className="d-block">3 hours ago</span>
                            </div>
                          </div>
                          <div className="history-amount mt-3 mt-sm-0">
                            <h5 className="mb-0">2.25 ETH</h5>
                            <span className="d-block">(= $695.48)</span>
                          </div>
                        </li>
                        <li className="d-block d-sm-flex align-items-center justify-content-between pt-3">
                          <div className="history-left d-flex align-items-center">
                            <img
                              src="assets/img/pd-author-3.png"
                              className="img-fluid rounded-circle"
                              alt="pd author"
                            />
                            <div className="history-info ms-3">
                              <p className="mb-0">
                                <Link to="/creator-profile">
                                  @molly j.brown <span>placed a bid</span>
                                </Link>
                              </p>
                              <span className="d-block">2 hours ago</span>
                            </div>
                          </div>
                          <div className="history-amount mt-3 mt-sm-0">
                            <h5 className="mb-0">6.25 ETH</h5>
                            <span className="d-block">(= $995.48)</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-pane fade" id="details">
                      <p>
                        Some NFT collections, including EtherRocks and
                        CryptoPunks are examples of generative art, where many
                        different s can be created by assembling a selection of
                        simple picture components in different combinations.
                      </p>
                      <p>
                        In March 2021, the blockchain company Injective Protocol
                        bought a original screen print entitled Morons White
                        from English graffiti artist Banksyfilmed physical work
                        of art to the NFT space.
                      </p>
                    </div>
                    <div className="tab-pane fade" id="activity">
                      <p>
                        In March 2021, the blockchain company Injective Protocol
                        bought a original screen print entitled Morons White
                        from English graffiti artist Banksyfilmed physical work
                        of art to the NFT space.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AuctionProductDetails;
