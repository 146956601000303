import React from "react";

import Layout from "../../components/layout/Layout";
import PromoOne from "../../components/promo/PromoOne";
import StepOne from "../../components/steps/StepOne";
import PartnersBrand from "../../components/brands/PartnersBrand";
import FaqTabs from "../../components/tabs/FaqTabs";
import HotDrops from "../../components/HotDrops";
import NftCreatingSteps from "../../components/nft-create-step/NftCreatingSteps";

import CtaOne from "../../components/cta/CtaOne";
import ActivityTable from "../../components/ActivityTable"
export default function HomeOne() {
  return (
    <Layout title="Padigital">
      <CtaOne style={{ marginTop: '200px' }}/>
      <HotDrops style={{ marginTop: '200px' }}/>
      <ActivityTable style={{ marginTop: '200px' }}/>
 
      <PromoOne style={{ marginBottom: '200px' }} />
      <NftCreatingSteps/>
      <StepOne style={{ marginBottom: '200px' }}/>
       <FaqTabs />
      
      <PartnersBrand style={{ marginTop: '200px' }}/>
     
      
    </Layout>
  );
}
