import React from "react";
import { Link } from "react-router-dom";

const CreatorProfileTab = () => {
  return (
    <>
      <section
        className="nft-breadcrumb"
        style={{ backgroundImage: "url('assets/img/breadcrumb-bg.jpg')" }}
      ></section>
      <section className="creator-profile-area position-relative overflow-hidden">
        <img
          src="assets/img/shapes/hero-top-left.svg"
          alt="shape"
          className="shape-top-left position-absolute"
        />
        <img
          src="assets/img/shapes/dark-mode-gradient.svg"
          alt="shape"
          className="position-absolute center-shape"
        />
        <div className="creator-profile pb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="profile-box">
                  <div className="profile-img">
                    <div className="thumb-wrapper">
                      <img
                        src="assets/img/creator-profile.png"
                        className="img-fluid rounded-circle"
                        alt="profile"
                      />
                    </div>
                    <div className="profile-change">
                      <button type="button">
                        <i className="fab fa-instagram"></i>
                      </button>
                    </div>
                  </div>
                  <div className="profile-title">
                    <h6 className="text-white">Mr. David Bissonette</h6>
                    <span>@bissonette</span>
                  </div>
                  <div className="profile-meta">
                    <span>
                      <i className="fa-solid fa-list-ul"></i>250 items
                    </span>
                    <span>
                      <i className="fa-solid fa-eye"></i>2.5k views
                    </span>
                  </div>
                  <div className="follow-btn">
                    <a
                      to="https://www.facebook.com/themetags"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button>Follow Me</button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 align-self-end">
                <div className="profile-right">
                  <h5 className="mb-3">Description</h5>
                  <div className="profile-desc">
                    <p>
                      Depending on the type of application, NFTs can get
                      programmed with unique properties. Hence, this means to
                      say that NFTs are programmable data files in the
                      metaverse. Likewise, we’ve seen digital characters that
                      confer unique benefits and other properties.
                    </p>
                  </div>
                  <div className="profile-social mt-4">
                    <h5>Follow on</h5>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="social-item">
                          <a
                            to="https://www.facebook.com/themetags"
                            target="_blank"
                            rel="noreferrer"
                            className="facebook"
                          >
                            <i className="fab fa-facebook"></i>
                            <span> facebook.com/neftron</span>
                          </a>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="social-item">
                          <a
                            to="https://www.facebook.com/themetags"
                            target="_blank"
                            rel="noreferrer"
                            className="youtube"
                          >
                            <i className="fab fa-youtube"></i>
                            <span>youtube .com/neftron</span>
                          </a>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="social-item">
                          <a
                            to="https://www.facebook.com/themetags"
                            target="_blank"
                            rel="noreferrer"
                            className="twitter"
                          >
                            <i className="fab fa-twitter"></i>
                            <span>twitter .com/neftron</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="creator-profile-tab pb-120">
          <div className="container">
            <div className="row">
              <div className="ctp-tab">
                <ul className="nav nav-tabs">
                  <li>
                    <button
                      data-bs-toggle="tab"
                      data-bs-target="#edit_profile"
                      type="button"
                    >
                      Edit Profile
                    </button>
                  </li>
                  <li>
                    <button
                      className="active"
                      data-bs-toggle="tab"
                      data-bs-target="#profile_collection"
                      type="button"
                    >
                      collection
                    </button>
                  </li>
                  <li>
                    <button
                      data-bs-toggle="tab"
                      data-bs-target="#profile_liked"
                      type="button"
                    >
                      liked
                    </button>
                  </li>
                  <li>
                    <button
                      data-bs-toggle="tab"
                      data-bs-target="#profile_activity"
                      type="button"
                    >
                      activity
                    </button>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane fade" id="edit_profile">
                    <div className="profile-form">
                      <form action="#">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="input-field">
                              <label htmlFor="fname">Name</label>
                              <input
                                type="text"
                                id="fname"
                                defaultValue="Steven Robart"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-field">
                              <label htmlFor="username">Username</label>
                              <input
                                type="txt"
                                id="username"
                                defaultValue="s_rob"
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-field">
                              <label htmlFor="email">Email</label>
                              <input
                                type="email"
                                id="email"
                                defaultValue="support@themetags.com"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-field">
                              <label htmlFor="profile-pic">
                                Profile Picture
                              </label>
                              <input type="file" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-field">
                              <label htmlFor="currency">Currency</label>
                              <div className="select-field">
                                <select>
                                  <option>ETH</option>
                                  <option>USD</option>
                                  <option>EURO</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-field">
                              <label htmlFor="fusername">
                                Facebook username
                              </label>
                              <input
                                type="text"
                                id="fusername"
                                defaultValue="themetags"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-field">
                              <label htmlFor="yusername">
                                Youtube username
                              </label>
                              <input
                                type="text"
                                id="yusername"
                                defaultValue="themetags"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-field">
                              <label htmlFor="tusername">
                                twitter username
                              </label>
                              <input
                                type="text"
                                id="tusername"
                                defaultValue="themetags"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="input-field">
                              <label htmlFor="bio">Bio</label>
                              <textarea rows="6" id="bio"></textarea>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="submit-btn">
                              <button>Update profile</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade show active"
                    id="profile_collection"
                  >
                    <div className="row g-4">
                      <div className="col-lg-4 col-xl-3 col-md-6">
                        <div className="single-nft-item">
                          <div className="nft-item-wrapper rounded">
                            <Link
                              to="/product-details"
                              className="item-image position-relative"
                            >
                              <img
                                src="assets/img/filter-1.jpg"
                                alt="nft item"
                                className="img-fluid rounded-top"
                              />
                            </Link>
                            <div className="buy-now-btn primary-btn">
                              <Link to="/product-details">buy now</Link>
                            </div>
                            <div className="nft-item-content-wrap pt-4">
                              <Link to="/product-details">
                                <h2 className="h6">Lee complete #100</h2>
                              </Link>

                              <hr />
                              <div className="nft-item-footer d-flex align-items-center justify-content-between">
                                <div className="item-price-wrapper d-flex align-items-center">
                                  <span>
                                    <i className="fab fa-ethereum"></i>
                                  </span>
                                  <div className="item-price ms-3">
                                    <p className="mb-0">Reserve price</p>
                                    <h5 className="mb-0">0.0478 ETH</h5>
                                  </div>
                                </div>
                                <span className="item-like">
                                  <i className="fa-solid fa-heart"></i> 15k Like
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-xl-3 col-md-6">
                        <div className="single-nft-item">
                          <div className="nft-item-wrapper rounded">
                            <Link
                              to="/product-details"
                              className="item-image position-relative"
                            >
                              <img
                                src="assets/img/filter-2.jpg"
                                alt="nft item"
                                className="img-fluid rounded-top"
                              />
                            </Link>

                            <div className="buy-now-btn primary-btn">
                              <Link to="/product-details">buy now</Link>
                            </div>
                            <div className="nft-item-content-wrap pt-4">
                              <Link to="/product-details">
                                <h2 className="h6">PharaGods-NFT</h2>
                              </Link>
                              <hr />
                              <div className="nft-item-footer d-flex align-items-center justify-content-between">
                                <div className="item-price-wrapper d-flex align-items-center">
                                  <span>
                                    <i className="fab fa-ethereum"></i>
                                  </span>
                                  <div className="item-price ms-3">
                                    <p className="mb-0">Reserve price</p>
                                    <h5 className="mb-0">0.0521 ETH</h5>
                                  </div>
                                </div>
                                <span className="item-like">
                                  <i className="fa-solid fa-heart"></i> 5k Like
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-xl-3 col-md-6">
                        <div className="single-nft-item">
                          <div className="nft-item-wrapper rounded">
                            <Link
                              to="/product-details"
                              className="item-image position-relative"
                            >
                              <img
                                src="assets/img/filter-3.jpg"
                                alt="nft item"
                                className="img-fluid rounded-top"
                              />
                            </Link>
                            <div className="buy-now-btn primary-btn">
                              <Link to="/product-details">buy now</Link>
                            </div>
                            <div className="nft-item-content-wrap pt-4">
                              <Link to="/product-details">
                                <h2 className="h6">Project Arowana Prime</h2>
                              </Link>
                              <hr />
                              <div className="nft-item-footer d-flex align-items-center justify-content-between">
                                <div className="item-price-wrapper d-flex align-items-center">
                                  <span>
                                    <i className="fab fa-ethereum"></i>
                                  </span>
                                  <div className="item-price ms-3">
                                    <p className="mb-0">Reserve price</p>
                                    <h5 className="mb-0">0.0421 ETH</h5>
                                  </div>
                                </div>
                                <span className="item-like">
                                  <i className="fa-solid fa-heart"></i> 10k Like
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-xl-3 col-md-6">
                        <div className="single-nft-item">
                          <div className="nft-item-wrapper rounded">
                            <Link
                              to="/product-details"
                              className="item-image position-relative"
                            >
                              <img
                                src="assets/img/filter-4.jpg"
                                alt="nft item"
                                className="img-fluid rounded-top"
                              />
                            </Link>

                            <div className="buy-now-btn primary-btn">
                              <Link to="/product-details">buy now</Link>
                            </div>
                            <div className="nft-item-content-wrap pt-4">
                              <Link to="/product-details">
                                <h2 className="h6">Time of Roger</h2>
                              </Link>
                              <hr />
                              <div className="nft-item-footer d-flex align-items-center justify-content-between">
                                <div className="item-price-wrapper d-flex align-items-center">
                                  <span>
                                    <i className="fab fa-ethereum"></i>
                                  </span>
                                  <div className="item-price ms-3">
                                    <p className="mb-0">Reserve price</p>
                                    <h5 className="mb-0">0.0478 ETH</h5>
                                  </div>
                                </div>
                                <span className="item-like">
                                  <i className="fa-solid fa-heart"></i> 15k Like
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-xl-3 col-md-6">
                        <div className="single-nft-item">
                          <div className="nft-item-wrapper rounded">
                            <Link
                              to="/product-details"
                              className="item-image position-relative"
                            >
                              <img
                                src="assets/img/filter-5.jpg"
                                alt="nft item"
                                className="img-fluid rounded-top"
                              />
                            </Link>
                            <div className="buy-now-btn primary-btn">
                              <Link to="/product-details">buy now</Link>
                            </div>
                            <div className="nft-item-content-wrap pt-4">
                              <Link to="/product-details">
                                <h2 className="h6">GoldSilver Pirates</h2>
                              </Link>
                              <hr />
                              <div className="nft-item-footer d-flex align-items-center justify-content-between">
                                <div className="item-price-wrapper d-flex align-items-center">
                                  <span>
                                    <i className="fab fa-ethereum"></i>
                                  </span>
                                  <div className="item-price ms-3">
                                    <p className="mb-0">Reserve price</p>
                                    <h5 className="mb-0">0.0032 ETH</h5>
                                  </div>
                                </div>
                                <span className="item-like">
                                  <i className="fa-solid fa-heart"></i> 12k Like
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-xl-3 col-md-6">
                        <div className="single-nft-item">
                          <div className="nft-item-wrapper rounded">
                            <Link
                              to="/product-details"
                              className="item-image position-relative"
                            >
                              <img
                                src="assets/img/filter-6.jpg"
                                alt="nft item"
                                className="img-fluid rounded-top"
                              />
                            </Link>
                            <div className="buy-now-btn primary-btn">
                              <Link to="/product-details">buy now</Link>
                            </div>
                            <div className="nft-item-content-wrap pt-4">
                              <Link to="/product-details">
                                <h2 className="h6">Anonymous Genesis</h2>
                              </Link>
                              <hr />
                              <div className="nft-item-footer d-flex align-items-center justify-content-between">
                                <div className="item-price-wrapper d-flex align-items-center">
                                  <span>
                                    <i className="fab fa-ethereum"></i>
                                  </span>
                                  <div className="item-price ms-3">
                                    <p className="mb-0">Reserve price</p>
                                    <h5 className="mb-0">0.00538 ETH</h5>
                                  </div>
                                </div>
                                <span className="item-like">
                                  <i className="fa-solid fa-heart"></i> 11k Like
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-xl-3 col-md-6">
                        <div className="single-nft-item">
                          <div className="nft-item-wrapper rounded">
                            <Link
                              to="/product-details"
                              className="item-image position-relative"
                            >
                              <img
                                src="assets/img/filter-6.jpg"
                                alt="nft item"
                                className="img-fluid rounded-top"
                              />
                            </Link>
                            <div className="buy-now-btn primary-btn">
                              <Link to="/product-details">buy now</Link>
                            </div>
                            <div className="nft-item-content-wrap pt-4">
                              <Link to="/product-details">
                                <h2 className="h6">Anonymous Genesis</h2>
                              </Link>
                              <hr />
                              <div className="nft-item-footer d-flex align-items-center justify-content-between">
                                <div className="item-price-wrapper d-flex align-items-center">
                                  <span>
                                    <i className="fab fa-ethereum"></i>
                                  </span>
                                  <div className="item-price ms-3">
                                    <p className="mb-0">Reserve price</p>
                                    <h5 className="mb-0">0.00538 ETH</h5>
                                  </div>
                                </div>
                                <span className="item-like">
                                  <i className="fa-solid fa-heart"></i> 11k Like
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-xl-3 col-md-6">
                        <div className="single-nft-item">
                          <div className="nft-item-wrapper rounded">
                            <Link
                              to="/product-details"
                              className="item-image position-relative"
                            >
                              <img
                                src="assets/img/filter-8.jpg"
                                alt="nft item"
                                className="img-fluid rounded-top"
                              />
                            </Link>
                            <div className="buy-now-btn primary-btn">
                              <Link to="/product-details">buy now</Link>
                            </div>
                            <div className="nft-item-content-wrap pt-4">
                              <Link to="/product-details">
                                <h2 className="h6">NeoTokyo Citizens</h2>
                              </Link>
                              <hr />
                              <div className="nft-item-footer d-flex align-items-center justify-content-between">
                                <div className="item-price-wrapper d-flex align-items-center">
                                  <span>
                                    <i className="fab fa-ethereum"></i>
                                  </span>
                                  <div className="item-price ms-3">
                                    <p className="mb-0">Reserve price</p>
                                    <h5 className="mb-0">0.0278 ETH</h5>
                                  </div>
                                </div>
                                <span className="item-like">
                                  <i className="fa-solid fa-heart"></i> 9k Like
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="profile_liked">
                    <div className="row g-4">
                      <div className="col-lg-4 col-xl-3 col-md-6">
                        <div className="single-nft-item">
                          <div className="nft-item-wrapper rounded">
                            <Link
                              to="/product-details"
                              className="item-image position-relative"
                            >
                              <img
                                src="assets/img/filter-1.jpg"
                                alt="nft item"
                                className="img-fluid rounded-top"
                              />
                            </Link>
                            <div className="buy-now-btn primary-btn">
                              <Link to="/product-details">buy now</Link>
                            </div>
                            <div className="nft-item-content-wrap pt-4">
                              <Link to="/product-details">
                                <h2 className="h6">Lee complete #100</h2>
                              </Link>
                              <hr />
                              <div className="nft-item-footer d-flex align-items-center justify-content-between">
                                <div className="item-price-wrapper d-flex align-items-center">
                                  <span>
                                    <i className="fab fa-ethereum"></i>
                                  </span>
                                  <div className="item-price ms-3">
                                    <p className="mb-0">Reserve price</p>
                                    <h5 className="mb-0">0.0478 ETH</h5>
                                  </div>
                                </div>
                                <span className="item-like">
                                  <i className="fa-solid fa-heart"></i> 15k Like
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-xl-3 col-md-6">
                        <div className="single-nft-item">
                          <div className="nft-item-wrapper rounded">
                            <Link
                              to="/product-details"
                              className="item-image position-relative"
                            >
                              <img
                                src="assets/img/filter-2.jpg"
                                alt="nft item"
                                className="img-fluid rounded-top"
                              />
                            </Link>
                            <div className="buy-now-btn primary-btn">
                              <Link to="/product-details">buy now</Link>
                            </div>
                            <div className="nft-item-content-wrap pt-4">
                              <Link to="/product-details">
                                <h2 className="h6">PharaGods-NFT</h2>
                              </Link>
                              <hr />
                              <div className="nft-item-footer d-flex align-items-center justify-content-between">
                                <div className="item-price-wrapper d-flex align-items-center">
                                  <span>
                                    <i className="fab fa-ethereum"></i>
                                  </span>
                                  <div className="item-price ms-3">
                                    <p className="mb-0">Reserve price</p>
                                    <h5 className="mb-0">0.0521 ETH</h5>
                                  </div>
                                </div>
                                <span className="item-like">
                                  <i className="fa-solid fa-heart"></i> 5k Like
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-xl-3 col-md-6">
                        <div className="single-nft-item">
                          <div className="nft-item-wrapper rounded p-3">
                            <Link
                              to="/product-details"
                              className="item-image position-relative"
                            >
                              <img
                                src="assets/img/filter-3.jpg"
                                alt="nft item"
                                className="img-fluid rounded-top"
                              />
                            </Link>
                            <div className="buy-now-btn primary-btn">
                              <Link to="/product-details">buy now</Link>
                            </div>
                            <div className="nft-item-content-wrap pt-4">
                              <Link to="/product-details">
                                <h2 className="h6">Project Arowana Prime</h2>
                              </Link>
                              <hr />
                              <div className="nft-item-footer d-flex align-items-center justify-content-between">
                                <div className="item-price-wrapper d-flex align-items-center">
                                  <span>
                                    <i className="fab fa-ethereum"></i>
                                  </span>
                                  <div className="item-price ms-3">
                                    <p className="mb-0">Reserve price</p>
                                    <h5 className="mb-0">0.0421 ETH</h5>
                                  </div>
                                </div>
                                <span className="item-like">
                                  <i className="fa-solid fa-heart"></i> 10k Like
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-xl-3 col-md-6">
                        <div className="single-nft-item">
                          <div className="nft-item-wrapper rounded p-3">
                            <Link
                              to="/product-details"
                              className="item-image position-relative"
                            >
                              <img
                                src="assets/img/filter-4.jpg"
                                alt="nft item"
                                className="img-fluid rounded-top"
                              />
                            </Link>
                            <div className="buy-now-btn primary-btn">
                              <Link to="/product-details">buy now</Link>
                            </div>
                            <div className="nft-item-content-wrap pt-4">
                              <Link to="/product-details">
                                <h2 className="h6">Time of Roger</h2>
                              </Link>
                              <hr />
                              <div className="nft-item-footer d-flex align-items-center justify-content-between">
                                <div className="item-price-wrapper d-flex align-items-center">
                                  <span>
                                    <i className="fab fa-ethereum"></i>
                                  </span>
                                  <div className="item-price ms-3">
                                    <p className="mb-0">Reserve price</p>
                                    <h5 className="mb-0">0.0478 ETH</h5>
                                  </div>
                                </div>
                                <span className="item-like">
                                  <i className="fa-solid fa-heart"></i> 15k Like
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-xl-3 col-md-6">
                        <div className="single-nft-item">
                          <div className="nft-item-wrapper rounded p-3">
                            <Link
                              to="/product-details"
                              className="item-image position-relative"
                            >
                              <img
                                src="assets/img/filter-5.jpg"
                                alt="nft item"
                                className="img-fluid rounded-top"
                              />
                            </Link>
                            <div className="buy-now-btn primary-btn">
                              <Link to="/product-details">buy now</Link>
                            </div>
                            <div className="nft-item-content-wrap pt-4">
                              <Link to="/product-details">
                                <h2 className="h6">GoldSilver Pirates</h2>
                              </Link>
                              <hr />
                              <div className="nft-item-footer d-flex align-items-center justify-content-between">
                                <div className="item-price-wrapper d-flex align-items-center">
                                  <span>
                                    <i className="fab fa-ethereum"></i>
                                  </span>
                                  <div className="item-price ms-3">
                                    <p className="mb-0">Reserve price</p>
                                    <h5 className="mb-0">0.0032 ETH</h5>
                                  </div>
                                </div>
                                <span className="item-like">
                                  <i className="fa-solid fa-heart"></i> 12k Like
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-xl-3 col-md-6">
                        <div className="single-nft-item">
                          <div className="nft-item-wrapper rounded">
                            <Link
                              to="/product-details"
                              className="item-image position-relative"
                            >
                              <img
                                src="assets/img/filter-6.jpg"
                                alt="nft item"
                                className="img-fluid rounded-top"
                              />
                            </Link>
                            <div className="buy-now-btn primary-btn">
                              <Link to="/product-details">buy now</Link>
                            </div>
                            <div className="nft-item-content-wrap pt-4">
                              <Link to="product-details">
                                <h2 className="h6">Anonymous Genesis</h2>
                              </Link>
                              <hr />
                              <div className="nft-item-footer d-flex align-items-center justify-content-between">
                                <div className="item-price-wrapper d-flex align-items-center">
                                  <span>
                                    <i className="fab fa-ethereum"></i>
                                  </span>
                                  <div className="item-price ms-3">
                                    <p className="mb-0">Reserve price</p>
                                    <h5 className="mb-0">0.00538 ETH</h5>
                                  </div>
                                </div>
                                <span className="item-like">
                                  <i className="fa-solid fa-heart"></i> 11k Like
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-xl-3 col-md-6">
                        <div className="single-nft-item">
                          <div className="nft-item-wrapper rounded">
                            <Link
                              to="/product-details"
                              className="item-image position-relative"
                            >
                              <img
                                src="assets/img/filter-6.jpg"
                                alt="nft item"
                                className="img-fluid rounded-top"
                              />
                            </Link>
                            <div className="buy-now-btn primary-btn">
                              <Link to="/product-details">buy now</Link>
                            </div>
                            <div className="nft-item-content-wrap pt-4">
                              <Link to="/product-details">
                                <h2 className="h6">Anonymous Genesis</h2>
                              </Link>
                              <hr />
                              <div className="nft-item-footer d-flex align-items-center justify-content-between">
                                <div className="item-price-wrapper d-flex align-items-center">
                                  <span>
                                    <i className="fab fa-ethereum"></i>
                                  </span>
                                  <div className="item-price ms-3">
                                    <p className="mb-0">Reserve price</p>
                                    <h5 className="mb-0">0.00538 ETH</h5>
                                  </div>
                                </div>
                                <span className="item-like">
                                  <i className="fa-solid fa-heart"></i> 11k Like
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-xl-3 col-md-6">
                        <div className="single-nft-item">
                          <div className="nft-item-wrapper rounded">
                            <Link
                              to="/product-details"
                              className="item-image position-relative"
                            >
                              <img
                                src="assets/img/filter-8.jpg"
                                alt="nft item"
                                className="img-fluid rounded-top"
                              />
                            </Link>

                            <div className="buy-now-btn primary-btn">
                              <Link to="/product-details">buy now</Link>
                            </div>
                            <div className="nft-item-content-wrap pt-4">
                              <Link to="/product-details">
                                <h2 className="h6">NeoTokyo Citizens</h2>
                              </Link>
                              <hr />
                              <div className="nft-item-footer d-flex align-items-center justify-content-between">
                                <div className="item-price-wrapper d-flex align-items-center">
                                  <span>
                                    <i className="fab fa-ethereum"></i>
                                  </span>
                                  <div className="item-price ms-3">
                                    <p className="mb-0">Reserve price</p>
                                    <h5 className="mb-0">0.0278 ETH</h5>
                                  </div>
                                </div>
                                <span className="item-like">
                                  <i className="fa-solid fa-heart"></i> 9k Like
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="profile_activity">
                    <div className="activity-list">
                      <div className="table-responsive">
                        <table className="table table-striped table-bordered align-middle">
                          <thead>
                            <tr>
                              <th>Item name</th>
                              <th>Price</th>
                              <th>Quantity</th>
                              <th>From</th>
                              <th>To</th>
                              <th>Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="item-info d-flex align-items-center">
                                <div className="thumb-wrapper">
                                  <img
                                    src="assets/img/mask.png"
                                    className="img-fluid rounded-circle"
                                    alt="mask"
                                  />
                                </div>
                                <div className="item-content ms-3">
                                  <span>Genesis Aliens</span>
                                  <h6>Otherdeed for otherside</h6>
                                </div>
                              </td>
                              <td>
                                <div className="price-amount text-center">
                                  <span>
                                    <i className="fa-brands fa-ethereum"></i>
                                    0.0005
                                  </span>
                                  <span className="d-block ms-4">$5.48</span>
                                </div>
                              </td>
                              <td>
                                <div className="quantity text-center">
                                  <span>01</span>
                                </div>
                              </td>
                              <td>
                                <div className="sellar text-center">
                                  <span>Suncity</span>
                                </div>
                              </td>
                              <td>
                                <div className="buyer text-center">
                                  <span>Hongjun</span>
                                </div>
                              </td>
                              <td>
                                <div className="time text-center">
                                  <span>20 Minutes ago</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="item-info d-flex align-items-center">
                                <div className="thumb-wrapper">
                                  <img
                                    src="assets/img/ac-2.png"
                                    className="img-fluid rounded-circle"
                                    alt="mask"
                                  />
                                </div>
                                <div className="item-content ms-3">
                                  <span>Lee Complete #110</span>
                                  <h6>Otherdeed for otherside</h6>
                                </div>
                              </td>
                              <td>
                                <div className="price-amount text-center">
                                  <span>
                                    <i className="fa-brands fa-ethereum"></i>
                                    0.0005
                                  </span>
                                  <span className="d-block ms-4">$5.48</span>
                                </div>
                              </td>
                              <td>
                                <div className="quantity text-center">
                                  <span>01</span>
                                </div>
                              </td>
                              <td>
                                <div className="sellar text-center">
                                  <span>Suncity</span>
                                </div>
                              </td>
                              <td>
                                <div className="buyer text-center">
                                  <span>Hongjun</span>
                                </div>
                              </td>
                              <td>
                                <div className="time text-center">
                                  <span>20 Minutes ago</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="item-info d-flex align-items-center">
                                <div className="thumb-wrapper">
                                  <img
                                    src="assets/img/ac-3.png"
                                    className="img-fluid rounded-circle"
                                    alt="mask"
                                  />
                                </div>
                                <div className="item-content ms-3">
                                  <span>Genesis Aliens</span>
                                  <h6>Otherdeed for otherside</h6>
                                </div>
                              </td>
                              <td>
                                <div className="price-amount text-center">
                                  <span>
                                    <i className="fa-brands fa-ethereum"></i>
                                    0.0005
                                  </span>
                                  <span className="d-block ms-4">$5.48</span>
                                </div>
                              </td>
                              <td>
                                <div className="quantity text-center">
                                  <span>01</span>
                                </div>
                              </td>
                              <td>
                                <div className="sellar text-center">
                                  <span>Suncity</span>
                                </div>
                              </td>
                              <td>
                                <div className="buyer text-center">
                                  <span>Hongjun</span>
                                </div>
                              </td>
                              <td>
                                <div className="time text-center">
                                  <span>20 Minutes ago</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="item-info d-flex align-items-center">
                                <div className="thumb-wrapper">
                                  <img
                                    src="assets/img/ac-4.png"
                                    className="img-fluid rounded-circle"
                                    alt="mask"
                                  />
                                </div>
                                <div className="item-content ms-3">
                                  <span>Genesis Aliens</span>
                                  <h6>Lee Complete #110</h6>
                                </div>
                              </td>
                              <td>
                                <div className="price-amount text-center">
                                  <span>
                                    <i className="fa-brands fa-ethereum"></i>
                                    0.0005
                                  </span>
                                  <span className="d-block ms-4">$5.48</span>
                                </div>
                              </td>
                              <td>
                                <div className="quantity text-center">
                                  <span>01</span>
                                </div>
                              </td>
                              <td>
                                <div className="sellar text-center">
                                  <span>Suncity</span>
                                </div>
                              </td>
                              <td>
                                <div className="buyer text-center">
                                  <span>Hongjun</span>
                                </div>
                              </td>
                              <td>
                                <div className="time text-center">
                                  <span>20 Minutes ago</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="item-info d-flex align-items-center">
                                <div className="thumb-wrapper">
                                  <img
                                    src="assets/img/ac-5.png"
                                    className="img-fluid rounded-circle"
                                    alt="mask"
                                  />
                                </div>
                                <div className="item-content ms-3">
                                  <span>Genesis Aliens</span>
                                  <h6>Otherdeed for otherside</h6>
                                </div>
                              </td>
                              <td>
                                <div className="price-amount text-center">
                                  <span>
                                    <i className="fa-brands fa-ethereum"></i>
                                    0.0005
                                  </span>
                                  <span className="d-block ms-4">$5.48</span>
                                </div>
                              </td>
                              <td>
                                <div className="quantity text-center">
                                  <span>01</span>
                                </div>
                              </td>
                              <td>
                                <div className="sellar text-center">
                                  <span>Suncity</span>
                                </div>
                              </td>
                              <td>
                                <div className="buyer text-center">
                                  <span>Hongjun</span>
                                </div>
                              </td>
                              <td>
                                <div className="time text-center">
                                  <span>20 Minutes ago</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="item-info d-flex align-items-center">
                                <div className="thumb-wrapper">
                                  <img
                                    src="assets/img/ac-4.png"
                                    className="img-fluid rounded-circle"
                                    alt="mask"
                                  />
                                </div>
                                <div className="item-content ms-3">
                                  <span>Genesis Aliens</span>
                                  <h6>Lee Complete #110</h6>
                                </div>
                              </td>
                              <td>
                                <div className="price-amount text-center">
                                  <span>
                                    <i className="fa-brands fa-ethereum"></i>
                                    0.0005
                                  </span>
                                  <span className="d-block ms-4">$5.48</span>
                                </div>
                              </td>
                              <td>
                                <div className="quantity text-center">
                                  <span>01</span>
                                </div>
                              </td>
                              <td>
                                <div className="sellar text-center">
                                  <span>Suncity</span>
                                </div>
                              </td>
                              <td>
                                <div className="buyer text-center">
                                  <span>Hongjun</span>
                                </div>
                              </td>
                              <td>
                                <div className="time text-center">
                                  <span>20 Minutes ago</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="item-info d-flex align-items-center">
                                <div className="thumb-wrapper">
                                  <img
                                    src="assets/img/ac-6.png"
                                    className="img-fluid rounded-circle"
                                    alt="mask"
                                  />
                                </div>
                                <div className="item-content ms-3">
                                  <span>Genesis Aliens</span>
                                  <h6>Otherdeed for otherside</h6>
                                </div>
                              </td>
                              <td>
                                <div className="price-amount text-center">
                                  <span>
                                    <i className="fa-brands fa-ethereum"></i>
                                    0.0005
                                  </span>
                                  <span className="d-block ms-4">$5.48</span>
                                </div>
                              </td>
                              <td>
                                <div className="quantity text-center">
                                  <span>01</span>
                                </div>
                              </td>
                              <td>
                                <div className="sellar text-center">
                                  <span>Suncity</span>
                                </div>
                              </td>
                              <td>
                                <div className="buyer text-center">
                                  <span>Hongjun</span>
                                </div>
                              </td>
                              <td>
                                <div className="time text-center">
                                  <span>20 Minutes ago</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="item-info d-flex align-items-center">
                                <div className="thumb-wrapper">
                                  <img
                                    src="assets/img/ac-7.png"
                                    className="img-fluid rounded-circle"
                                    alt="mask"
                                  />
                                </div>
                                <div className="item-content ms-3">
                                  <span>Genesis Aliens</span>
                                  <h6>Lee Complete #110</h6>
                                </div>
                              </td>
                              <td>
                                <div className="price-amount text-center">
                                  <span>
                                    <i className="fa-brands fa-ethereum"></i>
                                    0.0005
                                  </span>
                                  <span className="d-block ms-4">$5.48</span>
                                </div>
                              </td>
                              <td>
                                <div className="quantity text-center">
                                  <span>01</span>
                                </div>
                              </td>
                              <td>
                                <div className="sellar text-center">
                                  <span>Suncity</span>
                                </div>
                              </td>
                              <td>
                                <div className="buyer text-center">
                                  <span>Hongjun</span>
                                </div>
                              </td>
                              <td>
                                <div className="time text-center">
                                  <span>20 Minutes ago</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="item-info d-flex align-items-center">
                                <div className="thumb-wrapper">
                                  <img
                                    src="assets/img/ac-8.png"
                                    className="img-fluid rounded-circle"
                                    alt="mask"
                                  />
                                </div>
                                <div className="item-content ms-3">
                                  <span>Genesis Aliens</span>
                                  <h6>Otherdeed for otherside</h6>
                                </div>
                              </td>
                              <td>
                                <div className="price-amount text-center">
                                  <span>
                                    <i className="fa-brands fa-ethereum"></i>
                                    0.0005
                                  </span>
                                  <span className="d-block ms-4">$5.48</span>
                                </div>
                              </td>
                              <td>
                                <div className="quantity text-center">
                                  <span>01</span>
                                </div>
                              </td>
                              <td>
                                <div className="sellar text-center">
                                  <span>Suncity</span>
                                </div>
                              </td>
                              <td>
                                <div className="buyer text-center">
                                  <span>Hongjun</span>
                                </div>
                              </td>
                              <td>
                                <div className="time text-center">
                                  <span>20 Minutes ago</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="item-info d-flex align-items-center">
                                <div className="thumb-wrapper">
                                  <img
                                    src="assets/img/ac-6.png"
                                    className="img-fluid rounded-circle"
                                    alt="mask"
                                  />
                                </div>
                                <div className="item-content ms-3">
                                  <span>Genesis Aliens</span>
                                  <h6>Lee Complete #110</h6>
                                </div>
                              </td>
                              <td>
                                <div className="price-amount text-center">
                                  <span>
                                    <i className="fa-brands fa-ethereum"></i>
                                    0.0005
                                  </span>
                                  <span className="d-block ms-4">$5.48</span>
                                </div>
                              </td>
                              <td>
                                <div className="quantity text-center">
                                  <span>01</span>
                                </div>
                              </td>
                              <td>
                                <div className="sellar text-center">
                                  <span>Suncity</span>
                                </div>
                              </td>
                              <td>
                                <div className="buyer text-center">
                                  <span>Hongjun</span>
                                </div>
                              </td>
                              <td>
                                <div className="time text-center">
                                  <span>20 Minutes ago</span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/img/shapes/hero-top-right.svg"
          alt="shape"
          className="shape-right-top position-absolute"
        />
      </section>
    </>
  );
};

export default CreatorProfileTab;
