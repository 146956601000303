import React from "react";

export default function PartnersBrand() {
  return (
    <div className="nft1-partner-brands pb-120"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="hwit-title text-center">
              <h2>Our Valuable Partners</h2>
              <p>
                Partnership make us better, .{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="nft1-brands-list">
          <div className="row g-4">
            <div className="col-lg-3 col-md-4 col-6">
              <div className="brand-wrapper">
                <img
                  src="https://bafkreibhopndbv3cqibr6rsukfd4de3naujvaws4c2n5y5oivhybbb37xu.ipfs.nftstorage.link/ " //YBK
                  alt="brand"
                  className="img-fluid"
                  style={{ width: '200px', height: '100px' }}
                />
              </div>
            </div>
          <div className="col-lg-3 col-md-4 col-6">
              <div className="brand-wrapper">
              <img
                  src="https://bafkreidh47cecinohfb3pqczhwhtbwmbzfn3c6p3r3pd5baedwbd5kq7fm.ipfs.nftstorage.link/"// Bitara
                  alt="brand"
                  className="img-fluid"
                  style={{ width: '170px', height: '150px' }}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <div className="brand-wrapper">
              <img
                  src="https://bafkreif5rkvgwscog7t2znbstxmni35fpoqdak5rlo5idzxfob65xuhc3u.ipfs.nftstorage.link/" // ISDB
                  alt="brand"
                  className="img-fluid"
                  style={{ width: '200px', height: '100px' }}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <div className="brand-wrapper">
              <img
                  src="https://bafybeiba45asdwb6izazas5fhxlr5whdoiwl2gvbvctcdfxy7syate4b7i.ipfs.nftstorage.link/" // GICTC
                  alt="brand"
                  className="img-fluid"
                  style={{ width: '270px', height: '100px' }}
                />
              </div>
            </div>
            <div className="row g-4">
            <div className="col-lg-3 col-md-4 col-6">
              <div className="brand-wrapper">
                <img
                  src="https://bafkreidiboxdi5plrpnhn5hznhpna6eme6nl2ntd25twf3ep3hegwnob6e.ipfs.nftstorage.link/" // PT. DNA
                  alt="brand"
                  className="img-fluid"
                  style={{ width: '150px', height: '150px' }}
                />
              </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6">
              <div className="brand-wrapper">
                <img
                  src="https://bafkreib7xdfzeras7p7v2c737cu5ei2zscot5vntfuq5i2lnvsfs75e6du.ipfs.nftstorage.link/" // ABIM
                  alt="brand"
                  className="img-fluid"
                  style={{ width: '70px', height: '100px' }}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <div className="brand-wrapper">
                <img
                  src="https://bafkreids5goydiudntklwlwbvrw6esymzrjtcbwcnjfpsmvddghxvo5ttm.ipfs.nftstorage.link/" // ACA
                  alt="brand"
                  className="img-fluid"
                  style={{ width: '200px', height: '110px' }}
                />
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-6">
              <div className="brand-wrapper">
                <img
                  src="https://bafkreidhc7tvwt6chp4q6ihgrubcqdqtmlqyqh44mhnl4r4guxq2n5f7ta.ipfs.nftstorage.link/" // PT. KBI
                  alt="brand"
                  className="img-fluid"
                  style={{ width: '200px', height: '100px' }}
                />
              </div>
            </div>
            <div className="row g-4">
            <div className="col-lg-3 col-md-4 col-6">
              <div className="brand-wrapper">
                <img
                  src="https://bafkreibwdo4jy6a23mlmq2gpjjoqeaucyipyykf56zvbtusw4mofovurky.ipfs.nftstorage.link/" // PT. NBA
                  alt="brand"
                  className="img-fluid"
                  style={{ width: '180px', height: '100px' }}
                />
              </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6">
              <div className="brand-wrapper">
                <img
                  src="https://bafkreihteibgsb7qi2rh5m2z7cpzexydqaiqiofup42deuddrxduqdhnju.ipfs.nftstorage.link/" // PT. Yamoti
                  alt="brand"
                  className="img-fluid"
                  style={{ width: '150px', height: '150px' }}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <div className="brand-wrapper">
                <img
                  src="https://bafybeigic765l5zuuprq2prr742hkja42qbdzkd4eqkg75medvdwy3uoze.ipfs.nftstorage.link/" // Muhamadiyah
                  alt="brand"
                  className="img-fluid"
                  style={{ width: '150px', height: '150px' }}
                />
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-6">
              <div className="brand-wrapper">
                <img
                  src="https://bafkreibgmn75abcpxn2ldjdik7jee5bk32oky4rattucq2xwdfsuc5sdmm.ipfs.nftstorage.link/ " // PT. Lokal Brand indonesia
                  alt="brand"
                  className="img-fluid"
                  style={{ width: '200px', height: '100px' }}
                />
              </div>
            </div>

          </div>
          </div>
          </div>
        </div>
      </div>
    </div>

// separation section 2



  );
}
