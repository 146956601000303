import React from "react";

export default function SidebarSearch() {
  return (
    <div className="ex-sidebar">
      <div className="ex-sidebar-title">
        <h6>Filter by</h6>
      </div>
      <div className="ex-sidebar-widget ex-search-widget">
        <form>
          <input type="text" placeholder="Search here" />
          <button type="submit">
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </form>
      </div>
      <div className="ex-sidebar-widget ex-category-widget">
        <div className="sidebar-title">
          <h6>
            Category{" "}
            <span>
              <i className="fa-solid fa-angle-down"></i>
            </span>
          </h6>
        </div>
        <ul className="sidebar-check">
          <li>
            <input type="checkbox" />
            <p>
              Fashion <span>10</span>
            </p>
          </li>
          <li>
            <input type="checkbox" />
            <p>
              Music <span>08</span>
            </p>
          </li>
          <li>
            <input type="checkbox" />
            <p>
              Video <span>07</span>
            </p>
          </li>
          <li>
            <input type="checkbox" />
            <p>
              Games <span>09</span>
            </p>
          </li>
          <li>
            <input type="checkbox" />
            <p>
              Metavarse <span>15</span>
            </p>
          </li>
          <li>
            <input type="checkbox" />
            <p>
              Sports <span>14</span>
            </p>
          </li>
        </ul>
      </div>
      <div className="ex-sidebar-widget ex-collection-sidebar">
        <div className="sidebar-title">
          <h6>
            Collection{" "}
            <span>
              <i className="fa-solid fa-angle-down"></i>
            </span>
          </h6>
        </div>
        <ul className="sidebar-check">
          <li>
            <input type="checkbox" />
            <p>
              Cryptoplastic <span>10</span>
            </p>
          </li>
          <li>
            <input type="checkbox" />
            <p>
              Abstraction <span>8</span>
            </p>
          </li>
          <li>
            <input type="checkbox" />
            <p>
              Virtuland <span>7</span>
            </p>
          </li>
        </ul>
      </div>
      <div className="ex-sidebar-widget ex-status-widget">
        <div className="sidebar-title">
          <h6>
            Status{" "}
            <span>
              <i className="fa-solid fa-angle-down"></i>
            </span>
          </h6>
        </div>
        <ul className="sidebar-check">
          <li>
            <input type="checkbox" />
            <p>
              Buy Now <span>10</span>
            </p>
          </li>
          <li>
            <input type="checkbox" />
            <p>
              Live Auction <span>8</span>
            </p>
          </li>
          <li>
            <input type="checkbox" />
            <p>
              Hot offers <span>7</span>
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}
