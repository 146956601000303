import React from "react";
import { Link } from "react-router-dom";
import HotDropsSlider from "./HotDropsSlider";
import ProductNBA1 from "../pages/ProductNBA1";
import ProductNBA2 from "../pages/ProductNBA2";
import ProductDNA2 from "../pages/ProductDNA2";
import ProductDNA3 from "../pages/ProductDNA3";


const HotDrops = () => {
  return (
    <section className="hot-collection overflow-hidden ptb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="text-center">
              <div className="title-style-2 text-center d-flex align-items-center justify-content-center">
                <h2 className="mb-0">CONTRACT FARMING</h2>
                <span className="title-shape ms-3">
                  <img
                    src="https://bafybeigej5h64zb4xecvadc342kf32sobd4d475xdku3manlw7vswfepxi.ipfs.nftstorage.link/"
                    alt="fire-shape"
                    width="45"
                  />
                </span>
              </div>
          
            </div>
          </div>
        </div>
        <div className="hot-collection-content">
          <div className="row">
            <div className="col-lg-6">
              <HotDropsSlider />
            </div>
            <div className="col-lg-6">
              <div className="row g-4 justify-content-center">
                <div className="col-sm-6">
                  <div className="nft-hc-item">
                    <div className="thumb-wrapper">
                      <Link to="/product-DNA2">
                        <img
                          src="https://bafybeigej5h64zb4xecvadc342kf32sobd4d475xdku3manlw7vswfepxi.ipfs.nftstorage.link/"
                          className="img-fluid"
                          alt="item-thumb"
                        />
                      </Link>
                    </div>
                    <div className="save-wish">
                      <button>
                        <i className="fa-solid fa-heart"></i>
                      </button>
                    </div>
                    <div className="hc-item-title">
                      <h6>Dry Corn Food Grade #398-JAPIDN11</h6>
                    </div>
                    <div className="hc-item-bid-amount d-flex align-items-center justify-content-between">
                      <div>
                        <span>current price</span>
                        <h6>39 USDT</h6>
                      </div>
                      <div>
                        <span>AUM</span>
                        <h6>250.000 M2</h6>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div className="hc-item-author-info d-flex align-items-center">
                      <img
                        src="https://bafkreiecrbwa66pvwxh4ik2wi6k7fzq54eqphhna74jg25e7noer5rpdzq.ipfs.nftstorage.link/"
                        className="img-fluid rounded-circle"
                        style={{ width: '50px', height: '70px' }}
                        alt="author"
                      />
                      <div className="author-title">
                        <h6>Lombok timur, Indonesia</h6>
                        <span>JAPIDN11 </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="nft-hc-item">
                    <div className="thumb-wrapper">
                      <Link to="/product-NBA1">
                        <img
                          src="https://bafybeigej5h64zb4xecvadc342kf32sobd4d475xdku3manlw7vswfepxi.ipfs.nftstorage.link/"
                          className="img-fluid"
                          alt="item-thumb"
                        />
                      </Link>
                    </div>
                    <div className="save-wish">
                      <button>
                        <i className="fa-solid fa-heart"></i>
                      </button>
                    </div>
                    <div className="hc-item-title">
                      <h6>Trisakti Rice #821-Trident99</h6>
                    </div>
                    <div className="hc-item-bid-amount d-flex align-items-center justify-content-between">
                      <div>
                        <span>current price</span>
                        <h6>33 USDT</h6>
                      </div>
                      <div>
                        <span>AUM</span>
                        <h6>250.000 M2</h6>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div className="hc-item-author-info d-flex align-items-center">
                      <img
                        src="https://bafkreifykzlsarltut57uxhqvntw7wg57tsx7nybgufzim2rxt6jpoe2ma.ipfs.nftstorage.link/"
                        className="img-fluid rounded-circle"
                        style={{ width: '50px', height: '70px' }}
                        alt="author"
                      />
                      <div className="author-title">
                        <h6>Serang, Indonesia</h6>
                        <span>Trident99</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="nft-hc-item">
                    <div className="thumb-wrapper">
                      <Link to="/product-NBA2">
                        <img
                          src="https://bafybeigej5h64zb4xecvadc342kf32sobd4d475xdku3manlw7vswfepxi.ipfs.nftstorage.link/"
                          className="img-fluid"
                          alt="item-thumb"
                        />
                      </Link>
                    </div>
                    <div className="save-wish">
                      <button>
                        <i className="fa-solid fa-heart"></i>
                      </button>
                    </div>
                    <div className="hc-item-title">
                      <h6>Trisakti Rice #669-Trident96</h6>
                    </div>
                    <div className="hc-item-bid-amount d-flex align-items-center justify-content-between">
                      <div>
                        <span>current price</span>
                        <h6>33 USDT</h6>
                      </div>
                      <div>
                        <span>AUM</span>
                        <h6>200.000 M2</h6>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div className="hc-item-author-info d-flex align-items-center">
                      <img
                        src="https://bafkreifykzlsarltut57uxhqvntw7wg57tsx7nybgufzim2rxt6jpoe2ma.ipfs.nftstorage.link/"
                        className="img-fluid rounded-circle"
                        style={{ width: '50px', height: '70px' }}
                        alt="author"
                      />
                      <div className="author-title">
                        <h6>Karawang, Indonesia</h6>
                        <span>Trident96</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="nft-hc-item">
                    <div className="thumb-wrapper">
                      <Link to="/product-DNA3">
                        <img
                          src="https://bafybeigej5h64zb4xecvadc342kf32sobd4d475xdku3manlw7vswfepxi.ipfs.nftstorage.link/"
                          className="img-fluid"
                          alt="item-thumb"
                        />
                      </Link>
                    </div>
                    <div className="save-wish">
                      <button>
                        <i className="fa-solid fa-heart"></i>
                      </button>
                    </div>
                    <div className="hc-item-title">
                      <h6>Dry Corn Feed Grade #422-JAPIDN22</h6>
                    </div>
                    <div className="hc-item-bid-amount d-flex align-items-center justify-content-between">
                      <div>
                        <span>current price</span>
                        <h6>39 USDT</h6>
                      </div>
                      <div>
                        <span>AUM</span>
                        <h6>250.000 M2</h6>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div className="hc-item-author-info d-flex align-items-center">
                      <img
                        src="https://bafkreiecrbwa66pvwxh4ik2wi6k7fzq54eqphhna74jg25e7noer5rpdzq.ipfs.nftstorage.link/"
                        className="img-fluid rounded-circle"
                        style={{ width: '50px', height: '70px' }}
                        alt="author"
                      />
                      <div className="author-title">
                        <h6>Lombok timur, Indonesia</h6>
                        <span>JAPIDN22</span>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HotDrops;

