import React from "react";
import { Link } from "react-router-dom";

const Pagination = ({ center }) => {
  return (
    <div className="row">
      <div className="col-12">
        <div
          className={`nft-pagination col-auto
       margin-top-50 ${center ? "text-center" : ""}`}
        >
          <ul>
            <li>
              <Link to="/">
                <i className="fa-solid fa-arrow-left"></i>
              </Link>
            </li>
            <li>
              <Link to="/">1</Link>
            </li>
            <li className="active">
              <Link to="/">2</Link>
            </li>
            <li>
              <Link to="/">3</Link>
            </li>
            <li>
              <Link to="/">
                <i className="fa-solid fa-arrow-right"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
