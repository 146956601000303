import React from "react";
import AuctionProductDetails from "../components/auction/AuctionItemDetails";
import SimilarCollection from "../components/collections/SimilarCollection";
import BreadCrumb from "../components/common/BreadCrumb";
import Layout from "../components/layout/Layout";

const AuctionItemDetails = () => {
  return (
    <Layout>
      <BreadCrumb
        pageTitle="Product Auction Details"
        parentPage="Home"
        prevPage="Pages"
        currentPage="Product Auction Details"
      />
      <AuctionProductDetails />
      <SimilarCollection />
    </Layout>
  );
};

export default AuctionItemDetails;
