import React from "react";

const NewsLetter = () => {
  return (
    <section className="nft2-newsletter-area pt-60 pb-120 position-relative overflow-hidden">
      <img
        src="assets/img/shapes/cta-2-left.png"
        alt="shape"
        className="shape-top-left position-absolute"
      />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="title-style-2 text-center newsletter-content-wrapper">
              <h2>Don't miss an update drop!</h2>
              <div className="title-desc">
                <p>
                  Lowest Fees and Unlimited Rewards. We Have it all to provide
                  the best experience As a pleasant surprise for crypto fans, a
                  unique.
                </p>
              </div>
              <form className="newsletter-form mx-auto">
                <input type="email" placeholder="Type your email" />
                <button type="submit">Submit now</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <img
        src="assets/img/shapes/cta-2-right.png"
        alt="shape"
        className="shape-right-top position-absolute"
      />
    </section>
  );
};

export default NewsLetter;
