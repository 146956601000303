import React from "react";

import Layout from "../components/layout/Layout";
import CreatorsList from "../components/tabs/CreatorsList";

const Creators = () => {
  return (
    <Layout>
     
      <CreatorsList />
    </Layout>
  );
};

export default Creators;
