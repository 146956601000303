import React from "react";
import Layout from "../components/layout/Layout";
import CreatorProfileTab from "../components/tabs/CreatorProfileTab";

const CreatorProfile = () => {
  return (
    <Layout title="Creator Profile">
      <CreatorProfileTab />
    </Layout>
  );
};

export default CreatorProfile;
