import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { navDemo, navPagesData } from "../../../utils/data";
import OffCanvas from "./OffCanvas";


export default function Navbar() {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector(".main-header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <>
      <header className="main-header position-absolute w-100">
        <nav
          className={`navbar navbar-expand-xl navbar-dark sticky-header ${
            scroll > headerTop ? "affix" : ""
          }`}
        >
          <div className="container d-flex align-items-center justify-content-lg-between position-relative">
            <Link
              to="/"
              className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
            >
              <img src="https://bafkreidxnb3p4gb3zq66mo2kwmrstrmb7vll2mzoa3mdnrfsn6vhjouese.ipfs.nftstorage.link/" 
              alt="logo" 
              className="img-fluid" 
              style={{ width: '200px', height: '80px' }}/>
            </Link>

            <a
              className="navbar-toggler position-absolute right-0 border-0"
              href="#offcanvasWithBackdrop"
              role="button"
            >
              <span
                className="fas fa-bars"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasWithBackdrop"
                aria-controls="offcanvasWithBackdrop"
              ></span>
            </a>
            <div className="collapse navbar-collapse justify-content-center">
              <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                <li className="nav-item dropdown">
                <Link className="nav-link" to="/">
                    Home
                </Link>
                  <div className="dropdown-menu border-0 rounded-custom shadow py-0">
                    <div className="dropdown-grid rounded-custom width-half">
                      <div className="dropdown-grid-item">
                        {navDemo.map((data, i) => (
                          <Link
                            to={data.path}
                            className="dropdown-link demo-link"
                            key={i + 1}
                          >
                            <span className="demo-list secondary-bg rounded-circle text-white">
                              {i + 1}
                            </span>
                            <div className="dropdown-info">
                              <div className="drop-title">{data.menuTitle}</div>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </li>


               
                <li>
                  <Link to="/contact-us" className="nav-link">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>

            <div className="action-btns d-flex align-items-center text-end me-5 me-xl-0 d-none d-sm-block d-md-flex d-lg-flex">
             
           
              
            </div>
          </div>
        </nav>
      </header>
      <OffCanvas />
    </>
  );
}
