import React from "react";

export default function AuthorAbout() {
  return (
    <div className="blog-sidebar-widget author-widget">
      <div className="widget-title">
        <h4 className="h5">About me</h4>
      </div>
      <div className="author-info">
        <div className="thumb-wrapper">
          <img
            src="assets/img/blog-author.png"
            className="img-fluid"
            alt="author"
          />
        </div>
        <div className="author-title">
          <h6>David M.Hyland</h6>
          <span>425.02 ETH</span>
        </div>
        <div className="author-social">
          <a href="#!">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="#!">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="#!">
            <i className="fab fa-behance"></i>
          </a>
          <a href="#!">
            <i className="fab fa-dribbble"></i>
          </a>
        </div>
      </div>
    </div>
  );
}
