import React from "react";

const FaqTabs = () => {
  return (
    <section className="nft1-faq-area ptb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="hwit-title text-center">
              <h2>Frequently Asked questions</h2>
              <p>
                Here are answer for your questions.
              </p>
            </div>
          </div>
        </div>
        <div className="faq-tab-wrapper">
          <ul className="nav nav-tabs">
            <li>
              <button
                className="active"
                data-bs-toggle="tab"
                data-bs-target="#general"
              >
                 Certificate of Depositories
              </button>
            </li>
            <li>
              <button data-bs-toggle="tab" data-bs-target="#support">
              General Questions
              </button>
            </li>
            <li>
              <button data-bs-toggle="tab" data-bs-target="#transaction">
              Certificate of Contract Farming
              </button>
            </li>
            <li>
              <button data-bs-toggle="tab" data-bs-target="#fees">
              Transaction & redemption
              </button>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane fade show active" id="general">
              <div className="row">
                <div className="col-lg-6">
                  <div className="accordion-wrapper">
                    <div className="accordion" id="general_1">
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                          >
                           What is the method of redemption for end-users from token to rice

                          </button>
                        </h6>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#general_1"
                        >
                          <div className="accordion-body">
                            <p>
                            End-users can redeem via a membership card and Padigital's WhatsApp number for token-to-rice conversion.

                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsetwo"
                          >
                            
                           Do I personally have to take care of the depository myself or
                            assign someone to take care of the depository


                          </button>
                        </h6>
                        <div
                          id="collapseone"
                          className="accordion-collapse collapse"
                          data-bs-parent="#general_2"
                        >
                          <div className="accordion-body">
                            <p>
                            PT Yamoti Adikara Food operates all fractionalized certificates; no personal upkeep required.


                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsetwo"
                          >
                            What or how is the profit making method from my investment in the depository

                          </button>
                        </h6>
                        <div
                          id="collapsetwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#general_2"
                        >
                          <div className="accordion-body">
                            <p>
                            Profit methods: 5% from redemption, fertilizer vault profit share, and NFT bid buy/sell.

                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsetwo"
                          >
                            Where does my profit go and where can I see or withdraw my profit from my investment

                          </button>
                        </h6>
                        <div
                          id="collapsetwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#general_1"
                        >
                          <div className="accordion-body">
                            <p>
                            Profits go to your NFT wallet; withdrawals and viewing are accessible there.

                            </p>
                          </div>
                        </div>
                      </div>


                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsethree"
                          >
                            Are there any other conmmodities to be sold in the depository or is it just rice
                          </button>
                        </h6>
                        <div
                          id="collapsethree"
                          className="accordion-collapse collapse"
                          data-bs-parent="#general_1"
                        >
                          <div className="accordion-body">
                            <p>
                            Depository offers various commodities besides rice and is continually expanding its offerings.

                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsefour"
                          >
                            Will there be any extra cost after purchasing the Certificate of Depository

                          </button>
                        </h6>
                        <div
                          id="collapsefour"
                          className="accordion-collapse collapse"
                          data-bs-parent="#general_2"
                        >
                          <div className="accordion-body">
                            <p>
                            Only gas fees and a 0.25% commission are additional costs post-purchase of the Certificate.

                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="accordion-wrapper">
                    <div className="accordion" id="general_2">
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsefive"
                          >
               How much is the price for Certificate of Depository
                          </button>
                        </h6>
                        <div
                          id="collapsefive"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#general_1"
                        >
                          <div className="accordion-body">
                            <p>
                            Certificate price: 33 Ethereum. 

                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsesix"
                          >
                            How many frational NFT each Depository?

                          </button>
                        </h6>
                        <div
                          id="collapsesix"
                          className="accordion-collapse collapse"
                          data-bs-parent="#general_2"
                        >
                          <div className="accordion-body">
                            <p>
                            There are 800 fractional NFTs each Depository in ERC-1155.

                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseseven"
                          >
                           Can I sell my Certificate?

                          </button>
                        </h6>
                        <div
                          id="collapseseven"
                          className="accordion-collapse collapse"
                          data-bs-parent="#general_2"
                        >
                          <div className="accordion-body">
                            <p>
                            Certificates can be sold on OpenSea or via receiving offers.

                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseseven"
                          >
                            Is there any time limit to holding my Certificate of Depository?

                          </button>
                        </h6>
                        <div
                          id="collapseseven"
                          className="accordion-collapse collapse"
                          data-bs-parent="#general_2"
                        >
                          <div className="accordion-body">
                            <p>
                            No time limit; continuous profit sharing as long as you hold and operate the Certificate.
                            </p>
                          </div>
                        </div>
                      </div>

                     

                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="support">
              <div className="row">
                <div className="col-lg-6">
                  <div className="accordion-wrapper">
                    <div className="accordion" id="support_1">
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTherteen"
                          >
                            What is the difference between Certificate of Farmland and Certificate of Depository

                          </button>
                        </h6>
                        <div
                          id="collapseTherteen"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#support_1"
                        >
                          <div className="accordion-body">
                            <p>
                            Certificate of Farmland signifies Contract Farming; Certificate of Depository denotes a distribution franchise.


                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               
              
                <div className="col-lg-6">
                  <div className="accordion-wrapper">
                    <div className="accordion" id="support_2">
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFourteen"
                          >
                            What is Padigital about and what does it do?

                          </button>
                        </h6>
                        <div
                          id="collapseFourteen"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#support_2"
                        >
                          <div className="accordion-body">
                            <p>
                            Padigital: Web3 blockchain for agricultural commodities, enabling transparent, efficient transactions using blockchain technology
                            </p>
                          </div>
                        </div>
                      </div>
                     </div>
                  </div>
                </div>
              </div>
            </div>



          

            <div className="tab-pane fade" id="transaction">
              <div className="row">
                <div className="col-lg-6">
                  <div className="accordion-wrapper">
                    <div className="accordion" id="transaction_1">
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTherteen"
                          >
                           How long will the harvest take


                          </button>
                        </h6>
                        <div
                          id="collapseTherteen"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#transaction_1"
                        >
                          <div className="accordion-body">
                            <p>
                            Harvest duration varies based on seed, weather, fertilizer, and seasonal factors
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="accordion-wrapper">
                    <div className="accordion" id="transaction_2">
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFourteen"
                          >
                            How long is the duration for planting

                          </button>
                        </h6>
                        <div
                          id="collapseFourteen"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#transaction_2"
                        >
                          <div className="accordion-body">
                            <p>
                            Planting typically spans 75 to 100 days.

                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="accordion-wrapper">
                    <div className="accordion" id="transaction_1">
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTherteen"
                          >
                           What can I do with the rice token after harvested



                          </button>
                        </h6>
                        <div
                          id="collapseTherteen"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#transaction_1"
                        >
                          <div className="accordion-body">
                            <p>
                            Rice tokens post-harvest: sell or redeem for consumption.

                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="accordion-wrapper">
                    <div className="accordion" id="transaction_2">
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFourteen"
                          >
                            Where can I check where the token goes after the harvest


                          </button>
                        </h6>
                        <div
                          id="collapseFourteen"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#transaction_2"
                        >
                          <div className="accordion-body">
                            <p>
                            Check token destination post-harvest on your wallet or blockchain explorer.


                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-lg-6">
                  <div className="accordion-wrapper">
                    <div className="accordion" id="transaction_2">
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFourteen"
                          >
                            Are there any guarantees for my investment if there are any crop loss. (Etc, bad weather or barrenness)



                          </button>
                        </h6>
                        <div
                          id="collapseFourteen"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#transaction_2"
                        >
                          <div className="accordion-body">
                            <p>
                            Investments entail risks; Your investment is at your own risk. DO YOUR OWN RESEARCH. 


                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="accordion-wrapper">
                    <div className="accordion" id="transaction_2">
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFourteen"
                          >
                            Do Padigital protect the investment?


                          </button>
                        </h6>
                        <div
                          id="collapseFourteen"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#transaction_2"
                        >
                          <div className="accordion-body">
                            <p>
                            Padigital cover assets under management with all risk inssurance by JASINDO -State Own Company-

                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>



            <div className="tab-pane fade" id="fees">
              <div className="row">
                <div className="col-lg-6">
                  <div className="accordion-wrapper">
                    <div className="accordion" id="fees_1">
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsTweenty"
                          >
                             Where do I go to trade the tokens on Padigital

                          </button>
                        </h6>
                        <div
                          id="collapsTweenty"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#fees_1"
                        >
                          <div className="accordion-body">
                            <p>
                            Tokens trade on Dex or through direct sales to acquaintances on Padigital.

                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsTweenty"
                          >
                            What is token redemption

                          </button>
                        </h6>
                        <div
                          id="collapsTweenty"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#fees_1"
                        >
                          <div className="accordion-body">
                            <p>
                            Token redemption: exchanging tokens for commodities available in our depository.

                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsTweenty"
                          >
                             What is the maximum I can redeem


                          </button>
                        </h6>
                        <div
                          id="collapsTweenty"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#fees_1"
                        >
                          <div className="accordion-body">
                            <p>
                            Maximum redemption: 5KG per citizen ID, 300KG for industries, 2Tons for retailers.


                            </p>
                          </div>
                        </div>
                      </div>

             
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFourty"
                          >
                            What happens to the token after it has been redeemed in depositories

                          </button>
                        </h6>
                        <div
                          id="collapseFourty"
                          className="accordion-collapse collapse"
                          data-bs-parent="#fees_2"
                        >
                          <div className="accordion-body">
                            <p>
                            After redemption, Padigital burns the tokens.

                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                
                <div className="col-lg-6">
                  <div className="accordion-wrapper">
                    <div className="accordion" id="fees_2">
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThirty"
                          >
                           Is there any cost for token redemption

                          </button>
                        </h6>
                        <div
                          id="collapseThirty"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#fees_2"
                        >
                          <div className="accordion-body">
                            <p>
                            Token redemption incurs a 5% cost.

                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThirty"
                          >
                           When can I redeem my token


                          </button>
                        </h6>
                        <div
                          id="collapseThirty"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#fees_2"
                        >
                          <div className="accordion-body">
                            <p>
                            Redemption usually occurs on Fridays.
                            </p>
                          </div>
                        </div>
                      </div>


                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThirty"
                          >
                           Can I have my package delivered



                          </button>
                        </h6>
                        <div
                          id="collapseThirty"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#fees_2"
                        >
                          <div className="accordion-body">
                            <p>
                            Delivery possible but subject to depository regulations and additional charges.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThirty"
                          >
                          Can I redeem my token if I am from overseas




                          </button>
                        </h6>
                        <div
                          id="collapseThirty"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#fees_2"
                        >
                          <div className="accordion-body">
                            <p>
                            Overseas redemption permitted with a minimum of 1000Tons; Padigital handles export with extra charges.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThirtyTwo"
                          >
                            Where can I redeem my token

                          </button>
                        </h6>
                        <div
                          id="collapseThirtyTwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#fees_2"
                        >
                          <div className="accordion-body">
                            <p>
                            Redeem tokens at our specified listed depositories.

                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          
        </div>
      </div>
    </section>
  );
};

export default FaqTabs;
