import React from "react";
import { Link } from "react-router-dom";

const BreadCrumb = ({ pageTitle, parentPage, prevPage, currentPage }) => {
  return (
    <section
      className="nft-breadcrumb"
      style={{
        backgroundImage: "url('assets/img/breadcrumb-bg.jpg')",
        backgroundRepeat: "no-repeat",
        bakgroundPosition: "center center / cover",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <nav className="breadcrumb-nav">
              <h1 className="fw-bold">{pageTitle}</h1>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">{parentPage}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/">{prevPage}</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <Link to="/faq">{currentPage}</Link>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BreadCrumb;
