import { exploreCollections } from "../utils/data";
import React from "react";
import { Link } from "react-router-dom";

export default function ExploreNftCollection() {
  return (
    <div className="ex-main">
      <div className="ex-top-bar">
        <div className="sort-btn">
          <div className="sort-select">
            <select>
              <option>Sort By</option>
              <option>Popular</option>
              <option>Best Selling</option>
            </select>
          </div>
          <div className="sort-select">
            <select>
              <option>Likes</option>
              <option>Most Likes</option>
              <option>Least Likes</option>
            </select>
          </div>
        </div>
      </div>
      <div className="ex-explore-items">
        <div className="row g-3">
          {exploreCollections.map((nftItem, i) => (
            <div className="col-lg-6 col-xl-4 col-md-6" key={i + 1}>
              <div className="single-nft-item">
                <div className="nft-item-wrapper rounded p-3">
                  <Link
                    to={nftItem.nftDetails}
                    className="item-image position-relative"
                  >
                    <img
                      src={nftItem.nftImage}
                      alt={nftItem.nftName}
                      className="img-fluid rounded-top"
                    />
                  </Link>
                  <div className="buy-now-btn primary-btn">
                    <Link to={nftItem.nftDetails}>buy now</Link>
                  </div>
                  <div className="nft-item-content-wrap pt-4">
                    <Link to={nftItem.nftDetails}>
                      <h2 className="h6">{nftItem.nftName}</h2>
                    </Link>
                    <hr />
                    <div className="nft-item-footer d-flex align-items-center justify-content-between">
                      <div className="item-price-wrapper d-flex align-items-center">
                        <span>
                          <i className="fab fa-ethereum"></i>
                        </span>
                        <div className="item-price ms-3">
                          <p className="mb-0">Reserve price</p>
                          <h5 className="mb-0">{nftItem.nftPrice} ETH</h5>
                        </div>
                      </div>
                      <span className="item-like">
                        <i className="fa-solid fa-heart"></i> 15k Like
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
