import React from "react";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";

export default function ExploreLiveAuction() {
  return (
    <div className="live-auction ptb-120">
      <div className="container">
        <div className="auction-top">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="title-heading">
                <h2>Explore live auction.</h2>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="explore-btn primary-btn">
                <Link to="/explore-collections">
                  Explore all
                  <span>
                    <i className="fa-solid fa-arrow-right"></i>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="auction-bottom">
          <div className="row g-4 justify-content-center">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-9">
              <div className="auction-column">
                <div className="fc-wrapper">
                  <div className="thumb-wrapper">
                    <img
                      src="assets/img/auction-1.jpg"
                      className="w-100"
                      alt="feature "
                    />
                  </div>
                  <div className="fc-title">
                    <Link to="/product-details">
                      <h6>Supperplastic crypto</h6>
                    </Link>
                  </div>
                  <div className="fc-spacer"></div>
                  <div className="fc-bottom">
                    <div className="fc-bt-left">
                      <div className="icon-wrapper">
                        <span>
                          <i className="fa-brands fa-ethereum"></i>
                        </span>
                      </div>
                      <div className="fc-reserve">
                        <span className="rs-subtitle">Reserve price</span>
                        <span className="rs-title">0.0478 ETH</span>
                      </div>
                    </div>
                    <div className="fc-right">
                      <span>
                        <i className="fa-solid fa-heart"></i>12
                      </span>
                    </div>
                  </div>
                  <div className="auction-timing">
                    <div className="auction-countdown">
                      <Countdown
                        date={Date.now() + 5000000}
                        renderer={counter}
                        autoStart={true}
                      />
                    </div>
                    <div className="buy-now-btn">
                      <Link to="/product-details">Buy now</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-9">
              <div className="auction-column">
                <div className="fc-wrapper">
                  <div className="thumb-wrapper">
                    <img
                      src="assets/img/auction-2.jpg"
                      className="w-100"
                      alt="feature"
                    />
                  </div>
                  <div className="fc-title">
                    <Link to="/product-details">
                      <h6>Supperplastic crypto</h6>
                    </Link>
                  </div>
                  <div className="fc-spacer"></div>
                  <div className="fc-bottom">
                    <div className="fc-bt-left">
                      <div className="icon-wrapper">
                        <span>
                          <i className="fa-brands fa-ethereum"></i>
                        </span>
                      </div>
                      <div className="fc-reserve">
                        <span className="rs-subtitle">Reserve price</span>
                        <span className="rs-title">0.0478 ETH</span>
                      </div>
                    </div>
                    <div className="fc-right">
                      <span>
                        <i className="fa-solid fa-heart"></i>12
                      </span>
                    </div>
                  </div>
                  <div className="auction-timing">
                    <div className="auction-countdown">
                      <Countdown
                        date={Date.now() + 5000000}
                        renderer={counter}
                        autoStart={true}
                      />
                    </div>
                    <div className="buy-now-btn">
                      <Link to="product-details">Buy now</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-9">
              <div className="auction-column">
                <div className="fc-wrapper">
                  <div className="thumb-wrapper">
                    <img
                      src="assets/img/auction-3.jpg"
                      className="w-100"
                      alt="feature "
                    />
                  </div>
                  <div className="fc-title">
                    <Link to="/product-details">
                      <h6>Supperplastic crypto</h6>
                    </Link>
                  </div>
                  <div className="fc-spacer"></div>
                  <div className="fc-bottom">
                    <div className="fc-bt-left">
                      <div className="icon-wrapper">
                        <span>
                          <i className="fa-brands fa-ethereum"></i>
                        </span>
                      </div>
                      <div className="fc-reserve">
                        <span className="rs-subtitle">Reserve price</span>
                        <span className="rs-title">0.0478 ETH</span>
                      </div>
                    </div>
                    <div className="fc-right">
                      <span>
                        <i className="fa-solid fa-heart"></i>12
                      </span>
                    </div>
                  </div>
                  <div className="auction-timing">
                    <div className="auction-countdown">
                      <Countdown
                        date={Date.now() + 5000000}
                        renderer={counter}
                        autoStart={true}
                      />
                    </div>
                    <div className="buy-now-btn">
                      <Link to="/product-details">Buy now</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-9">
              <div className="auction-column">
                <div className="fc-wrapper">
                  <div className="thumb-wrapper">
                    <img
                      src="assets/img/auction-4.jpg"
                      className="w-100"
                      alt="feature"
                    />
                  </div>
                  <div className="fc-title">
                    <Link to="/product-details">
                      <h6>Supperplastic crypto</h6>
                    </Link>
                  </div>
                  <div className="fc-spacer"></div>
                  <div className="fc-bottom">
                    <div className="fc-bt-left">
                      <div className="icon-wrapper">
                        <span>
                          <i className="fa-brands fa-ethereum"></i>
                        </span>
                      </div>
                      <div className="fc-reserve">
                        <span className="rs-subtitle">Reserve price</span>
                        <span className="rs-title">0.0478 ETH</span>
                      </div>
                    </div>
                    <div className="fc-right">
                      <span>
                        <i className="fa-solid fa-heart"></i>12
                      </span>
                    </div>
                  </div>
                  <div className="auction-timing">
                    <div className="auction-countdown">
                      <Countdown
                        date={Date.now() + 5000000}
                        renderer={counter}
                        autoStart={true}
                      />
                    </div>
                    <div className="buy-now-btn">
                      <Link to="/product-details">Buy now</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const counter = ({ hours, minutes, seconds }) => {
  return (
    <ul className="downcount-timer">
      <li className="pe-1">
        <span className="hours">{hours}</span>h
      </li>
      <li className="pe-1">
        <span className="minutes">{minutes}</span>m
      </li>
      <li>
        <span className="seconds">{seconds}</span>s
      </li>
    </ul>
  );
};
