import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import Countdown from "react-countdown";
import "swiper/css/navigation";
import { Link } from "react-router-dom";

export default function HotDropsSlider() {
  const swiperOptions = {
    slidesPerView: 1,
    speed: 700,
    spaceBetween: 30,
    slidesPerGroup: 1,
    loop: true,
    navigation: {
      nextEl: ".swiper-nav-control .swiper-button-next",
      prevEl: ".swiper-nav-control .swiper-button-prev",
    },
  };
  return (
    <>
      <Swiper
        className="nft2-hc-slider"
        modules={[Navigation]}
        {...swiperOptions}
      >
        <SwiperSlide>
          <div className="nft2-hc-slide-single">
            <div className="top-img">
              <img
                src="https://bafkreibaphdi7vlyfx47csslfqxj2c5csig54fiegle2sqw6nh2akr3jmq.ipfs.nftstorage.link/"
                className="img-fluid rounded-custom w-100"
                style={{ width: '50px', height: '300px' }}
                alt="image not found"
              />
            </div>
            <div className="hc-slide-info">
              <div className="slide-info-content">
                <div className="slide-title">
                  <h3 className="h4">PT. Nusa Berkat Alam</h3>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="author-info d-flex align-items-center">
                      <div className="author-thumb">
                        <img
                          src="https://bafkreiednhed747hc56y3o4hexpiw35fsv6enmxsvcliic3cbyxthz5jty.ipfs.nftstorage.link/"
                          className="img-fluid rounded-circle"
                          alt="item-thumb"
                          style={{ width: '50px', height: '70px' }}
                        />
                      </div>
                      <div className="author-title">
                        <span>Aggregator</span>
                        <h6>Prof. Ali Zum Mashar</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="author-info d-flex align-items-center">
                      <div className="author-thumb">
                        <img
                          src="https://bafybeigej5h64zb4xecvadc342kf32sobd4d475xdku3manlw7vswfepxi.ipfs.nftstorage.link/"
                          className="img-fluid rounded-circle"
                          alt="item-thumb"
                          style={{ width: '70px', height: '50px' }}
                        />
                      </div>
                      <div className="author-title">
                        <span>Commodity</span>
                        <h6>Rice, Soy bean</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="bid-amount">
                      <span>Contract Limit</span>
                      <h4>3.000.000 M2</h4>
                      <span className="currency">(USDT 396,000,-)</span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="bid-amount">
                      <span>Farmers Under Management</span>
                      <h4>1.562 Farmers</h4>
                     
                    </div>
                  </div>

                </div>
                <div className="d-flex align-items-center collection-action-btns">
                <div className="primary-btn">
                    <Link to="https://ptnba.eth">Profile</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="nft2-hc-slide-single">
            <div className="top-img">
              <img
                src="https://bafkreidiboxdi5plrpnhn5hznhpna6eme6nl2ntd25twf3ep3hegwnob6e.ipfs.nftstorage.link/ "
                className="img-fluid rounded-custom w-100"
                style={{ width: '500px', height: '300px' }}
                alt="image not found"
              />
            </div>
            <div className="hc-slide-info">
              <div className="slide-info-content">
                <div className="slide-title">
                  <h3 className="h4">PT. Datu Nusra Agribisnis</h3>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="author-info d-flex align-items-center">
                      <div className="author-thumb">
                        <img
                          src="https://bafkreietowvrcygvupmvq4p5oofh3ergg5gejx34gw3pzgx7n73sfzxcqq.ipfs.nftstorage.link/"
                          className="img-fluid rounded-circle"
                          alt="item-thumb"
                          style={{ width: '50px', height: '70px' }}
                        />
                      </div>
                      <div className="author-title">
                        <span>Aggregator</span>
                        <h6>Dean Novel</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="author-info d-flex align-items-center">
                      <div className="author-thumb">
                        <img
                          src="https://bafybeigej5h64zb4xecvadc342kf32sobd4d475xdku3manlw7vswfepxi.ipfs.nftstorage.link/"
                          className="img-fluid rounded-circle"
                          alt="item-thumb"
                          style={{ width: '70px', height: '50px' }}
                        />
                      </div>
                      <div className="author-title">
                        <span>Commodity</span>
                        <h6>Dry Corn</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="bid-amount">
                      <span>Contract Limit</span>
                      <h4>25.000.000 M2</h4>
                      <span className="currency">(USDT 3,700,000,-)</span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="bid-amount">
                      <span>Farmers Under Management</span>
                      <h4>7420 Farmers</h4>
                     
                    </div>
                  </div>

                </div>
                <div className="d-flex align-items-center collection-action-btns">
                  <div className="primary-btn">
                    <Link to="https://ptdna.eth.xyz">Profile</Link>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="nft2-hc-slide-single">
            <div className="top-img">
              <img
                src="https://bafkreibgmn75abcpxn2ldjdik7jee5bk32oky4rattucq2xwdfsuc5sdmm.ipfs.nftstorage.link/  "
                className="img-fluid rounded-custom w-100"
                style={{ width: '500px', height: '300px' }}
                alt="image not found"
              />
            </div>
            <div className="hc-slide-info">
              <div className="slide-info-content">
                <div className="slide-title">
                  <h3 className="h4">PT. Lokal Brand Indonesia</h3>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="author-info d-flex align-items-center">
                      <div className="author-thumb">
                        <img
                          src="https://bafkreick5awmz2rmsgwuy2i45e6aqytiho3r5o3nphz2asi2geq5ra4hve.ipfs.nftstorage.link/"
                          className="img-fluid rounded-circle"
                          alt="item-thumb"
                          style={{ width: '50px', height: '70px' }}
                        />
                      </div>
                      <div className="author-title">
                        <span>Aggregator</span>
                        <h6>Alfin Suhanda</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="author-info d-flex align-items-center">
                      <div className="author-thumb">
                        <img
                          src="https://bafybeigej5h64zb4xecvadc342kf32sobd4d475xdku3manlw7vswfepxi.ipfs.nftstorage.link/"
                          className="img-fluid rounded-circle"
                          alt="item-thumb"
                          style={{ width: '70px', height: '50px' }}
                        />
                      </div>
                      <div className="author-title">
                        <span>Commodity</span>
                        <h6>Dry Corn</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="bid-amount">
                      <span>Contract Limit</span>
                      <h4>25.000.000 M2</h4>
                      <span className="currency">(USDT 3,700,000,-)</span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="bid-amount">
                      <span>Farmers Under Management</span>
                      <h4>383 Farmers</h4>
                     
                    </div>
                  </div>

                </div>
                <div className="d-flex align-items-center collection-action-btns">
                  <div className="primary-btn">
                    <Link to="https://lokalbrandindonesia.eth.xyz">Profile</Link>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>


        <SwiperSlide>
          <div className="nft2-hc-slide-single">
            <div className="top-img">
              <img
                src="https://bafkreic6d237yq7vlrko4cfnsptpmx7bbzvoghye5cefjcgdpcgpzvufvu.ipfs.nftstorage.link/ "
                className="img-fluid rounded-custom w-100"
                style={{ width: '500px', height: '300px' }}
                alt="image not found"
              />
            </div>
            <div className="hc-slide-info">
              <div className="slide-info-content">
                <div className="slide-title">
                  <h3 className="h4">PT. Agromas Perdana</h3>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="author-info d-flex align-items-center">
                      <div className="author-thumb">
                        <img
                          src="https://bafkreigq5flbz22bwdsuvte5nhzu5kt4rylanggtun3wsm2rsionieiojq.ipfs.nftstorage.link/"
                          className="img-fluid rounded-circle"
                          alt="item-thumb"
                          style={{ width: '50px', height: '70px' }}
                        />
                      </div>
                      <div className="author-title">
                        <span>Aggregator</span>
                        <h6>George Gunawan Bsc</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="author-info d-flex align-items-center">
                      <div className="author-thumb">
                        <img
                          src="https://bafybeigej5h64zb4xecvadc342kf32sobd4d475xdku3manlw7vswfepxi.ipfs.nftstorage.link/"
                          className="img-fluid rounded-circle"
                          alt="item-thumb"
                          style={{ width: '70px', height: '50px' }}
                        />
                      </div>
                      <div className="author-title">
                        <span>Commodity</span>
                        <h6>Durian Fruit</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="bid-amount">
                      <span>Contract Limit</span>
                      <h4>25.000.000 M2</h4>
                      <span className="currency">(USDT 12,700,000,-)</span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="bid-amount">
                      <span>Farmers Under Management</span>
                      <h4>689 Farmers</h4>
                     
                    </div>
                  </div>

                </div>
                <div className="d-flex align-items-center collection-action-btns">
                  <div className="primary-btn">
                    <Link to="https://lokalbrandindonesia.eth.xyz">Profile</Link>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>



      </Swiper>
      <div className="swiper-nav-control custom-control">
        <span className="swiper-button-next">
          <i className="fa-solid fa-arrow-left"></i>
        </span>
        <span className="swiper-button-prev">
          <i className="fa-solid fa-arrow-right"></i>
        </span>
      </div>
    </>
  );
}

const counter = ({ days, hours, minutes, seconds }) => {
  return (
    <ul className="downcount-timer">
      <li>
        <h4 className="days">{days}</h4>
        <span>Days</span>
      </li>
      <li>
        <h4 className="hours">{hours}</h4>
        <span>Hour</span>
      </li>
      <li>
        <h4 className="minutes">{minutes}</h4>
        <span>Min</span>
      </li>
      <li>
        <h4 className="seconds">{seconds}</h4>
        <span>Sec</span>
      </li>
    </ul>
  );
};
