import { exploreCollectionTwo } from "../../utils/data";
import React from "react";
import { Link } from "react-router-dom";

export default function ExploreTabs() {
  return (
    <section className="explore-filter position-relative">
      <div className="container">
        <div className="explore-top">
          <div className="row">
            <div className="col-xl-5">
              <div className="explore-title">
                <h2 className="mb-0">Explore NFTs </h2>
              </div>
            </div>
            <div className="col-xl-7">
              <div className="explore-btns filter-button-group">
                <ul
                  className="nav justify-content-lg-end justify-content-center"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-art-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-art"
                      type="button"
                      role="tab"
                      aria-controls="pills-art"
                      aria-selected="true"
                    >
                      Art
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-photography-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-photography"
                      type="button"
                      role="tab"
                      aria-controls="pills-photography"
                      aria-selected="false"
                    >
                      Photography
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-games-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-games"
                      type="button"
                      role="tab"
                      aria-controls="pills-games"
                      aria-selected="false"
                    >
                      Games
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-collectibles-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-collectibles"
                      type="button"
                      role="tab"
                      aria-controls="pills-collectibles"
                      aria-selected="false"
                    >
                      Collectibles
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-trading-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-trading"
                      type="button"
                      role="tab"
                      aria-controls="pills-trading"
                      aria-selected="false"
                    >
                      Trading cards
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="filter-bottom">
          <div className="row grid g-4" id="MixItUp">
            <div className="col-12">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-art"
                  role="tabpanel"
                  aria-labelledby="pills-art-tab"
                >
                  <div className="row">
                    {exploreCollectionTwo.map((nftItem, i) => (
                      <div className="col-xl-3 col-lg-4 col-md-6" key={i + 1}>
                        <div className="single-nft-item mb-4">
                          <div className="nft-item-wrapper rounded p-3">
                            <Link
                              to={nftItem.nftDetails}
                              className="item-image position-relative"
                            >
                              <img
                                src={nftItem.nftImage}
                                alt="nft item"
                                className="img-fluid rounded-top"
                              />
                            </Link>
                            <div className="buy-now-btn primary-btn">
                              <Link to={nftItem.nftDetails}>buy now</Link>
                            </div>
                            <div className="nft-item-content-wrap pt-4">
                              <Link to={nftItem.nftDetails}>
                                <h2 className="h6">{nftItem.nftName}</h2>
                              </Link>
                              <hr />
                              <div className="nft-item-footer d-flex align-items-center justify-content-between">
                                <div className="item-price-wrapper d-flex align-items-center">
                                  <span>
                                    <i className="fab fa-ethereum"></i>
                                  </span>
                                  <div className="item-price ms-3">
                                    <p className="mb-0">Reserve price</p>
                                    <h5 className="mb-0">
                                      {nftItem.nftPrice} ETH
                                    </h5>
                                  </div>
                                </div>
                                <span className="item-like">
                                  <i className="fa-solid fa-heart"></i>
                                  {nftItem.nftLikes}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-photography"
                  role="tabpanel"
                  aria-labelledby="pills-photography-tab"
                >
                  <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="single-nft-item mb-4 mb-lg-0">
                        <div className="nft-item-wrapper rounded p-3">
                          <Link
                            to="/product-details"
                            className="item-image position-relative"
                          >
                            <img
                              src="assets/img/filter-2.jpg"
                              alt="nft item"
                              className="img-fluid rounded-top"
                            />
                          </Link>
                          <div className="buy-now-btn primary-btn">
                            <Link to="/product-details">buy now</Link>
                          </div>
                          <div className="nft-item-content-wrap pt-4">
                            <Link to="/product-details">
                              <h2 className="h6">PharaGods-NFT</h2>
                            </Link>
                            <hr />
                            <div className="nft-item-footer d-flex align-items-center justify-content-between">
                              <div className="item-price-wrapper d-flex align-items-center">
                                <span>
                                  <i className="fab fa-ethereum"></i>
                                </span>
                                <div className="item-price ms-3">
                                  <p className="mb-0">Reserve price</p>
                                  <h5 className="mb-0">0.0521 ETH</h5>
                                </div>
                              </div>
                              <span className="item-like">
                                <i className="fa-solid fa-heart"></i> 5k Like
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="single-nft-item mb-4 mb-lg-0">
                        <div className="nft-item-wrapper rounded p-3">
                          <Link
                            to="/product-details"
                            className="item-image position-relative"
                          >
                            <img
                              src="assets/img/filter-6.jpg"
                              alt="nft item"
                              className="img-fluid rounded-top"
                            />
                          </Link>

                          <div className="buy-now-btn primary-btn">
                            <Link to="/product-details">buy now</Link>
                          </div>
                          <div className="nft-item-content-wrap pt-4">
                            <Link to="/product-details">
                              <h2 className="h6">Anonymous Genesis</h2>
                            </Link>
                            <hr />
                            <div className="nft-item-footer d-flex align-items-center justify-content-between">
                              <div className="item-price-wrapper d-flex align-items-center">
                                <span>
                                  <i className="fab fa-ethereum"></i>
                                </span>
                                <div className="item-price ms-3">
                                  <p className="mb-0">Reserve price</p>
                                  <h5 className="mb-0">0.00538 ETH</h5>
                                </div>
                              </div>
                              <span className="item-like">
                                <i className="fa-solid fa-heart"></i> 11k Like
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-games"
                  role="tabpanel"
                  aria-labelledby="pills-games-tab"
                >
                  <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="single-nft-item mb-4 mb-lg-0">
                        <div className="nft-item-wrapper rounded p-3">
                          <Link
                            to="/product-details"
                            className="item-image position-relative"
                          >
                            <img
                              src="assets/img/filter-3.jpg"
                              alt="nft item"
                              className="img-fluid rounded-top"
                            />
                          </Link>
                          <div className="buy-now-btn primary-btn">
                            <Link to="/product-details">buy now</Link>
                          </div>
                          <div className="nft-item-content-wrap pt-4">
                            <Link to="/product-details">
                              <h2 className="h6">Project Arowana Prime</h2>
                            </Link>
                            <hr />
                            <div className="nft-item-footer d-flex align-items-center justify-content-between">
                              <div className="item-price-wrapper d-flex align-items-center">
                                <span>
                                  <i className="fab fa-ethereum"></i>
                                </span>
                                <div className="item-price ms-3">
                                  <p className="mb-0">Reserve price</p>
                                  <h5 className="mb-0">0.0421 ETH</h5>
                                </div>
                              </div>
                              <span className="item-like">
                                <i className="fa-solid fa-heart"></i> 10k Like
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="single-nft-item mb-4 mb-lg-0">
                        <div className="nft-item-wrapper rounded p-3">
                          <Link
                            to="/product-details"
                            className="item-image position-relative"
                          >
                            <img
                              src="assets/img/filter-7.jpg"
                              alt="nft item"
                              className="img-fluid rounded-top"
                            />
                          </Link>
                          <div className="buy-now-btn primary-btn">
                            <Link to="/product-details">buy now</Link>
                          </div>
                          <div className="nft-item-content-wrap pt-4">
                            <Link to="/product-details">
                              <h2 className="h6">Invisible Friends</h2>
                            </Link>
                            <hr />
                            <div className="nft-item-footer d-flex align-items-center justify-content-between">
                              <div className="item-price-wrapper d-flex align-items-center">
                                <span>
                                  <i className="fab fa-ethereum"></i>
                                </span>
                                <div className="item-price ms-3">
                                  <p className="mb-0">Reserve price</p>
                                  <h5 className="mb-0">0.0421 ETH</h5>
                                </div>
                              </div>
                              <span className="item-like">
                                <i className="fa-solid fa-heart"></i> 15k Like
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-collectibles"
                  role="tabpanel"
                  aria-labelledby="pills-collectibles-tab"
                >
                  <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="single-nft-item mb-4 mb-lg-0">
                        <div className="nft-item-wrapper rounded p-3">
                          <Link
                            to="/product-details"
                            className="item-image position-relative"
                          >
                            <img
                              src="assets/img/filter-4.jpg"
                              alt="nft item"
                              className="img-fluid rounded-top"
                            />
                          </Link>
                          <div className="buy-now-btn primary-btn">
                            <Link to="/product-details">buy now</Link>
                          </div>
                          <div className="nft-item-content-wrap pt-4">
                            <Link to="/product-details">
                              <h2 className="h6">Time of Roger</h2>
                            </Link>
                            <hr />
                            <div className="nft-item-footer d-flex align-items-center justify-content-between">
                              <div className="item-price-wrapper d-flex align-items-center">
                                <span>
                                  <i className="fab fa-ethereum"></i>
                                </span>
                                <div className="item-price ms-3">
                                  <p className="mb-0">Reserve price</p>
                                  <h5 className="mb-0">0.0478 ETH</h5>
                                </div>
                              </div>
                              <span className="item-like">
                                <i className="fa-solid fa-heart"></i> 15k Like
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-trading"
                  role="tabpanel"
                  aria-labelledby="pills-trading-tab"
                >
                  <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="single-nft-item mb-4 mb-lg-0">
                        <div className="nft-item-wrapper rounded p-3">
                          <Link
                            to="/product-details"
                            className="item-image position-relative"
                          >
                            <img
                              src="assets/img/filter-3.jpg"
                              alt="nft item"
                              className="img-fluid rounded-top"
                            />
                          </Link>

                          <div className="buy-now-btn primary-btn">
                            <Link to="/product-details">buy now</Link>
                          </div>
                          <div className="nft-item-content-wrap pt-4">
                            <Link to="/product-details">
                              <h2 className="h6">Project Arowana Prime</h2>
                            </Link>
                            <hr />
                            <div className="nft-item-footer d-flex align-items-center justify-content-between">
                              <div className="item-price-wrapper d-flex align-items-center">
                                <span>
                                  <i className="fab fa-ethereum"></i>
                                </span>
                                <div className="item-price ms-3">
                                  <p className="mb-0">Reserve price</p>
                                  <h5 className="mb-0">0.0421 ETH</h5>
                                </div>
                              </div>
                              <span className="item-like">
                                <i className="fa-solid fa-heart"></i> 10k Like
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="single-nft-item mb-4 mb-lg-0">
                        <div className="nft-item-wrapper rounded p-3">
                          <Link
                            to="/product-details"
                            className="item-image position-relative"
                          >
                            <img
                              src="assets/img/filter-2.jpg"
                              alt="nft item"
                              className="img-fluid rounded-top"
                            />
                          </Link>
                          <div className="buy-now-btn primary-btn">
                            <Link to="/product-details">buy now</Link>
                          </div>
                          <div className="nft-item-content-wrap pt-4">
                            <Link to="/product-details">
                              <h2 className="h6">PharaGods-NFT</h2>
                            </Link>
                            <hr />
                            <div className="nft-item-footer d-flex align-items-center justify-content-between">
                              <div className="item-price-wrapper d-flex align-items-center">
                                <span>
                                  <i className="fab fa-ethereum"></i>
                                </span>
                                <div className="item-price ms-3">
                                  <p className="mb-0">Reserve price</p>
                                  <h5 className="mb-0">0.0521 ETH</h5>
                                </div>
                              </div>
                              <span className="item-like">
                                <i className="fa-solid fa-heart"></i> 5k Like
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
