import React from "react";
import { Link } from "react-router-dom";

const CollectionsAlbum = () => {
  return (
    <section className="nft-collection-page pt-120 pb-120">
      <div className="container">
        <div className="row g-4">
          <div className="col-lg-4 col-md-6">
            <div className="cc-column rounded">
              <div className="row">
                <div className="col-6">
                  <div className="thumb-wrapper overflow-hidden mb-4 rounded">
                    <Link to="/product-details">
                      <img
                        src="assets/img/cc-1.jpg"
                        className="img-fluid"
                        alt="featured "
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-6">
                  <div className="thumb-wrapper overflow-hidden mb-4 rounded">
                    <Link to="/product-details">
                      <img
                        src="assets/img/cc-2.jpg"
                        className="img-fluid"
                        alt="feature "
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="cc-cover-photo thumb-wrapper overflow-hidden rounded">
                <Link to="/product-details">
                  <img
                    src="assets/img/cc-3.jpg"
                    className="img-fluid"
                    alt="feature "
                  />
                </Link>
              </div>
              <div className="cc-profile position-relative">
                <img
                  src="assets/img/cc-author-1.png"
                  className="rounded-circle"
                  alt="profile"
                />
                <span className="profile-check badge rounded-circle p-2">
                  <i className="fa-solid fa-check"></i>
                </span>
              </div>
              <div className="cc-title text-center">
                <Link to="explore-collection.html">
                  <h2 className="h5">Photography</h2>
                </Link>
              </div>
              <div className="cc-count text-center">
                <Link to="explore-collection.html">165 item</Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="cc-column rounded">
              <div className="row">
                <div className="col-6">
                  <div className="thumb-wrapper overflow-hidden mb-4 rounded">
                    <Link to="/product-details">
                      <img
                        src="assets/img/cc-4.jpg"
                        className="img-fluid"
                        alt="featured "
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-6">
                  <div className="thumb-wrapper overflow-hidden mb-4 rounded">
                    <Link to="/product-details">
                      <img
                        src="assets/img/cc-5.jpg"
                        className="img-fluid"
                        alt="feature "
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="cc-cover-photo thumb-wrapper overflow-hidden rounded">
                <Link to="/product-details">
                  <img
                    src="assets/img/cc-6.jpg"
                    className="img-fluid"
                    alt="feature "
                  />
                </Link>
              </div>
              <div className="cc-profile position-relative">
                <img
                  src="assets/img/cc-author-2.png"
                  className="rounded-circle"
                  alt="profile"
                />
                <span className="profile-check badge rounded-circle p-2">
                  <i className="fa-solid fa-check"></i>
                </span>
              </div>
              <div className="cc-title text-center">
                <Link to="explore-collection.html">
                  <h2 className="h5">Digital Art</h2>
                </Link>
              </div>
              <div className="cc-count text-center">
                <Link to="explore-collection.html">155 item</Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="cc-column rounded">
              <div className="row">
                <div className="col-6">
                  <div className="thumb-wrapper overflow-hidden mb-4 rounded">
                    <Link to="/product-details">
                      <img
                        src="assets/img/cc-7.jpg"
                        className="img-fluid"
                        alt="featured "
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-6">
                  <div className="thumb-wrapper overflow-hidden mb-4 rounded">
                    <Link to="/product-details">
                      <img
                        src="assets/img/cc-8.jpg"
                        className="img-fluid"
                        alt="feature "
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="cc-cover-photo thumb-wrapper overflow-hidden rounded">
                <Link to="/product-details">
                  <img
                    src="assets/img/cc-9.jpg"
                    className="img-fluid"
                    alt="feature "
                  />
                </Link>
              </div>
              <div className="cc-profile position-relative">
                <img
                  src="assets/img/cc-author-3.png"
                  className="rounded-circle"
                  alt="profile"
                />
                <span className="profile-check badge rounded-circle p-2">
                  <i className="fa-solid fa-check"></i>
                </span>
              </div>
              <div className="cc-title text-center">
                <Link to="explore-collection.html">
                  <h2 className="h5">Music</h2>
                </Link>
              </div>
              <div className="cc-count text-center">
                <Link to="explore-collection.html">140 item</Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="cc-column rounded">
              <div className="row">
                <div className="col-6">
                  <div className="thumb-wrapper overflow-hidden mb-4 rounded">
                    <Link to="/product-details">
                      <img
                        src="assets/img/cc-10.jpg"
                        className="img-fluid"
                        alt="featured "
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-6">
                  <div className="thumb-wrapper overflow-hidden mb-4 rounded">
                    <Link to="/product-details">
                      <img
                        src="assets/img/cc-11.jpg"
                        className="img-fluid"
                        alt="feature "
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="cc-cover-photo thumb-wrapper overflow-hidden rounded">
                <Link to="/product-details">
                  <img
                    src="assets/img/cc-12.jpg"
                    className="img-fluid"
                    alt="feature "
                  />
                </Link>
              </div>
              <div className="cc-profile position-relative">
                <img
                  src="assets/img/cc-author-4.png"
                  className="rounded-circle"
                  alt="profile"
                />
                <span className="profile-check badge rounded-circle p-2">
                  <i className="fa-solid fa-check"></i>
                </span>
              </div>
              <div className="cc-title text-center">
                <Link to="explore-collection.html">
                  <h2 className="h5">3d Illustration</h2>
                </Link>
              </div>
              <div className="cc-count text-center">
                <Link to="explore-collection.html">156 item</Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="cc-column rounded">
              <div className="row">
                <div className="col-6">
                  <div className="thumb-wrapper overflow-hidden mb-4 rounded">
                    <Link to="/product-details">
                      <img
                        src="assets/img/cc-13.jpg"
                        className="img-fluid"
                        alt="featured "
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-6">
                  <div className="thumb-wrapper overflow-hidden mb-4 rounded">
                    <Link to="/product-details">
                      <img
                        src="assets/img/cc-14.jpg"
                        className="img-fluid"
                        alt="feature "
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="cc-cover-photo thumb-wrapper overflow-hidden rounded">
                <Link to="/product-details">
                  <img
                    src="assets/img/cc-15.jpg"
                    className="img-fluid"
                    alt="feature "
                  />
                </Link>
              </div>
              <div className="cc-profile position-relative">
                <img
                  src="assets/img/cc-author-5.png"
                  className="rounded-circle"
                  alt="profile"
                />
                <span className="profile-check badge rounded-circle p-2">
                  <i className="fa-solid fa-check"></i>
                </span>
              </div>
              <div className="cc-title text-center">
                <Link to="explore-collection.html">
                  <h2 className="h5">Animation</h2>
                </Link>
              </div>
              <div className="cc-count text-center">
                <Link to="explore-collection.html">140 item</Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="cc-column rounded">
              <div className="row">
                <div className="col-6">
                  <div className="thumb-wrapper overflow-hidden mb-4 rounded">
                    <Link to="/product-details">
                      <img
                        src="assets/img/cc-16.jpg"
                        className="img-fluid"
                        alt="featured "
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-6">
                  <div className="thumb-wrapper overflow-hidden mb-4 rounded">
                    <Link to="/product-details">
                      <img
                        src="assets/img/cc-17.jpg"
                        className="img-fluid"
                        alt="feature "
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="cc-cover-photo thumb-wrapper overflow-hidden rounded">
                <Link to="/product-details">
                  <img
                    src="assets/img/cc-18.jpg"
                    className="img-fluid"
                    alt="feature "
                  />
                </Link>
              </div>
              <div className="cc-profile position-relative">
                <img
                  src="assets/img/cc-author-6.png"
                  className="rounded-circle"
                  alt="profile"
                />
                <span className="profile-check badge rounded-circle p-2">
                  <i className="fa-solid fa-check"></i>
                </span>
              </div>
              <div className="cc-title text-center">
                <Link to="explore-collection.html">
                  <h2 className="h5">Virtual Reality</h2>
                </Link>
              </div>
              <div className="cc-count text-center">
                <Link to="explore-collection.html">125 item</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="nft-pagination text-center margin-top-50">
          <ul>
            <li>
              <Link to="#">
                <i className="fa-solid fa-arrow-left"></i>
              </Link>
            </li>
            <li>
              <Link to="#">1</Link>
            </li>
            <li className="active">
              <Link to="#">2</Link>
            </li>
            <li>
              <Link to="#">3</Link>
            </li>
            <li>
              <Link to="#">
                <i className="fa-solid fa-arrow-right"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default CollectionsAlbum;
