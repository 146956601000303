import React from "react";
import BlogCategory from "../../components/blogs/BlogCategory";
import BlogPostList from "../../components/blogs/BlogPostList";
import BlogSearch from "../../components/blogs/BlogSearch";
import RecentPosts from "../../components/blogs/RecentPosts";
import Tags from "../../components/blogs/Tags";
import BreadCrumb from "../../components/common/BreadCrumb";
import Layout from "../../components/layout/Layout";

const BlogList = () => {
  return (
    <Layout title="Blog List">
      <BreadCrumb
        pageTitle="Blog List"
        parentPage="Home"
        prevPage="Pages"
        currentPage="Blog List"
      />
      <div className="nft-blog-area pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <BlogPostList />
            </div>
            <div className="col-lg-4">
              <div className="nft-sidebar-area">
                <BlogSearch />
                <RecentPosts />
                <BlogCategory />
                <Tags />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BlogList;
