import React from "react";
import { Link } from "react-router-dom";

export default function BlogCategory() {
  return (
    <div className="blog-sidebar-widget category-widget">
      <div className="widget-title">
        <h4 className="h5">Categories</h4>
      </div>
      <div className="categories-list">
        <ul>
          <li>
            <Link to="/blog-list">
              Arts <span>4</span>
            </Link>
          </li>
          <li>
            <Link to="/blog-list">
              Photography <span>2</span>
            </Link>
          </li>
          <li>
            <Link to="/blog-list">
              Collectibeles <span>8</span>
            </Link>
          </li>
          <li>
            <Link to="/blog-list">
              Technology <span>10</span>
            </Link>
          </li>
          <li>
            <Link to="/blog-list">
              Sports <span>4</span>
            </Link>
          </li>
          <li>
            <Link to="/blog-list">
              Domains <span>30</span>
            </Link>
          </li>
          <li>
            <Link to="/blog-list">
              Marketing <span>26</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
