import Pagination from "../../components/Pagination";
import { blogData } from "../../utils/data";
import React from "react";
import { Link } from "react-router-dom";

export default function BlogPost() {
  return (
    <section className="nft-blog-grid pt-120 pb-120">
      <div className="container">
        <div className="row g-4 justify-content-center">
          {blogData.map((blogItem, i) => (
            <div className="col-xl-4 col-lg-6 col-md-10" key={i + 1}>
              <div className="nft-blog-column">
                <article>
                  <div className="figure-img mb-4">
                    <Link to={blogItem.details}>
                      <img
                        src={blogItem.thumb}
                        className="img-fluid"
                        alt="feature"
                      />
                    </Link>
                  </div>
                  <Link to={blogItem.details} className="blog-title">
                    <h2 className="h5">{blogItem.title}</h2>
                  </Link>
                  <p>{blogItem.exerpt}</p>
                  <div className="blog-btns">
                    <Link to="/blog-list">{blogItem.tagOne}</Link>
                    <Link to="/blog-list">{blogItem.tagTwo}</Link>
                  </div>
                  <hr className="spacer" />
                  <div className="blog-author d-flex align-items-center justify-content-between">
                    <div className="author-box d-flex align-items-center">
                      <img
                        src={blogItem.authorImg}
                        className="img-fluid rounded-circle"
                        alt="author"
                      />
                      <div className="author-info">
                        <h6>{blogItem.auhtorName}</h6>
                        <span>{blogItem.date}</span>
                      </div>
                    </div>
                    <div className="viewmore-btn">
                      <Link to="/blog-details">
                        <i className="fas fa-link"></i>
                      </Link>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          ))}
        </div>
        <Pagination />
      </div>
    </section>
  );
}
