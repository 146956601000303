import Pagination from "../Pagination";
import { liveAuctionData } from "../../utils/data";
import React from "react";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";

export default function LiveAuctionAll() {
  return (
    <section className="nft1-live-auction pt-120 pb-120">
      <span className="lv-shape">
        <img src="/img/lv-shape.png" alt="shape" />
      </span>
      <div className="container">
        <div className="row">
          <div className="auction-title text-center text-xl-start">
            <h2 className="text-white">Explore live auction</h2>
          </div>
        </div>
        <div className="nft1-live-auction-wrapper">
          <div className="row g-4 justify-content-center">
            {liveAuctionData.map((item, i) => (
              <div
                className="col-xxl-3 col-xl-4 col-lg-5 col-md-6 col-sm-8"
                key={i + 1}
              >
                <div className="auction-column">
                  <div className="fc-wrapper">
                    <div className="thumb-wrapper">
                      <img
                        src={item.nftImage}
                        className="img-fluid rounded-top"
                        alt="auction thumbnail"
                      />
                    </div>
                    <div className="fc-title">
                      <Link to={item.nftDetails}>
                        <h6>Supperplastic crypto</h6>
                      </Link>
                    </div>
                    <div className="fc-spacer"></div>
                    <div className="fc-bottom">
                      <div className="fc-bt-left">
                        <div className="icon-wrapper">
                          <span>
                            <i className="fa-brands fa-ethereum"></i>
                          </span>
                        </div>
                        <div className="fc-reserve">
                          <span className="rs-subtitle">
                            {item.nftPriceTitle}
                          </span>
                          <span className="rs-title">{item.nftPrice}</span>
                        </div>
                      </div>
                      <div className="fc-right">
                        <span>
                          <i className="fa-solid fa-heart"></i>12
                        </span>
                      </div>
                    </div>
                    <div className="auction-timing">
                      <div className="auction-countdown">
                        <Countdown
                          date={Date.now() + 5000000}
                          renderer={counter}
                          autoStart={true}
                        />
                      </div>
                      <div className="buy-now-btn">
                        <Link to={item.nftDetails}>Buy now</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Pagination />
      </div>
    </section>
  );
}

const counter = ({ hours, minutes, seconds }) => {
  return (
    <ul className="downcount-timer">
      <li className="pe-1">
        <span className="hours">{hours}</span>h
      </li>
      <li className="pe-1">
        <span className="minutes">{minutes}</span>m
      </li>
      <li className="pe-1">
        <span className="seconds">{seconds}</span>s
      </li>
      <li>Left</li>
    </ul>
  );
};
