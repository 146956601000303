import React from "react";
import Layout from "../components/layout/Layout";


const TradeCommodity = () => {
  return (
    <Layout title="TradeCommodity">
    
           <iframe
        title="Book-order"
        src="https://trade.d5.xyz/trade"
        width="100%"
        height="1000px"
        frameBorder="20"
        scrolling="no"
        style={{ marginTop: '200px' }}
      ></iframe>
      
    </Layout>
  );
};

export default TradeCommodity;
