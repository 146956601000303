import React from "react";
import Layout from "../components/layout/Layout";


const Wallet = () => {
  return (
    <Layout title="Wallet">
    
           <iframe
        title="DexScreener"
        src="https://dexscreener.com/ethereum/0xCeb4004cb6399875D5ec759bd7151C2aC56D2650"
        width="100%"
        height="1000px"
        frameBorder="20"
        scrolling="no"
        style={{ marginTop: '200px' }}
      ></iframe>
      
    </Layout>
  );
};

export default Wallet;
