import React from "react";
import Pagination from "./common/Pagination";
import { Link } from "react-router-dom";

const ActivityTable = () => {
  return (
    <section className="nft-activity-area pt-120 pb-120 position-relative overflow-hidden">
          
      <img
        src="assets/img/shapes/hero-top-left.svg"
        alt="shape"
        className="shape-top-left position-absolute"
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="activity-filter">
              <p>Tradeble Token</p>
             

           
            </div>
         </div>
          <div className="col-lg-9">
            <div className="activity-list">
              <div className="table-responsive">
                <table className="table table-striped table-bordered align-middle">
                  <thead>
                    <tr>
                      <th>Token Symbol</th>
                      <th>Commodity</th>
                      <th>Grade</th>
                      <th>Minimum Redeem</th>
                      <th>Redeem Requirement</th>
                      <th>Trade link</th>
                    </tr>
                  </thead>
                  <tbody>
              
                    <tr>
                      <td className="item-info d-flex align-items-center">
                        <div className="thumb-wrapper">
                          <img
                            src="https://bafkreif424x7oul45eecjzi6yhuaspzlnf5wvgxzzoahh2pxxbd2tuvo2y.ipfs.nftstorage.link/"
                            className="img-fluid rounded-circle"
                            alt="mask"
                            style={{ width: '50px', height: '50px' }}
                          />
                        </div>
                        <div className="item-content ms-3">
                          <span>Skytopia</span>
                          <h6>SKYPIA</h6>
                        </div>
                      </td>
                      <td>
                        <div className="price-amount text-center">
                          <span>
                            <i className="d-block ms-4"></i>Utilities
                        </span>
                        </div>
                      </td>
                      <td>
                        <div className="quantity text-center">
                          <span>Tradeble</span>
                        </div>
                      </td>
                      <td>
                        <div className="sellar text-center">
                          <span>0</span>
                        </div>
                      </td>
                      <td>
                        <div className="buyer text-center">
                          <span>none</span>
                        </div>
                      </td>
                      <td>
                        <div className="time text-center">
                          <span>none</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="item-info d-flex align-items-center">
                        <div className="thumb-wrapper">
                          <img
                            src="https://bafkreic4powdu2sej2oycmlhk4p3bfp7x2sbspajwmehrjtuveomawwig4.ipfs.nftstorage.link/"
                            className="img-fluid rounded-circle"
                            alt="mask"
                            style={{ width: '50px', height: '50px' }}
                          />
                        </div>
                        <div className="item-content ms-3">
                          <span>Trisakti Rice</span>
                          <h6>Trident96</h6>
                        </div>
                      </td>
                      <td>
                        <div className="price-amount text-center">
                      
                          <span className="d-block ms-4">Rice</span>
                        </div>
                      </td>
                      <td>
                        <div className="quantity text-center">
                          <span>Premium</span>
                        </div>
                      </td>
                      <td>
                        <div className="sellar text-center">
                          <span>5</span>
                        </div>
                      </td>
                      <td>
                        <div className="buyer text-center">
                       
                <span>Depositories</span>
              
                        </div>
                      </td>
                      <td>
                        <div className="time text-center">
                        <Link to="https://trade.d5.xyz/trade/0xbBB43117EA586bCB019dc47257DCd45c9e4D479a/0xafCCb1534389793bE0b995aE2190027A52Eb9C94">
                <span>Trade Here</span>
              </Link>
                        </div>
                      </td>
                    </tr>
                    

                    <tr>
                      <td className="item-info d-flex align-items-center">
                        <div className="thumb-wrapper">
                          <img
                            src="https://bafkreihdvteaemehdkn3o2mpfb3diur4kcnisrxbtvsmackhjcodpnpxhm.ipfs.nftstorage.link/"
                            className="img-fluid rounded-circle"
                            style={{ width: '50px', height: '50px' }}
                            alt="mask"
                          />
                        </div>
                        <div className="item-content ms-3">
                          <span>Zumtech</span>
                          <h6>Zumtech121</h6>
                        </div>
                      </td>
                      <td>
                        <div className="price-amount text-center">
                          <span>
                            <i className="d-block ms-4"></i>Fertilizer
                        </span>
                        </div>
                      </td>
                      <td>
                        <div className="quantity text-center">
                          <span>Organic</span>
                        </div>
                      </td>
                      <td>
                        <div className="sellar text-center">
                          <span>60</span>
                        </div>
                      </td>
                      <td>
                        <div className="buyer text-center">
                          <span>Depositories</span>
                        </div>
                      </td>
                      <td>
                        <div className="time text-center">
                        <Link to="https://trade.d5.xyz/trade/0x2075e647A2866C4158E95B0ae4cc6Dbc594F2A64/0xafCCb1534389793bE0b995aE2190027A52Eb9C94">
                <span>Trade Here</span>
              </Link>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td className="item-info d-flex align-items-center">
                        <div className="thumb-wrapper">
                          <img
                            src="https://bafkreihwgl3dijxbqnxseec4l6s2nohtjsa2tvbvoupz7ft6l33ykdnrne.ipfs.nftstorage.link/"
                            className="img-fluid rounded-circle"
                            alt="mask"
                            style={{ width: '50px', height: '50px' }}
                          />
                        </div>
                        <div className="item-content ms-3">
                          <span>Dry Corn</span>
                          <h6>JAPIDN11</h6>
                        </div>
                      </td>
                      <td>
                        <div className="price-amount text-center">
                      
                          <span className="d-block ms-4">Dry Corn</span>
                        </div>
                      </td>
                      <td>
                        <div className="quantity text-center">
                          <span>Food Grade</span>
                        </div>
                      </td>
                      <td>
                        <div className="sellar text-center">
                          <span>5000</span>
                        </div>
                      </td>
                      <td>
                        <div className="buyer text-center">
                       
                <span>Delivery</span>
              
                        </div>
                      </td>
                      <td>
                        <div className="time text-center">
                        <Link to="https://trade.d5.xyz/trade/0x704Acbc1C549375A4b51F16E69f1EbA5BdE6D469/0xafCCb1534389793bE0b995aE2190027A52Eb9C94">
                <span>Trade Here</span>
              </Link>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td className="item-info d-flex align-items-center">
                        <div className="thumb-wrapper">
                          <img
                            src="https://bafkreihwgl3dijxbqnxseec4l6s2nohtjsa2tvbvoupz7ft6l33ykdnrne.ipfs.nftstorage.link/"
                            className="img-fluid rounded-circle"
                            alt="mask"
                            style={{ width: '50px', height: '50px' }}
                          />
                        </div>
                        <div className="item-content ms-3">
                          <span>Dry Corn</span>
                          <h6>JAPIDN22</h6>
                        </div>
                      </td>
                      <td>
                        <div className="price-amount text-center">
                      
                          <span className="d-block ms-4">Dry Corn</span>
                        </div>
                      </td>
                      <td>
                        <div className="quantity text-center">
                          <span>Feed Grade</span>
                        </div>
                      </td>
                      <td>
                        <div className="sellar text-center">
                          <span>5000</span>
                        </div>
                      </td>
                      <td>
                        <div className="buyer text-center">
                       
                <span>Delivery</span>
              
                        </div>
                      </td>
                      <td>
                        <div className="time text-center">
                        <Link to="https://trade.d5.xyz/trade/0xEC3d838632cE70a4B0A0aA4b7e7090D9d4934F8c/0xafCCb1534389793bE0b995aE2190027A52Eb9C94">
                <span>Trade Here</span>
              </Link>
                        </div>
                      </td>
                    </tr>




                  </tbody>
                </table>
              </div>
              <Pagination />
            </div>
          </div>
        </div>
      </div>
      <img
        src="assets/img/shapes/hero-top-right.svg"
        alt="shape"
        className="shape-right-top position-absolute"
      />
      <img
        src="assets/img/shapes/hero-top-right.svg"
        alt="shape"
        className="right-0 shape-bottom position-absolute"
      />
    </section>
  );
};

export default ActivityTable;
