import React, { useState } from "react";
import ModalVideo from "react-modal-video";
export default function VideoModal({ large }) {
  const [isOpen, setOpen] = useState(false);
  return (
    <div>
      {typeof window !== "undefined" && (
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="6WZOxnYi4Cs"
          onClose={() => setOpen(false)}
        />
      )}

      {large ? (
        <div
          className="video-play nft1-popup-video"
          onClick={() => setOpen(true)}
        >
          <a href="#!" onClick={(e) => e.preventDefault()}>
            <i className="fa-solid fa-play"></i>
          </a>
        </div>
      ) : (
        <div
          className="nft1-popup-video audio-btn"
          onClick={(e) => e.preventDefault()}
        >
          <a href="#!" onClick={() => setOpen(true)}>
            <i className="fa-solid fa-play"></i>
          </a>
        </div>
      )}
    </div>
  );
}
