import React from "react";
import CollectionsAlbum from "../components/collections/CollectionsAlbum";
import BreadCrumb from "../components/common/BreadCrumb";
import Layout from "../components/layout/Layout";

const Collections = () => {
  return (
    <Layout title="Collections">
      <BreadCrumb
        pageTitle="Collections"
        parentPage="Home"
        prevPage="Pages"
        currentPage="Collections"
      />
      <CollectionsAlbum />
    </Layout>
  );
};

export default Collections;
