import React from "react";
import { Link } from "react-router-dom";

const AddressBox = () => {
  return (
    <section className="nft-address-area pt-120 pb-90">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="nft-address-title text-center">
              <div className="title-headline">
                <h2>You need assistance?</h2>
              </div>
              <div className="title-desc">
                <p>
                  We will respond within 24 Hours
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-address">
          <div className="row g-4">
            <div className="col-lg-4 col-md-6">
              <div className="address-column rounded">
                <div className="icon-wrapper">
                  <span>
                    <Phone />
                  </span>
                </div>
                <div className="address-info">
                  <h4>Whats'app Admin</h4>
                  <span>
                    <Link to="tel:+6287864330666">+62 878 6433 0666</Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="address-column rounded">
                <div className="icon-wrapper">
                  <span>
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 0C7.168 0 0 7.168 0 16C0 24.832 7.168 32 16 32H24V28.8H16C9.056 28.8 3.2 22.944 3.2 16C3.2 9.056 9.056 3.2 16 3.2C22.944 3.2 28.8 9.056 28.8 16V18.288C28.8 19.552 27.664 20.8 26.4 20.8C25.136 20.8 24 19.552 24 18.288V16C24 11.584 20.416 8 16 8C11.584 8 8 11.584 8 16C8 20.416 11.584 24 16 24C18.208 24 20.224 23.104 21.664 21.648C22.704 23.072 24.496 24 26.4 24C29.552 24 32 21.44 32 18.288V16C32 7.168 24.832 0 16 0ZM16 20.8C13.344 20.8 11.2 18.656 11.2 16C11.2 13.344 13.344 11.2 16 11.2C18.656 11.2 20.8 13.344 20.8 16C20.8 18.656 18.656 20.8 16 20.8Z"
                        fill="url(#paint0_linear_533_168)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_533_168"
                          x1="0"
                          y1="0"
                          x2="35.3062"
                          y2="3.54759"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop offset="0.0511013" stopColor="#004FFF" />
                          <stop offset="1" stopColor="#2295FF" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </span>
                </div>
                <div className="address-info">
                  <h4>Email</h4>
                  <span>
                    <Link to="mailto:customer.care@padigital.id">
                      Customer.care@padigital.id
                    </Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="address-column rounded">
                <div className="icon-wrapper">
                  <span>
                    <Phone />
                  </span>
                </div>
                <div className="address-info">
                  <h4>Twitter/ X</h4>
                  <a href="https://twitter.com/PadigitalNFT" target="_blank" rel="noopener noreferrer">
                    <span>@PadigitalNFT</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddressBox;

export function Phone() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.2684 34.0027C25.7199 34.0053 26.1674 33.9179 26.5848 33.7457C27.0021 33.5735 27.381 33.3199 27.6993 32.9997L32.3062 28.3928C32.6228 28.0743 32.8005 27.6434 32.8005 27.1943C32.8005 26.7452 32.6228 26.3144 32.3062 25.9959L25.5064 19.196C25.1879 18.8794 24.757 18.7017 24.3079 18.7017C23.8588 18.7017 23.428 18.8794 23.1095 19.196L20.3895 21.899C18.5064 21.3969 16.7624 20.4725 15.2897 19.196C14.0166 17.721 13.0927 15.9778 12.5867 14.0962L15.2897 11.3763C15.6063 11.0577 15.784 10.6269 15.784 10.1778C15.784 9.72869 15.6063 9.29783 15.2897 8.97932L8.48984 2.1795C8.17134 1.86288 7.74048 1.68517 7.29138 1.68517C6.84227 1.68517 6.41142 1.86288 6.09291 2.1795L1.50303 6.80338C1.18281 7.12168 0.929202 7.50058 0.756999 7.91795C0.584795 8.33532 0.497449 8.78282 0.500057 9.23431C0.654282 15.766 3.26431 21.9998 7.80986 26.6928C12.503 31.2384 18.7367 33.8484 25.2684 34.0027ZM7.29988 5.80041L11.7028 10.2033L9.50982 12.3962C9.30212 12.5908 9.14658 12.8344 9.05748 13.1047C8.96838 13.3749 8.94855 13.6633 8.99983 13.9432C9.63522 16.7827 10.9805 19.4147 12.9097 21.593C15.0863 23.5247 17.7189 24.8703 20.5595 25.5029C20.8352 25.5605 21.1209 25.5487 21.3909 25.4686C21.6609 25.3885 21.9068 25.2426 22.1065 25.0439L24.2994 22.7999L28.7023 27.2028L25.3024 30.6027C19.6649 30.4576 14.2858 28.2077 10.2238 24.2959C6.30185 20.2322 4.0455 14.846 3.89997 9.20031L7.29988 5.80041ZM31.0992 15.3032H34.4991C34.5432 13.2819 34.1776 11.2727 33.4243 9.3965C32.6711 7.52034 31.5457 5.81612 30.1162 4.38654C28.6866 2.95697 26.9824 1.83163 25.1062 1.07836C23.2301 0.32509 21.2208 -0.0405213 19.1996 0.00356078V3.40347C20.777 3.3489 22.3487 3.61938 23.8171 4.19809C25.2856 4.77679 26.6192 5.65135 27.7353 6.76741C28.8514 7.88346 29.7259 9.21715 30.3046 10.6856C30.8833 12.154 31.1538 13.7258 31.0992 15.3032Z"
        fill="url(#paint0_linear_533_157)"
      />
      <path
        d="M19.1992 10.2046C22.7691 10.2046 24.2991 11.7346 24.2991 15.3045H27.699C27.699 9.83061 24.6731 6.80469 19.1992 6.80469V10.2046Z"
        fill="url(#paint1_linear_533_157)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_533_157"
          x1="0.5"
          y1="0"
          x2="38.0159"
          y2="3.76962"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0511013" stopColor="#004FFF" />
          <stop offset="1" stopColor="#2295FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_533_157"
          x1="19.1992"
          y1="6.80469"
          x2="28.5772"
          y2="7.74699"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0511013" stopColor="#004FFF" />
          <stop offset="1" stopColor="#2295FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function Mail() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 22C19.3137 22 22 19.3137 22 16C22 12.6863 19.3137 10 16 10C12.6863 10 10 12.6863 10 16C10 19.3137 12.6863 22 16 22Z"
        fill="url(#paint0_linear_533_176)"
      />
      <path
        d="M17.6 3.3104V0H14.4V3.3104C11.5845 3.66989 8.96795 4.95388 6.96091 6.96091C4.95388 8.96795 3.66989 11.5845 3.3104 14.4H0V17.6H3.3104C3.66936 20.4157 4.95318 23.0325 6.96031 25.0397C8.96745 27.0468 11.5843 28.3306 14.4 28.6896V32H17.6V28.6896C20.4158 28.3309 23.0328 27.0472 25.04 25.04C27.0472 23.0328 28.3309 20.4158 28.6896 17.6H32V14.4H28.6896C28.3306 11.5843 27.0468 8.96745 25.0397 6.96031C23.0325 4.95318 20.4157 3.66936 17.6 3.3104ZM16 25.6C10.7056 25.6 6.4 21.2944 6.4 16C6.4 10.7056 10.7056 6.4 16 6.4C21.2944 6.4 25.6 10.7056 25.6 16C25.6 21.2944 21.2944 25.6 16 25.6Z"
        fill="url(#paint1_linear_533_176)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_533_176"
          x1="10"
          y1="10"
          x2="23.2398"
          y2="11.3303"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0511013" stopColor="#004FFF" />
          <stop offset="1" stopColor="#2295FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_533_176"
          x1="0"
          y1="0"
          x2="35.3062"
          y2="3.54759"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0511013" stopColor="#004FFF" />
          <stop offset="1" stopColor="#2295FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
