import { collectors } from "../utils/data";
import React from "react";
import { Link } from "react-router-dom";

export default function ExploreByCollector() {
  return (
    <div className="nft-collections position-relative overflow-hidden pt-120 pb-60">
      <img
        src="assets/img/shapes/step-shape-left.png"
        alt="shape"
        className="step-shape-left position-absolute"
      />
      <div className="container">
        <div className="primary-title">
          <div className="title-heading">
            <h2>Explore by Collectors</h2>
          </div>
          <div className="title-desc">
            <p>
              Bonorum et MalorumPartner with one of the worlds largest retailers
              to showcase your brand and products.
            </p>
          </div>
        </div>
        <div className="collections-bottom">
          <div className="row justify-content-center">
            {collectors.map((item, i) => (
              <div className="col-xl-3 col-lg-4 col-sm-6" key={i + 1}>
                <div className="collection-column-wrapper">
                  <div className="collections-column">
                    <div className="figure-img">
                      <img
                        src={item.collectorImage}
                        className="img-fluid"
                        alt="videos"
                      />
                    </div>
                    <div className="ct-column-title">
                      <Link to="/explore-collections">
                        <h5 className="mb-0">{item.category}</h5>
                      </Link>
                      <span>
                        <i className="dot"></i>
                        {item.itemCount}
                      </span>
                    </div>
                    <div className="ct-column-desc">
                      <p>{item.desc}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
