import React from "react";
import Layout from "../components/layout/Layout";
import NftDNA1 from "../components/NftDNA1";
import NftNBA1 from "../components/NftNBA1";
const ProductNBA1 = () => {
  return (
    <Layout title="Product NBA 1">
      <NftNBA1 />
      
      
    </Layout>
  );
};

export default ProductNBA1;
