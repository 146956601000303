import React from "react";
import { Link } from "react-router-dom";

const NftDNA3 = () => {
  return (
    <section className="nft-product-details position-relative overflow-hidden ptb-120">
    <img
      src="assets/img/shapes/shape-blog-detail-left-bottom.svg"
      alt="shape"
      className="shape-top-left position-absolute"
    />
    <div className="container">
      <div className="product-details rounded">
        <div className="row">
          <div className="col-lg-6">
            <div className="pd-left">
              <div className="pd-slider">
                <div
                  id="controls"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        src="https://bafkreidzvtkuzefnatk2vuoebqyltkmpvwraorcc3cercfem4wcfnry6vm.ipfs.nftstorage.link/"
                        className="img-fluid"
                        alt="product thumbnail"
                        
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="https://bafkreia55k5oi7hwgt5qyawkykkwedznrrbtsdnxc7tf5khxhp3wmmh4ci.ipfs.nftstorage.link/"
                        className="img-fluid"
                        alt="product thumbnail"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="https://bafybeig4ekiflha7pzmdvhswyj7nlxyygq7r3vxqh6wdov6lq6ytwzhq3e.ipfs.nftstorage.link/"
                        className="img-fluid"
                        alt="product thumbnail"
                      />
                    </div>
                  </div>
                  <div className="carousel-indicators">
                    <button
                      type="button"
                      data-bs-target="#controls"
                      data-bs-slide-to="0"
                      className="active"
                      aria-current="true"
                      aria-label="Slide 1"
                    >
                      <img
                        src="https://bafkreidzvtkuzefnatk2vuoebqyltkmpvwraorcc3cercfem4wcfnry6vm.ipfs.nftstorage.link/"
                        className="img-fluid"
                        alt="product "
                      />
                    </button>
                    <button
                      type="button"
                      data-bs-target="#controls"
                      data-bs-slide-to="1"
                      aria-label="Slide 2"
                    >
                      <img
                        src="https://bafkreia55k5oi7hwgt5qyawkykkwedznrrbtsdnxc7tf5khxhp3wmmh4ci.ipfs.nftstorage.link/"
                        className="img-fluid"
                        alt="product "
                      />
                    </button>
                    <button
                      type="button"
                      data-bs-target="#controls"
                      data-bs-slide-to="2"
                      aria-label="Slide 3"
                    >
                      <img
                        src="https://bafybeig4ekiflha7pzmdvhswyj7nlxyygq7r3vxqh6wdov6lq6ytwzhq3e.ipfs.nftstorage.link/"
                        className="img-fluid"
                        alt="product "
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="pd-right">
              <div className="pd-title">
                <h2 className="h3"></h2>
              </div>
              <div className="pd-author-box">
                <div className="single-author d-flex align-items-center">
                  <div className="thumb-wrapper">
                    <img
                      src="https://bafybeicwmq7x7ixxxfeaeh7oxnrchjgkss4bvxsbugxwwtfuemkyol33ly.ipfs.nftstorage.link/"
                      alt="nft creator"
                      width="45"
                      className="img-fluid"
                    />
                  </div>
                  <div className="author-info">
                    <span>Aggregator</span>
                    <Link
                    to="https://app.ens.domains/ptdna.eth"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div >
                      <span>Dean Novel</span>
                      
                    </div>
                  </Link>
                  </div>
                </div>
                <div className="single-author d-flex align-items-center">
                  <div className="thumb-wrapper">
                    <img
                      src="https://bafkreiecrbwa66pvwxh4ik2wi6k7fzq54eqphhna74jg25e7noer5rpdzq.ipfs.nftstorage.link/"
                      alt="nft creator"
                      width="30"
                      
                      className="img-fluid"
                    />
                  </div>
                  <div className="author-info">
                    <span>Commodity</span>
                    <h6>Dry Corn Feed Grade </h6>
                  </div>
                </div>
              </div>
              <div className="pd-desc margin-top-30">
                <p>

                  The NFT for legal contract farming is a unique digital certificate representing a one-time planting agreement. 
                   Harvested crops are distributed based on ownership shares outlined in the NFT. 
                  This innovative model ensures transparency and fair sharing of yields among certificate holders in the contract farming process.
                </p>
              </div>
              <div className="row">
                
                <div className="col-md-6">
                  <Link
                    to="https://opensea.io/assets/ethereum/0x0510c92ca16b5ac771f7d3370f00fe3e17b6df06/5"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="bid-amount margin-top-30">
                      <span>Token Symbol</span>
                      <h3 className="text-white">JAPIDN22</h3>
                    </div>
                  </Link>
               
            
            
                <div className="bid-amount margin-top-30">
                  <span>Contract Limit</span>
                  <h3 className="text-white">USDT 39/ Unit</h3>
                </div>
             
            
              <div className="bid-amount margin-top-30">
                  <span>Planting Duration</span>
                  <h3 className="text-white">120 Days</h3>
                </div>
            
                <div className="bid-amount margin-top-30">
                  <span>Profit Share</span>
                  <h3 className="text-white">70% of Yield</h3>
                </div>
            
                <div className="bid-amount margin-top-30">
                <Link
                    to="https://www.google.com/maps/place/8%C2%B032'39.5%22S+116%C2%B037'30.6%22E/@-8.5444844,116.6208968,1637m/data=!3m1!1e3!4m4!3m3!8m2!3d-8.5443!4d116.62516?entry=ttu"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="bid-amount margin-top-30">
                      <span>Farm Coordinate</span>
                      <h3 className="text-white">East Lombok</h3>
                    </div>
                  </Link>
                </div>
            
              </div>
            
              <div className="col-md-6">
                <div className="bid-amount margin-top-30">
                  <span>Asset Under Management</span>
                  <h3 className="text-white">2.500.000 m2</h3>
                </div>
            
                <div className="bid-amount margin-top-30">
                <span>Farmers Under Management</span>
                  <h3 className="text-white">252 Farmers</h3>
                </div>
            
                <div className="bid-amount margin-top-30">
                  <span>Date of Planting</span>
                  <h3 className="text-white">12th of March,2024</h3>
                </div>
            
                <div className="bid-amount margin-top-30">
                  <span>Yield Projection</span>
                  <h3 className="text-white">6 Ton/ Hectare</h3>
                </div>
            
                <div className="bid-amount margin-top-30">
                  <span>Processing Factory</span>
                  <h3 className="text-white">Coordinate</h3>
                </div>
            
              </div>
            </div>


            <div className="row">
<div className="col-md-6">
  <div className="pd-btns margin-top-30">
    <div className="primary-btn">
      <Link to="https://opensea.io/assets/ethereum/0x0510c92ca16b5ac771f7d3370f00fe3e17b6df06/5" target="_blank" rel="noopener noreferrer"
      style={{ width: '200px', height: '60px' }}>
        Mint
      </Link>
    </div>
  </div>
</div>

<div className="col-md-6">
  <div className="pd-btns margin-top-30">
    <div className="primary-btn">
      <Link to="https://bafkreif7q26fz2efl7x3vkt4dryp76ksidoefqunim7shydjysxxp4ducm.ipfs.nftstorage.link/" target="_blank" rel="noopener noreferrer" 
       style={{ width: '200px', height: '60px' }}>
        Warehouse Receipt
      </Link>
    </div>
  </div>
</div>
</div>
              <div className="pd-tab">
                <ul className="nav nav-tabs">
                  <li>
                    <button
                      className="active"
                      data-bs-toggle="tab"
                      data-bs-target="#details"
                    >
                      Details
                    </button>
                  </li>
                  <li>
                    <button data-bs-toggle="tab" data-bs-target="#history">
                      Legal
                    </button>
                  </li>
                  <li>
                    <button data-bs-toggle="tab" data-bs-target="#activity">
                      Share & Profit
                    </button>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane active fade show" id="details">
                  <p> 1. Parties Involved: This contract involves farmers/landowners and NFT holders, facilitated by PT Padigital Graha Utama. </p>
                    <p>2. NFT Security: All NFTs are backed and secured by PT Padigital Graha Utama.</p>
                    <p>3. Land Allocation: Each NFT represents ownership of 250 square meters of land.</p>
                    <p>4. Supervision and Custody: Padigital will monitor and supervise all plantation activities on the designated land.</p>
                    <p>5. Allocation for Processing: 1 kilogram of harvested crops will be directed to dryer for corn processing.</p>
                    <p>6. Documentation and Public Disclosure: All processes will be recorded and publicly disclosed by Padigital.</p>
                    <p>7. Fungible Corn Tokens: Padigital will mint fungible corn tokens corresponding to warehouse receipts.</p>
                    <p>8. Conversion Rate: The standard conversion rate from corn to dry corn is 50-60%.</p>
                    <p>9. Issuance Based on Warehouse Receipt: Corn issuance from farm will align with the warehouse receipt.</p>
                    <p>10. Token Representation: Each token signifies 1 kilogram of corn.</p>
                    <p>11. Distribution to NFT Holders: Final corn product distribution will be proportional among NFT holders.</p>
                    <p>12. NFT Return for Token Receipt: To obtain tokens representing the end product, NFT holders must return the NFT.</p>
                    <p>13. Redemption Locations: Tokens can be redeemed in designated listed depositories.</p>
                    <p>14. Supplies and Support: Padigital provides seeds, fertilizers and technical assistance from PT DNA.</p>
                    <p>15. Token Trade Rights: Token holders have the right to sell or trade tokens in the market.</p>
                    <p>16. Export Limitation and Charges: Export of a minimum of 1000 tons of corn overseas is allowed, with additional charges.</p>
                    <p>17. Insurance Coverage: All risks are insured through ACA insurance.</p>
                    <p>18. Token Price Determination: NFT holders are free to determine token prices without external interference.</p>
                    
                  </div>
                 
                  

                  <div className="tab-pane fade" id="history">
                    
                    <p>1. Parties Involved: Farmer/Land owners and NFT holders are bound by this contract, appointing Padigital as custodian.</p>
                    <p>2. Oversight Responsibility: Padigital will oversee, report, and publicize matters on behalf of NFT holders to the public.</p>
                    <p>3. Notarization: This agreement obtains legal validation through notarization by (Insert Name).</p>
                    <p>4. Legal Compliance: Contract farming adheres strictly to Indonesian Law for legal compliance.</p>
                    <p>5. Dispute Resolution: Padigital's arbitration handles disputes; unresolved matters escalated to Bandung's court for resolution.</p>
                    <p>6. Insurance Coverage: ACA insurance provides comprehensive insurance covering all risks outlined within the contract.</p>
                    <p>7. Crop Risk Coverage: ACA insures against risks associated with specified crops.</p>
                    <p>8. Warehouse Risk Coverage: ACA provides insurance coverage for risks within the designated warehouse space.</p>
                  </div>

                  <div className="tab-pane fade" id="activity">
                  
                    <p>1. Asset Management Scope: This contract oversees 250,000,000 square meters of assets.</p>
                    <p>2. NFT Fractionalization: Totaling 10,000 NFTs, each represents 250 square meters of land.</p>
                    <p>3. Price Limit and Value: The upper limit for NFT pricing is set at 370,000 USDC.</p>
                    <p>4. Fractional NFT Value: Each fractionalized NFT is valued at 37 USDC.</p>
                    <p>5. Seed Allocation: Seeds are provided in packs of 30 kilograms each.</p>
                    <p>6. Projected Harvest Timeline: Estimated harvest period spans 3 months.</p>
                    <p>7. Projected Yield per Hectare: Anticipated harvest 6000 kilograms per hectare.</p>
                    <p>8. Projected Total Yield: Across 250 hectares, an estimated 1,500,000 kilograms are expected.</p>
                    <p>9. Profit Distribution: Goods' profit sharing includes 70% for NFT holders, 5% for Padigital, and 25% for Landowners.</p>
                    <p>10. Potential Gain per NFT: Each NFT may yield 105 kilograms for the holders.</p>
                   
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
};

export default NftDNA3;

