import React from "react";
import Layout from "../components/layout/Layout";
import NftNBA2 from "../components/NftNBA2";
const ProductNBA2 = () => {
  return (
    <Layout title="Product NBA 2">
      <NftNBA2 />
      
      
    </Layout>
  );
};

export default ProductNBA2;
