import React from "react";
import { Link } from "react-router-dom";

export default function RecentPosts() {
  return (
    <div className="blog-sidebar-widget post-widget">
      <div className="widget-title">
        <h4 className="h5">Recent Posts</h4>
      </div>
      <ul>
        <li>
          <div className="post-thumb">
            <Link to="/blog-details">
              <img
                src="assets/img/recent-post-1.png"
                className="img-fluid"
                alt="feature "
              />
            </Link>
          </div>
          <div className="post-content">
            <div className="title-heading">
              <Link to="/blog-details">
                <h6>10 tips for Avoiding Scams & Staying</h6>
              </Link>
            </div>
            <div className="post-date">
              <span>
                <i className="fa-solid fa-clock"></i>January 21, 2022
              </span>
            </div>
          </div>
        </li>
        <li>
          <div className="post-thumb">
            <Link to="/blog-details">
              <img
                src="assets/img/recent-post-2.png"
                className="img-fluid"
                alt="feature "
              />
            </Link>
          </div>
          <div className="post-content">
            <div className="title-heading">
              <Link to="/blog-details">
                <h6>The Beginners guide to creating & selling</h6>
              </Link>
            </div>
            <div className="post-date">
              <span>
                <i className="fa-solid fa-clock"></i>February 21, 2022
              </span>
            </div>
          </div>
        </li>
        <li>
          <div className="post-thumb">
            <Link to="/blog-details">
              <img
                src="assets/img/recent-post-3.png"
                className="img-fluid"
                alt="feature "
              />
            </Link>
          </div>
          <div className="post-content">
            <div className="title-heading">
              <Link to="/blog-details">
                <h6>10 tips for avoiding scams and staying safe.</h6>
              </Link>
            </div>
            <div className="post-date">
              <span>
                <i className="fa-solid fa-clock"></i>January 26, 2022
              </span>
            </div>
          </div>
        </li>
        <li>
          <div className="post-thumb">
            <Link to="/blog-details">
              <img
                src="assets/img/recent-post-4.png"
                className="img-fluid"
                alt="feature "
              />
            </Link>
          </div>
          <div className="post-content">
            <div className="title-heading">
              <Link to="/blog-details">
                <h6>NFTs Are Digital Real Estate Be Worth</h6>
              </Link>
            </div>
            <div className="post-date">
              <span>
                <i className="fa-solid fa-clock"></i>January 21, 2022
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}
