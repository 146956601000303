import React from "react";
import NftNBA1 from "../components/NftNBA1";
import Layout from "../components/layout/Layout";
const ProductDetails = () => {
  return (
    <Layout title="Product Details">
      <NftNBA1 />
      
      
    </Layout>
  );
};

export default ProductDetails;
