import React from "react";
import { Link } from "react-router-dom";

export default function RelatedPost() {
  return (
    <div className="related-post margin-top-60">
      <div className="row">
        <div className="col-md-6">
          <div className="prev-post">
            <div className="post-thumb">
              <Link to="/blog-details">
                <img
                  src="assets/img/post-slide-1.jpg"
                  className="img-fluid"
                  alt="post thumbnail"
                />
              </Link>
            </div>
            <div className="post-right">
              <Link to="/blog-details">
                <i className="fa-solid fa-angles-left"></i>Previous post
              </Link>
              <Link to="/blog-details">
                <h6>When the musics artover turn off the light</h6>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="next-post">
            <div className="post-left">
              <Link to="/blog-details">
                Next post<i className="fa-solid fa-angles-right"></i>
              </Link>
              <Link to="/blog-details">
                <h6>When the musics artover turn off the light</h6>
              </Link>
            </div>
            <div className="post-thumb">
              <Link to="/blog-details">
                <img
                  src="assets/img/post-slide-2.jpg"
                  className="img-fluid"
                  alt="post thumbnail"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
