import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { simillarCollection } from "../../utils/data";
import { Link } from "react-router-dom";

export default function SimilarCollection() {
  const swiperOptions = {
    slidesPerView: 4,
    speed: 700,
    spaceBetween: 30,
    slidesPerGroup: 2,
    pagination: {
      clickable: true,
      dynamicBullets: true,
    },
    loop: true,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      991: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  };
  return (
    <section className="nft1-similar-collection position-relative overflow-hidden pb-120">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-6">
            <div className="title-headline text-center text-md-start">
              <h3 className="mb-0">More similar collection</h3>
            </div>
          </div>
          <div className="col-md-6">
            <div className="primary-btn text-center text-md-end mt-3 mt-md-0">
              <Link to="/explore-collections">View Collection</Link>
            </div>
          </div>
        </div>
        <div className="row">
          <Swiper
            className="similar-collection-slider position-relative"
            {...swiperOptions}
            modules={[Pagination]}
          >
            {simillarCollection.map((item, i) => (
              <SwiperSlide key={i + 1}>
                <div className="single-nft-item">
                  <div className="nft-item-wrapper rounded p-3">
                    <Link
                      to={item.nftDetails}
                      className="item-image position-relative"
                    >
                      <img
                        src={item.nftImage}
                        alt="nft item"
                        className="img-fluid rounded-top"
                      />
                    </Link>
                    <div className="buy-now-btn primary-btn">
                      <Link to={item.nftDetails}>buy now</Link>
                    </div>
                    <div className="nft-item-content-wrap pt-4">
                      <Link to={item.nftDetails}>
                        <h2 className="h6">{item.nftName}</h2>
                      </Link>
                      <hr />
                      <div className="nft-item-footer d-flex align-items-center justify-content-between">
                        <div className="item-price-wrapper d-flex align-items-center">
                          <span>
                            <i className="fab fa-ethereum"></i>
                          </span>
                          <div className="item-price ms-3">
                            <p className="mb-0">{item.nftPriceTitle}</p>
                            <h5 className="mb-0">{item.nftPrice}</h5>
                          </div>
                        </div>
                        <span className="item-like">
                          <i className="fa-solid fa-heart"></i> {item.nftLikes}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <img
        src="assets/img/shapes/shape-blog-detail-right-bottom.svg"
        alt="shape"
        className="shape-right position-absolute"
      />
    </section>
  );
}
