import React from "react";
import BreadCrumb from "../components/common/BreadCrumb";
import ExploreNftCollection from "../components/ExploreNftCollection";
import Layout from "../components/layout/Layout";
import SidebarSearch from "../components/sidebar/SidebarSearch";

const ExploreCollection = () => {
  return (
    <Layout title="Explore Collection">
      <BreadCrumb
        pageTitle="Explore Collection"
        parentPage="Home"
        prevPage="Pages"
        currentPage="Explore Collection"
      />

      <section className="nft1-explore-collection ptb-120">
        <div className="container">
          <div className="row g-3">
            <div className="col-lg-3 order-2 order-lg-1">
              <SidebarSearch />
            </div>
            <div className="col-lg-9 order-1 order-lg-2">
              <ExploreNftCollection />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ExploreCollection;
