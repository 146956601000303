import React from "react";

export default function BlogSearch() {
  return (
    <div className="blog-sidebar-widget search-widget">
      <div className="widget-title">
        <h4 className="h5">Search here</h4>
      </div>
      <div className="search-form">
        <form action="#">
          <input type="text" placeholder="Search" />
          <button type="submit">
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </form>
      </div>
    </div>
  );
}
