import React from "react";

import AddressBox from "../components/contact/AddressBox";

import Layout from "../components/layout/Layout";

const Contact = () => {
  return (
    <Layout title="Contact">
      
      <AddressBox />
   
    </Layout>
  );
};

export default Contact;
