import React from "react";
import BreadCrumb from "../components/common/BreadCrumb";
import Layout from "../components/layout/Layout";
import NftCreatingSteps from "../components/nft-create-step/NftCreatingSteps";

const HowItWorks = () => {
  return (
    <Layout title="How It Works">
      <BreadCrumb
        pageTitle="How It Works"
        parentPage="Home"
        prevPage="Pages"
        currentPage="How It Works"
      />
      <NftCreatingSteps />
    </Layout>
  );
};

export default HowItWorks;
