import React from "react";
import LiveAuctionAll from "../components/auction/LiveAuctionAll";
import BreadCrumb from "../components/common/BreadCrumb";
import Layout from "../components/layout/Layout";

const LiveAuction = () => {
  return (
    <Layout title="Live Auction">
      <BreadCrumb
        pageTitle="Live Auction"
        parentPage="Home"
        prevPage="Pages"
        currentPage="Live Auction"
      />
      <LiveAuctionAll />
    </Layout>
  );
};

export default LiveAuction;
