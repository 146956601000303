import React, { useState } from "react";
import PostThumbSlider from "./PostThumbSlider";
import ModalVideo from "react-modal-video";

export default function BlogPostDetails() {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="blog-content-part">
      <div className="blog-item-details">
        <div className="thumb-wrapper">
          <img
            src="assets/img/blog-list-1.jpg"
            className="img-fluid"
            alt="feature"
          />
        </div>
        <div className="blog-pera-txt margin-top-40">
          <p>
            We are upgrading the smart contract we use to help address the
            inactive listings issue on Ethereum. The upgrade ensures that old,
            inactive listings expire, enables bulk cancellation with a single,
            low cost transaction, and allows us to roll out new features like
            bulk cancellation and more descriptive signatures. There are a few
            actions required of anyone with active listings
          </p>
          <p>
            As of today, Friday, February 18 at 3pm ET, you can start migrating
            your listings to the new Wyvern smart contract. You will have 1 week
            to move all your existing listings to the new contract. At 2pm ET on
            Friday, February 25, any listings you have not migrated will expire.
            If you miss the window to migrate your listings to the new contract,
            you will be able to re-list your expired listings without incurring
            any additional fees (including gas fees). Any new listings made
            during this 7-day migration period will automatically be set to the
            new contract (so no additional effort is required for new listings).
          </p>
        </div>
        <blockquote>
          <p>
            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
            fugit, sed quia consequuntur magni dolores eos qui ratione
            voluptatem sequi nesci hoichioi saradin aranonde sultanab ibiana
            unt.
          </p>
          <span>Robert K.Maraquez</span>
        </blockquote>

        <div className="blog-title-txt margin-top-40">
          <h2 className="h4">What is the metaverse? </h2>
        </div>
        <div className="blog-pera-txt margin-top-20">
          <p>
            Think about the Metaverse as a digital universe embedded on top of
            the real world. The Metaverse will become the global settlement and
            coordination layer of the world. All powered by the internet. We
            need many things to fall into place to make the Metaverse a reality.
            For instance, higher data speeds. Think 6g and even beyond that.
            Second, better VR hardware. The moment all these things happen, we
            will have a ‘Metaverse’ revolution. People from anywhere in the
            world will transcend their physical limitations of geography.
          </p>
        </div>
        <div className="blog-feature margin-top-40">
          <div className="row g-4">
            <div className="col-md-6">
              <PostThumbSlider />
            </div>
            <div className="col-md-6">
              <div className="blog-video-content">
                <div className="blog-thumb">
                  <img
                    src="assets/img/blog-video.jpg"
                    className="img-fluid"
                    alt="feature"
                  />
                </div>
                {typeof window !== "undefined" && (
                  <ModalVideo
                    channel="youtube"
                    autoplay
                    isOpen={isOpen}
                    videoId="6WZOxnYi4Cs"
                    onClose={() => setOpen(false)}
                  />
                )}
                <a
                  href="#!"
                  className="blog-video-popup"
                  onClick={() => setOpen(true)}
                >
                  <i className="fa-solid fa-play"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-title-txt margin-top-40">
          <h2 className="h4">
            NFT Market - A compact trike with the big benefits
          </h2>
        </div>
        <div className="blog-pera-txt margin-top-20">
          <p>
            Were upgrading the smart contract we use to help address the
            inactive listings issue Ethereum. The upgrade ensures that old,
            inactive listings expire, enables bulk cancellation with a single,
            low cost transaction, and allows us to roll out new features like
            bulk cancellation and more descriptive signatures. There are a few
            actions required of anyone with active listings
          </p>
          <p>
            Different they have the same total value are fungible, therefore
            non-fungible obviously means that it is good which cannot be
            replaced and that has unique attributes
          </p>
        </div>
        <div className="content-spacer"></div>
      </div>
      <div className="blog-content-bottom">
        <div className="row">
          <div className="col-sm-6">
            <div className="content-tags">
              <ul>
                <li>
                  <strong>Tags:</strong>
                </li>
                <li>
                  <a href="#!">blockchain</a>
                </li>
                <li>
                  <a href="#!">metaverse</a>
                </li>
                <li>
                  <a href="#!">NFT</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="content-social-share">
              <ul>
                <li>
                  <strong>Share:</strong>
                </li>
                <li>
                  <a href="#!" className="facebook">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="#!" className="twitter">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#!" className="instagram">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
