import React from "react";
import { Link } from "react-router-dom";

const TrendingCollection = () => {
  return (
    <section className="nft2-collections">
      <div className="container">
        <div className="row g-3">
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/explore-collections" className="d-block">
                <img
                  src="assets/img/home2/cover-1.jpg"
                  className="img-fluid rounded-top position-relative"
                  alt="cover"
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/mega-thumb-2.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Domain names</h6>
                  <span>
                    <i className="icon"></i>55 items
                  </span>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/explore-collections" className="d-block">
                <img
                  src="assets/img/home2/cover-2.jpg"
                  className="img-fluid rounded-top position-relative"
                  alt="cover"
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/mega-thumb-3.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Trading Cards</h6>
                  <span>
                    <i className="icon"></i>25 items
                  </span>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/explore-collections" className="d-block">
                <img
                  src="assets/img/home2/cover-3.jpg"
                  className="img-fluid rounded-top position-relative"
                  alt="cover "
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/mega-thumb-4.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Collectibles</h6>
                  <span>
                    <i className="icon"></i>15 items
                  </span>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/explore-collections" className="d-block">
                <img
                  src="assets/img/home2/cover-3.jpg"
                  className="img-fluid rounded-top position-relative"
                  alt="cover"
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/mega-thumb-5.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Photography</h6>
                  <span>
                    <i className="icon"></i>120 items
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrendingCollection;
