import React from "react";
import { Link } from "react-router-dom";

const CreateItemForm = () => {
  return (
    <section className="nft1-create-form pt-120 pb-120 position-relative overflow-hidden">
      <img
        src="assets/img/shapes/hero-3-shape-left.svg"
        alt="shape"
        className="shape-top-left position-absolute"
      />
      <img
        src="assets/img/shapes/hero-top-right.svg"
        alt="shape"
        className="shape-right-top position-absolute"
      />
      <img
        src="assets/img/shapes/hero-bottom-promo-bg.svg"
        alt="shape"
        className="shape-bottom position-absolute"
      />
      <div className="container">
        <div className="nft1-create-form-wrapper">
          <form>
            <div className="row">
              <div className="col-lg-12">
                <div className="file-input">
                  <input type="file" className="nft1-file-collect" />
                  <span className="nft1-file-location">Choose a File</span>
                  <button type="button" className="nft1-file-browse">
                    Browse
                  </button>
                  <span className="file-formate">
                    PNG, JPG, GIF, WEBP or MP4, Max 150MB
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-9">
                <div className="form-left">
                  <div className="input-field">
                    <label for="pr-title">Project Title</label>
                    <input
                      type="text"
                      placeholder="Enter Price for an item (ETH)"
                      id="pr-title"
                    />
                  </div>
                  <div className="input-field">
                    <label for="d-message">Description</label>
                    <textarea
                      rows="6"
                      placeholder="Description Here"
                    ></textarea>
                  </div>
                  <div className="input-field">
                    <label>Select Method</label>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="radio-field">
                          <input
                            type="radio"
                            name="method"
                            value="fixed-price"
                            checked="checked"
                          />
                          <span>
                            <i className="fa-solid fa-money-check-dollar"></i>
                            Fixed Price
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="radio-field">
                          <input
                            type="radio"
                            name="method"
                            value="timed-auction"
                          />
                          <span>
                            <i className="fa-solid fa-clock"></i>timed auction
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="radio-field">
                          <input
                            type="radio"
                            name="method"
                            value="Open-for-bids"
                          />
                          <span>
                            <i className="fa-solid fa-user"></i>open for bids
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="select-input">
                        <label>Price</label>
                        <select>
                          <option>15</option>
                          <option>16</option>
                          <option>17</option>
                          <option>18</option>
                          <option>19</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input-field">
                        <label for="size">Size</label>
                        <input type="text" id="size" value="157mb" />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="select-input">
                        <label for="category">Category</label>
                        <select>
                          <option>Sports</option>
                          <option>Entertainment</option>
                          <option>Arts</option>
                          <option>Beauty</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="input-field">
                        <label for="royal">Royalties</label>
                        <input
                          type="text"
                          id="royal"
                          placeholder="Suggested 0% 30% 50%"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-btns">
                        <div className="view-btn">
                          <a href="#">view item</a>
                        </div>
                        <div className="submit-btn">
                          <button type="submit">Submit item</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-8">
                <div className="auction-column ms-0 me-0">
                  <div className="fc-wrapper">
                    <div className="thumb-wrapper">
                      <Link to="/product-details">
                        <img
                          src="assets/img/auction-2.jpg"
                          className="img-fluid"
                          alt="feature image"
                        />
                      </Link>
                    </div>
                    <div className="fc-title">
                      <Link to="/product-details">
                        <h6>Supperplastic crypto</h6>
                      </Link>
                    </div>
                    <div className="fc-spacer"></div>
                    <div className="fc-bottom">
                      <div className="fc-bt-left">
                        <div className="icon-wrapper">
                          <span>
                            <i className="fa-brands fa-ethereum"></i>
                          </span>
                        </div>
                        <div className="fc-reserve">
                          <span className="rs-subtitle">Reserve price</span>
                          <span className="rs-title">0.0478 ETH</span>
                        </div>
                      </div>
                      <div className="fc-right">
                        <span>
                          <i className="fa-solid fa-heart"></i>12
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default CreateItemForm;
