import React from "react";
import AuthorAbout from "../../components/blogs/AuthorAbout";
import BlogCategory from "../../components/blogs/BlogCategory";
import BlogPostDetails from "../../components/blogs/BlogPostDetails";
import BlogSearch from "../../components/blogs/BlogSearch";
import CommentForm from "../../components/blogs/CommentForm";
import Comments from "../../components/blogs/Comments";
import RecentPosts from "../../components/blogs/RecentPosts";
import RelatedPost from "../../components/blogs/RelatedPost";
import Tags from "../../components/blogs/Tags";
import BreadCrumb from "../../components/common/BreadCrumb";
import Layout from "../../components/layout/Layout";

const BlogDetails = () => {
  return (
    <Layout title="Blog Details">
      <BreadCrumb
        pageTitle="Blog Details"
        parentPage="Home"
        prevPage="Pages"
        currentPage="Blog Details"
      />
      <div className="nft-blog-area pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <BlogPostDetails />
              <RelatedPost />
              <div className="content-spacer"></div>
              <Comments />
              <CommentForm />
            </div>
            <div className="col-lg-4">
              <div className="nft-sidebar-area">
                <BlogSearch />
                <AuthorAbout />
                <RecentPosts />
                <BlogCategory />
                <Tags />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BlogDetails;
