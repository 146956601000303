import React from "react";
import BlogPost from "../../components/blogs/BlogPost";
import BreadCrumb from "../../components/common/BreadCrumb";
import Layout from "../../components/layout/Layout";

const BlogGrid = () => {
  return (
    <Layout>
      <BreadCrumb
        pageTitle="Blog Grid"
        parentPage="Home"
        prevPage="Page"
        currentPage="Blog Grid"
      />
      <BlogPost />
    </Layout>
  );
};

export default BlogGrid;
