import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css/navigation";

export default function PostThumbSlider() {
  const swiperOptions = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    loop: true,
    navigation: {
      nextEl: ".swiper-nav-control .swiper-button-next",
      prevEl: ".swiper-nav-control .swiper-button-prev",
    },
  };

  return (
    <div className="position-relative">
      <Swiper
        className="blog-feature-slider"
        modules={[Navigation]}
        {...swiperOptions}
      >
        <SwiperSlide>
          {" "}
          <div className="blog-featured-img">
            <img
              src="assets/img/blog-feature-1.jpg"
              className="img-fluid"
              alt="feature "
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="blog-featured-img">
            <img
              src="assets/img/blog-feature-1.jpg"
              className="img-fluid"
              alt="feature "
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="blog-featured-img">
            <img
              src="assets/img/blog-feature-1.jpg"
              className="img-fluid"
              alt="feature "
            />
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="swiper-nav-control inner-post-control">
        <span className="swiper-button-next">
          <i className="fa-solid fa-arrow-left"></i>
        </span>
        <span className="swiper-button-prev">
          <i className="fa-solid fa-arrow-right"></i>
        </span>
      </div>
    </div>
  );
}
