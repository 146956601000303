import React from "react";
import { Link } from "react-router-dom";

const CreatorByWeek = () => {
  return (
    <section className="nft2-top-creators ptb-120 position-relative overflow-hidden">
      <img
        src="assets/img/shapes/hero-top-right.svg"
        alt=" not found"
        className="position-absolute shape-right-top"
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="title-style-2 text-center">
              <h2>
                Top Creator this Week{" "}
                <span className="title-shape ms-2">
                  <img
                    src="assets/img/home2/star-shape-2.png"
                    alt=" not found"
                  />
                </span>
              </h2>
              <div className="title-desc">
                <p>
                  {" "}
                  We Have it all to provide the best experience to buy your
                  first Bitcoin Rewarding Lowest Fees and Unlimited Rewards.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="nft2-creator-list">
          <div className="row g-4 justify-content-center">
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="nft2-creator-column box-shadow-light">
                <div className="cover-img">
                  <img
                    src="assets/img/home2/creator-cover-1.jpg"
                    className="w-100"
                    alt="cover "
                  />
                </div>
                <div className="profile-wrapper">
                  <img src="assets/img/home2/tp-1.jpg" alt="profile" />
                </div>
                <div className="item-bottom">
                  <Link to="/creator-profile">
                    <h6>Esther Howard</h6>
                  </Link>
                  <span>15.3k Artwork</span>
                </div>
                <div className="follow-btn">
                  <a href="#!">Following</a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="nft2-creator-column box-shadow-light">
                <div className="cover-img">
                  <img
                    src="assets/img/home2/creator-cover-2.jpg"
                    className="w-100"
                    alt="cover "
                  />
                </div>
                <div className="profile-wrapper">
                  <img src="assets/img/home2/tp-2.jpg" alt="profile" />
                </div>
                <div className="item-bottom">
                  <Link to="/creator-profile">
                    <h6>Leslie Alexander</h6>
                  </Link>
                  <span>15.3k Artwork</span>
                </div>
                <div className="follow-btn">
                  <a href="#!">Following</a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="nft2-creator-column box-shadow-light">
                <div className="cover-img">
                  <img
                    src="assets/img/home2/creator-cover-3.jpg"
                    className="w-100"
                    alt="cover "
                  />
                </div>
                <div className="profile-wrapper">
                  <img src="assets/img/home2/tp-3.jpg" alt="profile" />
                </div>
                <div className="item-bottom">
                  <Link to="/creator-profile">
                    <h6>Jenny wilson</h6>
                  </Link>
                  <span>15.3k Artwork</span>
                </div>
                <div className="follow-btn">
                  <a href="#!">Following</a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="nft2-creator-column box-shadow-light">
                <div className="cover-img">
                  <img
                    src="assets/img/home2/creator-cover-4.jpg"
                    className="w-100"
                    alt="cover "
                  />
                </div>
                <div className="profile-wrapper">
                  <img src="assets/img/home2/tp-4.jpg" alt="profile" />
                </div>
                <div className="item-bottom">
                  <Link to="/creator-profile">
                    <h6>Courtney henry</h6>
                  </Link>
                  <span>15.3k Artwork</span>
                </div>
                <div className="follow-btn">
                  <a href="#!">Following</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nft2-explore-creator text-center">
          <span className="arrow-shape">
            <img src="assets/img/arrow-shape.png" alt="arrow shape" />
          </span>
          <ul className="explore-ct">
            <li>
              <Link to="/creator-profile">
                <img src="assets/img/home2/artist-1.jpg" alt="artist" />
              </Link>
              <span>Esther</span>
            </li>
            <li>
              <Link to="/creator-profile">
                <img src="assets/img/home2/artist-2.jpg" alt="artist" />
              </Link>
              <span>Leslie</span>
            </li>
            <li>
              <Link to="/creator-profile">
                <img src="assets/img/home2/artist-3.jpg" alt="artist" />
              </Link>
              <span>henry</span>
            </li>
            <li>
              <Link to="/creator-profile">
                <img src="assets/img/home2/artist-4.jpg" alt="artist" />
              </Link>
              <span>willson</span>
            </li>
          </ul>
          <div className="explore-ct-btn">
            <Link to="/creators">Explore creators</Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreatorByWeek;
