import VideoModal from "../VidoeModal";
import React from "react";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";

export default function VideoBlog() {
  return (
    <section className="nft2-video-blog overflow-hidden position-relative ptb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="title-top">
              <h2>Explore videos & audio NFTs</h2>
            </div>
          </div>
        </div>
        <div className="video-blog-wrapper">
          <div className="row g-4">
            <div className="col-xl-4 col-md-6">
              <div className="nft2-vblog-column">
                <div className="thumb-wrapper">
                  <Link to="/blog-details">
                    <img
                      src="assets/img/home2/vblog-1.jpg"
                      className="img-fluid"
                      alt="feature"
                    />
                  </Link>
                </div>
                <div className="wish-btn">
                  <button>
                    <i className="fa-solid fa-heart"></i>
                  </button>
                </div>
                <div className="blog-content">
                  <div className="blog-content-wrapper">
                    <VideoModal large />
                    <div className="blog-title">
                      <Link to="/blog-details">
                        <h6>Digital NFT Video #120</h6>
                      </Link>
                    </div>
                    <div className="blog-authors">
                      <div className="authors">
                        <div className="nft2-explore-creator">
                          <ul className="explore-ct">
                            <li>
                              <Link to="/creator-profile">
                                <img
                                  src="assets/img/home2/v-author-1.jpg"
                                  alt="author"
                                />
                              </Link>
                              <span>Esther</span>
                            </li>
                            <li>
                              <Link to="/creator-profile">
                                <img
                                  src="assets/img/home2/v-author-2.jpg"
                                  alt="author"
                                />
                              </Link>
                              <span>Leslie</span>
                            </li>
                            <li>
                              <Link to="/creator-profile">
                                <img
                                  src="assets/img/home2/v-author-3.jpg"
                                  alt="author"
                                />
                              </Link>
                              <span>henry</span>
                            </li>
                            <li>
                              <Link to="/creator-profile">
                                <img
                                  src="assets/img/home2/v-author-4.jpg"
                                  alt="author"
                                />
                              </Link>
                              <span>willson</span>
                            </li>
                          </ul>
                          <div className="numbers">
                            <span>3 of 99</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="blog-bottom">
                      <div className="row">
                        <div className="col-5">
                          <div className="bid-amount">
                            <span>current bid</span>
                            <h6>4.56 ETH</h6>
                          </div>
                        </div>
                        <div className="col-7">
                          <div className="countdown-timing">
                            <span>Remaining Time</span>
                            <Countdown
                              date={Date.now() + 5000000}
                              renderer={counter}
                              autoStart={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="nft2-vblog-column">
                <div className="thumb-wrapper">
                  <Link to="/blog-single">
                    <img
                      src="assets/img/home2/vblog2.jpg"
                      className="img-fluid"
                      alt="feature"
                    />
                  </Link>
                </div>
                <div className="wish-btn">
                  <button>
                    <i className="fa-solid fa-heart"></i>
                  </button>
                </div>
                <div className="blog-content">
                  <div className="blog-content-wrapper">
                    <div className="video-play nft1-popup-video">
                      <VideoModal />
                    </div>
                    <div className="blog-title">
                      <Link to="/blog-details">
                        <h6>Digital NFT Video #120</h6>
                      </Link>
                    </div>
                    <div className="blog-authors">
                      <div className="authors">
                        <div className="nft2-explore-creator">
                          <ul className="explore-ct">
                            <li>
                              <Link to="/creator-profile">
                                <img
                                  src="assets/img/home2/v-author-1.jpg"
                                  alt="author"
                                />
                              </Link>
                              <span>Esther</span>
                            </li>
                            <li>
                              <Link to="/creator-profile">
                                <img
                                  src="assets/img/home2/v-author-2.jpg"
                                  alt="author"
                                />
                              </Link>
                              <span>Leslie</span>
                            </li>
                            <li>
                              <Link to="/creator-profile">
                                <img
                                  src="assets/img/home2/v-author-3.jpg"
                                  alt="author"
                                />
                              </Link>
                              <span>henry</span>
                            </li>
                            <li>
                              <Link to="/creator-profile">
                                <img
                                  src="assets/img/home2/v-author-4.jpg"
                                  alt="author"
                                />
                              </Link>
                              <span>willson</span>
                            </li>
                          </ul>
                          <div className="numbers">
                            <span>3 of 99</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="blog-bottom">
                      <div className="row">
                        <div className="col-5">
                          <div className="bid-amount">
                            <span>current bid</span>
                            <h6>4.56 ETH</h6>
                          </div>
                        </div>
                        <div className="col-7">
                          <div className="countdown-timing">
                            <span>Remaining Time</span>
                            <Countdown
                              date={Date.now() + 5000000}
                              renderer={counter}
                              autoStart={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="row g-4 nft2-audion-blog">
                <div className="col-xl-12 col-md-6">
                  <div className="audio-blog-item">
                    <div className="thumb-wrapper">
                      <Link to="/blog-details">
                        <img
                          src="assets/img/home2/vblog-3.jpg"
                          className="img-fluid"
                          alt="feature"
                        />
                      </Link>
                    </div>
                    <div className="ad-blog-content">
                      <div className="content-left">
                        <div className="blog-title">
                          <Link to="/blog-details">
                            <h6>Digital NFT Music #01</h6>
                          </Link>
                        </div>
                        <div className="content-bottom">
                          <div className="bid-amount">
                            <span>Current Bid</span>
                            <h6>0.56 ETH</h6>
                          </div>
                          <div className="explore-authors">
                            <div className="nft2-explore-creator">
                              <ul className="explore-ct">
                                <li>
                                  <Link to="/creator-profile">
                                    <img
                                      src="assets/img/home2/v-author-1.jpg"
                                      alt="author"
                                    />
                                  </Link>
                                  <span>Esther</span>
                                </li>
                                <li>
                                  <Link to="/creator-profile">
                                    <img
                                      src="assets/img/home2/v-author-2.jpg"
                                      alt="author"
                                    />
                                  </Link>
                                  <span>Leslie</span>
                                </li>
                                <li>
                                  <Link to="/creator-profile">
                                    <img
                                      src="assets/img/home2/v-author-3.jpg"
                                      alt="author"
                                    />
                                  </Link>
                                  <span>henry</span>
                                </li>
                                <li>
                                  <Link to="/creator-profile">
                                    <img
                                      src="assets/img/home2/v-author-4.jpg"
                                      alt="author"
                                    />
                                  </Link>
                                  <span>willson</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <VideoModal />
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 col-md-6">
                  <div className="audio-blog-item">
                    <div className="thumb-wrapper">
                      <Link to="/blog-details">
                        <img
                          src="assets/img/home2/vblog-4.jpg"
                          className="img-fluid"
                          alt="feature image"
                        />
                      </Link>
                    </div>
                    <div className="ad-blog-content">
                      <div className="content-left">
                        <div className="blog-title">
                          <Link to="/blog-details">
                            <h6>NFTCally Music #01</h6>
                          </Link>
                        </div>
                        <div className="content-bottom">
                          <div className="bid-amount">
                            <span>Current Bid</span>
                            <h6>0.56 ETH</h6>
                          </div>
                          <div className="explore-authors">
                            <div className="nft2-explore-creator">
                              <ul className="explore-ct">
                                <li>
                                  <Link to="/creator-profile">
                                    <img
                                      src="assets/img/home2/v-author-1.jpg"
                                      alt="author"
                                    />
                                  </Link>
                                  <span>Esther</span>
                                </li>
                                <li>
                                  <Link to="/creator-profile">
                                    <img
                                      src="assets/img/home2/v-author-2.jpg"
                                      alt="author"
                                    />
                                  </Link>
                                  <span>Leslie</span>
                                </li>
                                <li>
                                  <Link to="/creator-profile">
                                    <img
                                      src="assets/img/home2/v-author-3.jpg"
                                      alt="author"
                                    />
                                  </Link>
                                  <span>henry</span>
                                </li>
                                <li>
                                  <Link to="/creator-profile">
                                    <img
                                      src="assets/img/home2/v-author-4.jpg"
                                      alt="author"
                                    />
                                  </Link>
                                  <span>willson</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <VideoModal />
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 col-md-6">
                  <div className="audio-blog-item">
                    <div className="thumb-wrapper">
                      <Link to="/blog-details">
                        <img
                          src="assets/img/home2/vblog-5.jpg"
                          className="img-fluid"
                          alt="feature"
                        />
                      </Link>
                    </div>
                    <div className="ad-blog-content">
                      <div className="content-left">
                        <div className="blog-title">
                          <Link to="/blog-details">
                            <h6>Song A Day #01</h6>
                          </Link>
                        </div>
                        <div className="content-bottom">
                          <div className="bid-amount">
                            <span>Current Bid</span>
                            <h6>0.56 ETH</h6>
                          </div>
                          <div className="explore-authors">
                            <div className="nft2-explore-creator">
                              <ul className="explore-ct">
                                <li>
                                  <Link to="/creator-profile">
                                    <img
                                      src="assets/img/home2/v-author-1.jpg"
                                      alt="author"
                                    />
                                  </Link>
                                  <span>Esther</span>
                                </li>
                                <li>
                                  <Link to="/creator-profile">
                                    <img
                                      src="assets/img/home2/v-author-2.jpg"
                                      alt="author"
                                    />
                                  </Link>
                                  <span>Leslie</span>
                                </li>
                                <li>
                                  <Link to="/creator-profile">
                                    <img
                                      src="assets/img/home2/v-author-3.jpg"
                                      alt="author"
                                    />
                                  </Link>
                                  <span>henry</span>
                                </li>
                                <li>
                                  <Link to="/creator-profile">
                                    <img
                                      src="assets/img/home2/v-author-4.jpg"
                                      alt="author"
                                    />
                                  </Link>
                                  <span>willson</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <VideoModal />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const counter = ({ hours, minutes, seconds }) => {
  return (
    <ul className="downcount-timer">
      <li>
        <span className="hours">{hours}</span>
        <span>h</span>
      </li>
      <li className="seperator px-1">:</li>
      <li className="pe-1">
        <span className="minutes">{minutes}</span>
        <span>m</span>
      </li>
      <li className="seperator px-1">:</li>
      <li className="pe-1">
        <span className="seconds">{seconds}</span>
        <span>s</span>
      </li>
    </ul>
  );
};
