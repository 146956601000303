const navPagesData = [
  {
    title: "Collections",
    path: "/collections",
  },
  {
    title: "Exlplore Collections",
    path: "/explore-collections",
  },
  {
    title: "Wallets",
    path: "/wallet",
  },
  {
    title: "Create Item",
    path: "/create-item",
  },
  {
    title: "How It Works",
    path: "/how-it-works",
  },
  {
    title: "Creators",
    path: "/creators",
  },
  {
    title: "Creator Profile",
    path: "/creator-profile",
  },
  {
    title: "Item Details",
    path: "/product-details",
  },
  {
    title: "Auction Item Details",
    path: "/product-auction-details",
  },
  {
    title: "Live Auction",
    path: "/live-auction",
  },
  {
    title: "FAQ",
    path: "/faq",
  },
  {
    title: "Contact Us",
    path: "/contact-us",
  },
  {
    title: "404 Page",
    path: "/notfound",
  },

  {
    title: "Product DNA 2",
    path: "/ProductDNA2",
  },

  {
    title: "Product NBA 1",
    path: "/ProductNBA1",
  },


  {
    title: "Product NBA 2",
    path: "/ProductNBA2",
  },


];

const allNFts = [
  {
    nftImage: "assets/img/home3/nft-art-1.jpg",
    nftName: "Biker Nyan art",
    nftPrice: " 0.045 ETH",
    nftLikes: "1.4k like",
    nftDetails: "/product-details",
    classOption: "",
  },
  {
    nftImage: "assets/img/home3/nft-art-2.jpg",
    nftName: "The Creative Dragont",
    nftPrice: "0.45 ETH",
    nftLikes: "5k like",
    nftDetails: "/product-details",
    classOption: "",
  },
  {
    nftImage: "assets/img/home3/nft-art-3.jpg",
    nftName: "Cool Cops Club",
    nftPrice: "0.95 ETH",
    nftLikes: "1.5k like",
    nftDetails: "/product-details",
    classOption: "",
  },
  {
    nftImage: "assets/img/home3/nft-art-4.jpg",
    nftName: "Nice Hair Style",
    nftPrice: "0.225 ETH",
    nftLikes: "1.5k like",
    nftDetails: "/product-details",
    classOption: "",
  },
  {
    nftImage: "assets/img/home3/nft-art-5.jpg",
    nftName: "Nice Hair Color",
    nftPrice: " 0.225 ETH",
    nftLikes: "1.5k like",
    nftDetails: "/product-details",
    classOption: "mb-lg-0",
  },
  {
    nftImage: "assets/img/home3/nft-art-6.jpg",
    nftName: "Gost Hand Eye",
    nftPrice: "0.564 ETH",
    nftLikes: "4.5k like",
    nftDetails: "/product-details",
    classOption: "mb-lg-0",
  },
  {
    nftImage: "assets/img/home3/nft-art-7.jpg",
    nftName: "Hair Color Gost Eye",
    nftPrice: "0.564 ETH",
    nftLikes: "4.5k like",
    nftDetails: "/product-details",
    classOption: "mb-lg-0",
  },
  {
    nftImage: "assets/img/home3/nft-art-8.jpg",
    nftName: "Robotic Ape Social",
    nftPrice: "3.58 ETH",
    nftLikes: "1.5k like",
    nftDetails: "/product-details",
    classOption: "mb-lg-0",
  },
];

const digitalArt = [
  {
    nftImage: "assets/img/home3/nft-art-1.jpg",
    nftName: "Biker Nyan art",
    nftPrice: " 0.045 ETH",
    nftLikes: "1.4k like",
    nftDetails: "/product-details",
    classOption: "mb-lg-0",
  },
  {
    nftImage: "assets/img/home3/nft-art-5.jpg",
    nftName: "Nice Hair Color",
    nftPrice: " 0.225 ETH",
    nftLikes: "1.5k like",
    nftDetails: "/product-details",
    classOption: "mb-lg-0",
  },
];
const musics = [
  {
    nftImage: "assets/img/home3/nft-art-2.jpg",
    nftName: "The Creative Dragont",
    nftPrice: "0.45 ETH",
    nftLikes: "5k like",
    nftDetails: "/product-details",
    classOption: "mb-lg-0",
  },
  {
    nftImage: "assets/img/home3/nft-art-6.jpg",
    nftName: "Gost Hand Eye",
    nftPrice: "0.564 ETH",
    nftLikes: "4.5k like",
    nftDetails: "/product-details",
    classOption: "mb-lg-0",
  },
];
const photography = [
  {
    nftImage: "assets/img/home3/nft-art-3.jpg",
    nftName: "Cool Cops Club",
    nftPrice: "0.95 ETH",
    nftLikes: "1.5k like",
    nftDetails: "/product-details",
    classOption: "mb-lg-0",
  },
  {
    nftImage: "assets/img/home3/nft-art-6.jpg",
    nftName: "Gost Hand Eye",
    nftPrice: "0.564 ETH",
    nftLikes: "4.5k like",
    nftDetails: "/product-details",
    classOption: "mb-lg-0",
  },
];
const sports = [
  {
    nftImage: "assets/img/home3/nft-art-8.jpg",
    nftName: "Robotic Ape Social",
    nftPrice: "3.58 ETH",
    nftLikes: "1.5k like",
    nftDetails: "/product-details",
    classOption: "mb-lg-0",
  },
  {
    nftImage: "assets/img/home3/nft-art-8.jpg",
    nftName: "Robotic Ape Social",
    nftPrice: "3.58 ETH",
    nftLikes: "1.5k like",
    nftDetails: "/product-details",
    classOption: "mb-lg-0",
  },
];

const topCollectionData = [
  {
    collectionImage: "assets/img/home3/tc-1.jpg",
    collectionName: "Oasis Doodles",
    itemCount: "12 items",
  },
  {
    collectionImage: "assets/img/home3/tc-2.png",
    collectionName: "Genesis Aliens",
    itemCount: "52 items",
  },
  {
    collectionImage: "assets/img/home3/tc-3.png",
    collectionName: "Azuki",
    itemCount: "10 items",
  },
  {
    collectionImage: "assets/img/home3/tc-4.png",
    collectionName: "Beanz official",
    itemCount: "25 items",
  },
  {
    collectionImage: "assets/img/home3/tc-5.png",
    collectionName: "Shinsei Galverse",
    itemCount: "15 items",
  },
  {
    collectionImage: "assets/img/home3/tc-6.png",
    collectionName: "Hikari official",
    itemCount: "35 items",
  },
  {
    collectionImage: "assets/img/home3/tc-7.png",
    collectionName: "Arcade Land",
    itemCount: "45 items",
  },
  {
    collectionImage: "assets/img/home3/tc-8.png",
    collectionName: "Crypto Punks",
    itemCount: "335 items",
  },
  {
    collectionImage: "assets/img/home3/tc-9.png",
    collectionName: "Mutant Ape club",
    itemCount: "15 items",
  },
  {
    collectionImage: "assets/img/home3/tc-10.png",
    collectionName: "Genesis aliens",
    itemCount: "655 items",
  },
  {
    collectionImage: "assets/img/home3/tc-11.png",
    collectionName: "Azuki aliens",
    itemCount: "320",
  },
  {
    collectionImage: "assets/img/home3/tc-12.png",
    collectionName: "Hereai aliens",
    itemCount: "65 items",
  },
];
//Top Creator
const topCreator = [
  {
    creatorImage: "assets/img/creator-1.jpg",
    creatorName: "Omegato Soemarso",
    userName: "@2",
    nftItem: "250 items",
    itemView: "2.5K Views",
    Position: "President Director",
  },
  {
    creatorImage: "assets/img/creator-2.jpg",
    creatorName: "Wim Tangkilisan",
    userName: "@Wim",
    nftItem: "250 items",
    itemView: "2.5K Views",
    Position: "Chairman",
  },
  {
    creatorImage: "assets/img/creator-3.jpg",
    creatorName: "Surya Kurniadi",
    userName: "@1",
    nftItem: "250 items",
    itemView: "2.5K Views",
    Position: "Chief Technology Officer",
  },
  {
    creatorImage: "assets/img/creator-4.jpg",
    creatorName: "Sara Quek",
    userName: "@SaraQyn",
    nftItem: "250 items",
    itemView: "2.5K Views",
    Position: "Chief Business Development",
  },
  {
    creatorImage: "assets/img/creator-5.jpg",
    creatorName: "Marcos Chow",
    userName: "@MarcosC",
    nftItem: "250 items",
    itemView: "2.5K Views",
    Position: "Commissioner",
  },
  {
    creatorImage: "assets/img/creator-6.jpg",
    creatorName: "Edi Prabowo",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
    Position: "Commissioner",
  },
  {
    creatorImage: "assets/img/creator-7.jpg",
    creatorName: "Udhoro Kasih Anggoro",
    userName: "@U.D.A",
    nftItem: "250 items",
    itemView: "2.5K Views",
    Position: "Chief Communications Officer",
  },
  {
    creatorImage: "assets/img/creator-8.jpg",
    creatorName: "Ali Zum Mashar",
    userName: "@A.Z.M",
    nftItem: "250 items",
    itemView: "2.5K Views",
    Position: "Production Director",
  },
  {
    creatorImage: "assets/img/creator-9.jpg",
    creatorName: "Ahmed Sluder",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-10.jpg",
    creatorName: "Daryl Patterson",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-11.jpg",
    creatorName: "Charles gabaldon",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-13.jpg",
    creatorName: "Glen womble",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-12.jpg",
    creatorName: "Dallas sewell",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-13.jpg",
    creatorName: "Brian Moberg",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-15.jpg",
    creatorName: "Jonathan gatling",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-11.jpg",
    creatorName: "Jonathan gatling",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
];
//Best Seller
const bestSeller = [
  {
    creatorImage: "assets/img/creator-1.jpg",
    creatorName: "Charles sullivan",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-8.jpg",
    creatorName: "Ronald bixby",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-9.jpg",
    creatorName: "Ahmed Sluder",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-10.jpg",
    creatorName: "Daryl Patterson",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-11.jpg",
    creatorName: "Charles gabaldon",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-13.jpg",
    creatorName: "Glen womble",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-12.jpg",
    creatorName: "Dallas sewell",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-13.jpg",
    creatorName: "Brian Moberg",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-13.jpg",
    creatorName: "Glen womble",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-12.jpg",
    creatorName: "Dallas sewell",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-13.jpg",
    creatorName: "Brian Moberg",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-15.jpg",
    creatorName: "Jonathan gatling",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
];

const recentAdded = [
  {
    creatorImage: "assets/img/creator-1.jpg",
    creatorName: "Omegato Soemarso",
    userName: "@A2",
    Position: "President Director",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-2.jpg",
    creatorName: "Debit alex",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-3.jpg",
    creatorName: "Bernard carter",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-4.jpg",
    creatorName: "joy parker",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-5.jpg",
    creatorName: "Dorek Harrison",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-6.jpg",
    creatorName: "Jeffrey Scheer",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-7.jpg",
    creatorName: "John Prather",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-8.jpg",
    creatorName: "Ronald bixby",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-9.jpg",
    creatorName: "Ahmed Sluder",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-10.jpg",
    creatorName: "Daryl Patterson",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-11.jpg",
    creatorName: "Charles gabaldon",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
  {
    creatorImage: "assets/img/creator-13.jpg",
    creatorName: "Glen womble",
    userName: "@debitalex",
    nftItem: "250 items",
    itemView: "2.5K Views",
  },
];
const simillarCollection = [
  {
    nftDetails: "/product-details",
    nftImage: "assets/img/filter-1.jpg",
    nftName: "Lee complete #100",
    nftPriceTitle: "Reserve price",
    nftPrice: "0.0478 ETH",
    nftLikes: "15k Like",
  },
  {
    nftDetails: "/product-details",
    nftImage: "assets/img/filter-11.jpg",
    nftName: "Pudgy Penguins",
    nftPriceTitle: "Reserve price",
    nftPrice: "0.0478 ETH",
    nftLikes: "1.5k Like",
  },
  {
    nftDetails: "/product-details",
    nftImage: "assets/img/filter-10.jpg",
    nftName: "Metroverse Genesis",
    nftPriceTitle: "Reserve price",
    nftPrice: "0.0478 ETH",
    nftLikes: "12k Like",
  },
  {
    nftDetails: "/product-details",
    nftImage: "assets/img/filter-9.jpg",
    nftName: "Shadowy Super Coder",
    nftPriceTitle: "Reserve price",
    nftPrice: "0.0478 ETH",
    nftLikes: "9.5k Like",
  },
  {
    nftDetails: "/product-details",
    nftImage: "assets/img/filter-8.jpg",
    nftName: "NeoTokyo Citizens",
    nftPriceTitle: "Reserve price",
    nftPrice: "0.0278 ETH",
    nftLikes: "9k Like",
  },
];
const footerMenuOne = [
  {
    menuTitle: "All NFTs",
    menuPath: "/explore-collections",
  },
  {
    menuTitle: "Digital Art",
    menuPath: "/explore-collections",
  },
  {
    menuTitle: "Popular Art",
    menuPath: "/explore-collections",
  },
  {
    menuTitle: "Trending NFT",
    menuPath: "/explore-collections",
  },
  {
    menuTitle: "Explore Details",
    menuPath: "/product-details",
  },
  {
    menuTitle: "Top Buyers",
    menuPath: "/creators",
  },
];
const footerMenuTwo = [
  {
    menuTitle: "Author",
    menuPath: "/creators",
  },
  {
    menuTitle: "How It Works",
    menuPath: "/how-it-works",
  },
  {
    menuTitle: "Create an NFT with us",
    menuPath: "/create-item",
  },
  {
    menuTitle: "Author Profile",
    menuPath: "/creator-profile",
  },
  {
    menuTitle: "Connect Wallet",
    menuPath: "/wallet",
  },
  {
    menuTitle: "NFT Collections",
    menuPath: "/explore-collections",
  },
];
const footerMenuThree = [
  {
    menuTitle: "Helps & Support",
    menuPath: "/contact-us",
  },
  {
    menuTitle: "Live auctions",
    menuPath: "/live-auction",
  },
  {
    menuTitle: "Crator Profile",
    menuPath: "/creator-profile",
  },
  {
    menuTitle: "Item Details",
    menuPath: "/product-details",
  },
  {
    menuTitle: "Activity",
    menuPath: "/activity",
  },
  {
    menuTitle: "Terms & Condtion",
    menuPath: "/faq",
  },
];

const liveAuctionData = [
  {
    nftImage: "assets/img/auction-1.jpg",
    nftDetails: "/product-details",
    nftName: "Supperplastic crypto",
    nftPriceTitle: "Reserve price",
    nftPrice: "0.0478 ETH",
    nftLikes: "12",
  },
  {
    nftImage: "assets/img/auction-2.jpg",
    nftDetails: "/product-details",
    nftName: "The Creative Dragont",
    nftPriceTitle: "Reserve price",
    nftPrice: "0.0478 ETH",
    nftLikes: "12",
  },
  {
    nftImage: "assets/img/auction-3.jpg",
    nftDetails: "/product-details",
    nftName: "Cool Cops Club",
    nftPriceTitle: "Reserve price",
    nftPrice: "0.0478 ETH",
    nftLikes: "14",
  },
  {
    nftImage: "assets/img/auction-4.jpg",
    nftDetails: "/product-details",
    nftName: "Supperplastic crypto",
    nftPriceTitle: "Reserve price",
    nftPrice: "0.0478 ETH",
    nftLikes: "20",
  },
  {
    nftImage: "assets/img/auction-5.jpg",
    nftDetails: "/product-details",
    nftName: "Gost Hand Eye",
    nftPriceTitle: "Reserve price",
    nftPrice: "0.0478 ETH",
    nftLikes: "15",
  },
  {
    nftImage: "assets/img/auction-6.jpg",
    nftDetails: "/product-details",
    nftName: "Cool Cops Club",
    nftPriceTitle: "Reserve price",
    nftPrice: "0.0478 ETH",
    nftLikes: "16",
  },
  {
    nftImage: "assets/img/auction-7.jpg",
    nftDetails: "/product-details",
    nftName: "Robotic Ape Social",
    nftPriceTitle: "Reserve price",
    nftPrice: "0.0478 ETH",
    nftLikes: "20",
  },
  {
    nftImage: "assets/img/auction-8.jpg",
    nftDetails: "/product-details",
    nftName: "The Creative Dragont",
    nftPriceTitle: "Reserve price",
    nftPrice: "0.0478 ETH",
    nftLikes: "27",
  },
  {
    nftImage: "assets/img/auction-9.jpg",
    nftDetails: "/product-details",
    nftName: "Cool Cops Club",
    nftPriceTitle: "Reserve price",
    nftPrice: "0.0478 ETH",
    nftLikes: "10",
  },
  {
    nftImage: "assets/img/auction-10.jpg",
    nftDetails: "/product-details",
    nftName: "Supperplastic crypto",
    nftPriceTitle: "Reserve price",
    nftPrice: "0.0478 ETH",
    nftLikes: "23",
  },
  {
    nftImage: "assets/img/auction-11.jpg",
    nftDetails: "/product-details",
    nftName: "Supperplastic crypto",
    nftPriceTitle: "Reserve price",
    nftPrice: "0.0478 ETH",
    nftLikes: "8",
  },
  {
    nftImage: "assets/img/auction-12.jpg",
    nftDetails: "/product-details",
    nftName: "Robotic Ape Social",
    nftPriceTitle: "Reserve price",
    nftPrice: "0.0478 ETH",
    nftLikes: "9",
  },
];

const collectors = [
  {
    collectorImage: "assets/img/mega-thumb-1.jpg",
    category: "Video",
    itemCount: "140 items",
    desc: "The Crypto Monkey is a high Monkeys on the NFT marketplace.",
  },
  {
    collectorImage: "assets/img/mega-thumb-8.png",
    category: "Games",
    itemCount: "140 items",
    desc: "The Crypto Monkey is a high Monkeys on the NFT marketplace.",
  },
  {
    collectorImage: "assets/img/mega-thumb-3.png",
    category: "Photography",
    itemCount: "140 items",
    desc: "The Crypto Monkey is a high Monkeys on the NFT marketplace.",
  },
  {
    collectorImage: "assets/img/mega-thumb-2.png",
    category: "Digital Art",
    itemCount: "140 items",
    desc: "The Crypto Monkey is a high Monkeys on the NFT marketplace.",
  },
  {
    collectorImage: "assets/img/mega-thumb-5.png",
    category: "Trading Cards",
    itemCount: "140 items",
    desc: "The Crypto Monkey is a high Monkeys on the NFT marketplace.",
  },
  {
    collectorImage: "assets/img/mega-thumb-6.png",
    category: "Sports",
    itemCount: "140 items",
    desc: "The Crypto Monkey is a high Monkeys on the NFT marketplace.",
  },
  {
    collectorImage: "assets/img/mega-thumb-8.png",
    category: "Music",
    itemCount: "140 items",
    desc: "The Crypto Monkey is a high Monkeys on the NFT marketplace.",
  },
  {
    collectorImage: "assets/img/mega-thumb-4.png",
    category: "Videos",
    itemCount: "140 itesm",
    desc: "The Crypto Monkey is a high Monkeys on the NFT marketplace.",
  },
];

const navDemo = [
  {
    menuTitle: "Home Page",
    path: "/",
  },

];

const blogData = [
  {
    thumb: "assets/img/blog-thumb-1.jpg",
    title: "What is the Difference custodial NFT Marketplace?",

    exerpt:
      "A non-custodial NFT marketplace is arguably a great way & Manage your NFTs.",
    details: "/blog-deatails",
    authorImg: "assets/img/blog-author-3.jpg",
    auhtorName: "Victor b.Dimouro",
    tagOne: "NFT Token",
    tagTwo: "Metavatars",
    date: "08 April 2022",
  },
  {
    thumb: "assets/img/blog-thumb-2.jpg",
    title: "Top 5 cryptocurrencies to invest in April 2022",
    exerpt:
      "A non-custodial NFT marketplace is arguably a great way & Manage your NFTs.",
    details: "/blog-details",
    authorImg: "assets/img/blog-author-2.jpg",
    auhtorName: "Victor b.Dimouro",
    tagOne: "Airdrpos",
    tagTwo: "NFT Token",
    date: "08 April 2022",
  },
  {
    thumb: "assets/img/blog-thumb-3.jpg",
    title: "What’s an NFT Airdrop and How Does it Work?",
    exerpt:
      "A non-custodial NFT marketplace is arguably a great way & Manage your NFTs.",
    details: "/blog-details",
    authorImg: "assets/img/blog-author-3.jpg",
    auhtorName: "William W. Stone",
    tagOne: "Metavatars",
    tagTwo: "NFT",
    date: "08 April 2022",
  },
  {
    thumb: "assets/img/blog-thumb-4.jpg",
    title: "Difference Between NFT and Cryptocurrency?",
    exerpt:
      "A non-custodial NFT marketplace is arguably a great way & Manage your NFTs.",
    authorImg: "assets/img/blog-author-3.jpg",
    details: "/blog-details",
    auhtorName: "   Margaret D. Wright   ",
    tagOne: "Metavatars",
    tagTwo: "NFT Token",
    date: "08 April 2022",
  },
  {
    thumb: "assets/img/blog-thumb-5.jpg",
    title: "What the hell is NFT? How does it work?",
    exerpt:
      "A non-custodial NFT marketplace is arguably a great way & Manage your NFTs.",
    details: "/blog-details",
    authorImg: "assets/img/blog-author-2.jpg",
    auhtorName: "Alexander M. Bechtel",
    tagOne: "Cryptocurrency",
    tagTwo: "NFTs",
    date: "08 April 2022",
  },
  {
    thumb: "assets/img/blog-thumb-6.jpg",
    title: "What Is Metaverse Stock ? How To Buy And Sell Stock",
    exerpt:
      "A non-custodial NFT marketplace is arguably a great way & Manage your NFTs.",
    details: "/blog-details",
    authorImg: "assets/img/blog-author-1.jpg",
    auhtorName: "Betty C. Guajardo",
    tagOne: "Airdrpos",
    tagTwo: " NFTically",
    date: "08 April 2022",
  },
];

const blogDataTwo = [
  {
    thumb: "assets/img/blog-thumb-1.jpg",
    title: "What is the Difference custodial NFT Marketplace?",
    exerpt:
      "A non-custodial NFT marketplace is arguably a great way & Manage your NFTs.",
    details: "/blog-deatails",
    authorImg: "assets/img/blog-author-3.jpg",
    auhtorName: "Victor b.Dimouro",
    tagOne: "NFT Token",
    tagTwo: "Metavatars",
    date: "08 April 2022",
  },
  {
    thumb: "assets/img/blog-thumb-2.jpg",
    title: "Top 5 cryptocurrencies to invest in April 2022",
    exerpt:
      "A non-custodial NFT marketplace is arguably a great way & Manage your NFTs.",
    details: "/blog-details",
    authorImg: "assets/img/blog-author-2.jpg",
    auhtorName: "Victor b.Dimouro",
    tagOne: "Airdrpos",
    tagTwo: "NFT Token",
    date: "08 April 2022",
  },
  {
    thumb: "assets/img/blog-thumb-3.jpg",
    title: "What’s an NFT Airdrop and How Does it Work?",
    exerpt:
      "A non-custodial NFT marketplace is arguably a great way & Manage your NFTs.",
    details: "/blog-details",
    authorImg: "assets/img/blog-author-3.jpg",
    auhtorName: "William W. Stone",
    tagOne: "Metavatars",
    tagTwo: "NFT",
    date: "08 April 2022",
  },
];
const exploreCollections = [
  {
    nftImage: "assets/img/filter-1.jpg",
    nftDetails: "/product-details",
    nftName: "Lee complete #100",
    nftPrice: "0.0478",
    nftLikes: "15k Likes",
  },
  {
    nftImage: "assets/img/filter-2.jpg",
    nftDetails: "/product-details",
    nftName: "PharaGods-NFT",
    nftPrice: "0.0521",
    nftLikes: "5k Likes",
  },
  {
    nftImage: "assets/img/filter-3.jpg",
    nftDetails: "/product-details",
    nftName: "Project Arowana Prime",
    nftPrice: "0.0421",
    nftLikes: "10k Likes",
  },
  {
    nftImage: "assets/img/filter-4.jpg",
    nftDetails: "/product-details",
    nftName: "GoldSilver Pirates",
    nftPrice: "0.0032",
    nftLikes: "15k Likes",
  },
  {
    nftImage: "assets/img/filter-5.jpg",
    nftDetails: "/product-details",
    nftName: "Anonymous Genesis",
    nftPrice: "0.00538",
    nftLikes: "11k Likes",
  },
  {
    nftImage: "assets/img/filter-6.jpg",
    nftDetails: "/product-details",
    nftName: "Anonymous Genesis",
    nftPrice: "0.00538",
    nftLikes: "11k Likes",
  },
  {
    nftImage: "assets/img/filter-7.jpg",
    nftDetails: "/product-details",
    nftName: "Invisible Friends",
    nftPrice: "0.0421",
    nftLikes: "15k Likes",
  },
  {
    nftImage: "assets/img/filter-8.jpg",
    nftDetails: "/product-details",
    nftName: "NeoTokyo Citizens",
    nftPrice: "0.0278",
    nftLikes: "9k Likes",
  },

  {
    nftImage: "assets/img/filter-9.jpg",
    nftDetails: "/product-details",
    nftName: "Shadowy Super Coder",
    nftPrice: "0.0478",
    nftLikes: "9.5k Likes",
  },
  {
    nftImage: "assets/img/filter-10.jpg",
    nftDetails: "/product-details",
    nftName: "Metroverse Genesis",
    nftPrice: "0.0678",
    nftLikes: "12k Likes",
  },
  {
    nftImage: "assets/img/filter-11.jpg",
    nftDetails: "/product-details",
    nftName: "Pudgy Penguins",
    nftPrice: "0.0678",
    nftLikes: "12k Likes",
  },
  {
    nftImage: "assets/img/filter-12.jpg",
    nftDetails: "/product-details",
    nftName: "Creepz Genesis",
    nftPrice: "0.0878",
    nftLikes: "1.2k Likes",
  },
];
const exploreCollectionTwo = [
  {
    nftImage: "assets/img/filter-1.jpg",
    nftDetails: "/product-details",
    nftName: "Lee complete #100",
    nftPrice: "0.0478",
    nftLikes: "15k Likes",
  },
  {
    nftImage: "assets/img/filter-2.jpg",
    nftDetails: "/product-details",
    nftName: "PharaGods-NFT",
    nftPrice: "0.0521",
    nftLikes: "5k Likes",
  },
  {
    nftImage: "assets/img/filter-3.jpg",
    nftDetails: "/product-details",
    nftName: "Project Arowana Prime",
    nftPrice: "0.0421",
    nftLikes: "10k Likes",
  },
  {
    nftImage: "assets/img/filter-4.jpg",
    nftDetails: "/product-details",
    nftName: "GoldSilver Pirates",
    nftPrice: "0.0032",
    nftLikes: "15k Likes",
  },
  {
    nftImage: "assets/img/filter-5.jpg",
    nftDetails: "/product-details",
    nftName: "Anonymous Genesis",
    nftPrice: "0.00538",
    nftLikes: "11k Likes",
  },
  {
    nftImage: "assets/img/filter-6.jpg",
    nftDetails: "/product-details",
    nftName: "Anonymous Genesis",
    nftPrice: "0.00538",
    nftLikes: "11k Likes",
  },
  {
    nftImage: "assets/img/filter-7.jpg",
    nftDetails: "/product-details",
    nftName: "Invisible Friends",
    nftPrice: "0.0421",
    nftLikes: "15k Likes",
  },
  {
    nftImage: "assets/img/filter-8.jpg",
    nftDetails: "/product-details",
    nftName: "NeoTokyo Citizens",
    nftPrice: "0.0278",
    nftLikes: "9k Likes",
  },
];

const ProductDetail =[

{   ContractFarming: "DNA 001",
    Aggregator :  "PT DNA",
    ProfitShare: "70%",
    CropType: "Corn Food Grade",
    TokenSymbol:  "JAPIDN11",
}







]
export {
  blogDataTwo,
  exploreCollectionTwo,
  exploreCollections,
  blogData,
  navDemo,
  collectors,
  liveAuctionData,
  footerMenuThree,
  footerMenuTwo,
  footerMenuOne,
  simillarCollection,
  navPagesData,
  allNFts,
  digitalArt,
  sports,
  musics,
  photography,
  topCollectionData,
  recentAdded,
  topCreator,
  bestSeller,
  ProductDetail,
};
