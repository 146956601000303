import React from "react";
import HeroThreeSlider from "./HeroThreeSlider";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { Link } from "react-router-dom";

export default function HeroThree() {
  return (
    <section className="hero-section pt-150 pb-120 position-relative overflow-hidden content-bg">
      <img
        src="assets/img/shapes/hero-3-shape-left.svg"
        alt="shape"
        className="shape-top-left position-absolute"
      />
      <img
        src="assets/img/shapes/dark-mode-gradient.svg"
        alt="shape"
        className="position-absolute center-shape"
      />
      <img
        src="assets/img/shapes/hero-bottom-promo-bg.svg"
        alt="shape"
        className="shape-bottom position-absolute"
      />
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-6">
            <div className="hero-3-content-wrap">
              <h1>
                Discover for create buy & Sell art <mark>NFTs.</mark>
              </h1>
              <p>
                The standard chunk of used since the 1500s is reproduced
                interested finibus bonorum malorum benchmark resource-leveling
                materials
              </p>
              <div className="hero-btns">
                <div className="primary-btn">
                  <Link to="/explore-collections">Explore More</Link>
                </div>
                <div className="hero-secondary-btn">
                  <Link to="/create-item">Create NFT</Link>
                </div>
              </div>
              <div className="hero-counters">
                <div className="row">
                  <div className="col-4">
                    <div className="counter-column">
                      <div className="ct-title">
                        <h3 className="mb-0">
                          <span className="counter">
                            <CountUp
                              redraw={true}
                              end={37}
                              duration="3"
                              suffix="k+"
                            >
                              {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start} delayedCall>
                                  <span ref={countUpRef} />
                                </VisibilitySensor>
                              )}
                            </CountUp>
                          </span>
                        </h3>
                      </div>
                      <div className="ct-subtitle">
                        <span>Artworks</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="counter-column">
                      <div className="ct-title">
                        <h3 className="mb-0">
                          <span className="counter">
                            <CountUp
                              redraw={true}
                              end={20}
                              duration="3"
                              suffix="k+"
                            >
                              {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start} delayedCall>
                                  <span ref={countUpRef} />
                                </VisibilitySensor>
                              )}
                            </CountUp>
                          </span>
                        </h3>
                      </div>
                      <div className="ct-subtitle">
                        <span>Artists</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="counter-column">
                      <div className="ct-title">
                        <h3 className="mb-0">
                          <span className="counter">
                            <CountUp
                              redraw={true}
                              end={90}
                              duration="3"
                              suffix="k+"
                            >
                              {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start} delayedCall>
                                  <span ref={countUpRef} />
                                </VisibilitySensor>
                              )}
                            </CountUp>
                          </span>
                        </h3>
                      </div>
                      <div className="ct-subtitle">
                        <span>Auctions</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <HeroThreeSlider />
          </div>
        </div>
      </div>
      <img
        src="assets/img/shapes/hero-3-shape-right.svg"
        alt="shape"
        className="shape-right-top position-absolute"
      />
    </section>
  );
}
