import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { Link } from "react-router-dom";

export default function HeroThreeSlider() {
  const swiperOptions = {
    slidesPerView: 1,
    speed: 700,
    spaceBetween: 30,
    slidesPerGroup: 1,
    loop: true,
    pagination: {
      clickable: true,
      dynamicBullets: true,
    },
  };
  return (
    <div className="hero-slider-wrapper position-relative">
      <Swiper {...swiperOptions} className="hero-slider" modules={[Pagination]}>
        <SwiperSlide>
          <div className="slide-single">
            <div className="slide-wrapper">
              <div className="thumb-wrapper">
                <Link to="/product-details">
                  <img
                    src="assets/img/hero-auction-1.jpg"
                    className="w-100"
                    alt="feature product"
                  />
                </Link>
              </div>
              <div className="slide-content">
                <div className="content-left">
                  <Link to="/product-details">
                    <h2 className="h5">Opera Face #100</h2>
                  </Link>

                  <span>0.0478 ETH</span>
                </div>
                <div className="right-btn">
                  <Link to="/product-details">Buy now</Link>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide-single">
            <div className="slide-wrapper">
              <div className="thumb-wrapper">
                <Link to="/product-details">
                  <img
                    src="assets/img/hero-auction-2.jpg"
                    className="w-100"
                    alt="feature product"
                  />
                </Link>
              </div>
              <div className="slide-content">
                <div className="content-left">
                  <Link to="/product-details">
                    <h2 className="h5">InvisibleDoodle Club #100</h2>
                  </Link>
                  <span>0.0478 ETH</span>
                </div>
                <div className="right-btn">
                  <Link to="/product-details">Buy now</Link>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide-single">
            <div className="slide-wrapper">
              <div className="thumb-wrapper">
                <Link to="/product-details">
                  <img
                    src="assets/img/hero-auction-3.jpg"
                    className="w-100"
                    alt="feature product"
                  />
                </Link>
              </div>
              <div className="slide-content">
                <div className="content-left">
                  <Link to="/product-details">
                    <h2 className="h5">Bearded Ape Teen Club #100</h2>
                  </Link>
                  <span>0.0478 ETH</span>
                </div>
                <div className="right-btn">
                  <Link to="/product-details">Buy now</Link>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
