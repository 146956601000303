import React from "react";
import ActivityTable from "../components/ActivityTable";
import BreadCrumb from "../components/common/BreadCrumb";
import Layout from "../components/layout/Layout";

const Activity = () => {
  return (
    <Layout title="Activity">
      <BreadCrumb
        pageTitle="Top Activity"
        parentPage="Home"
        prevPage="Pages"
        currentPage="Activity"
      />
      <ActivityTable />
    </Layout>
  );
};

export default Activity;
