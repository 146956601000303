import React from "react";
import { Link } from "react-router-dom";

const HeroTwo = () => {
  return (
    <section className="hero-section-two position-relative overflow-hidden pt-80">
      <img
        src="assets/img/shapes/hero-two-top-right.svg"
        alt="hero shape"
        className="position-absolute shape-right-top"
      />
      <div className="container-fluid custom-container position-relative overflow-hidden">
        <img
          src="assets/img/shapes/hero-two-shape.svg"
          alt="hero shape"
          className="position-absolute shape-top-left"
        />
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6">
              <div className="hero-content-wrap">
                <h1 className="display-4 fw-bold">
                  NFT Marketplace Explore, Collect or Sell
                </h1>
                <p>
                  NFT artists are cracking the male-dominated decentralized
                  space to voice major changes in the virtual world.
                  Distinctively foster B2B networks whereas state of the art
                  opportunities continually facilitate vertical mindshare
                </p>
                <div className="hero-btns">
                  <div className="primary-btn d-inline-block mt-3 me-3">
                    <Link to="/explore-collections">Explore items</Link>
                  </div>
                  <div className="hero-secondary-btn d-inline-block mt-3">
                    <Link to="/wallet">Connect wallet</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="hero-right-img">
                <img
                  src="assets/img/home2/hero-art.png"
                  alt="hero"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-social">
        <a href="#!">
          <span>
            <i className="fab fa-facebook-f"></i>
          </span>
          facebook
        </a>
        <a href="#!">
          <span>
            <i className="fab fa-twitter"></i>
          </span>
          twitter
        </a>
        <a href="#!">
          <span>
            <i className="fab fa-twitter"></i>
          </span>
          dribbble
        </a>
      </div>
    </section>
  );
};

export default HeroTwo;
