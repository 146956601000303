import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Activity from "../pages/Activity";
import BlogDetails from "../pages/blog-details/BlogDetails";
import BlogGrid from "../pages/blog-grid/BlogGrid";
import BlogList from "../pages/blog-list/BlogList";
import Contact from "../pages/Contact";
import CreateItem from "../pages/CreateItem";
import CreatorProfile from "../pages/CreatorProfile";
import Collections from "../pages/Collections";
import Faqs from "../pages/Faqs";
import HomeOne from "../pages/home-one/HomeOne";
import HowItWorks from "../pages/HowItWorks";
import NotFound from "../pages/NotFound";
import ProductDetails from "../pages/ProductDetails";
import Wallet from "../pages/Wallet";
import ExploreCollection from "../pages/ExploreCollection";
import HomeTwo from "../pages/home-two/HomeTwo";
import { HomeThree } from "../pages/home-three/HomeThree";
import LiveAuction from "../pages/LiveAuction";
import AuctionItemDetails from "../pages/AuctionItemDetails";
import ScrollToTop from "../components/common/ScrollToTop";
import Creators from "../pages/Creators";
import TradeCommodity from "../pages/TradeCommodity";

import ProductDNA2 from "../pages/ProductDNA2";
import ProductDNA3 from "../pages/ProductDNA3";
import ProductNBA1 from "../pages/ProductNBA1";
import ProductNBA2 from "../pages/ProductNBA2";
const Routers = () => {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomeOne />} />
          <Route path="/home-two" element={<HomeTwo />} />
          <Route path="/home-three" element={<HomeThree />} />
          <Route path="/activity" element={<Activity />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/collections" element={<Collections />} />
          <Route path="/explore-collections" element={<ExploreCollection />} />
          <Route path="/creators" element={<Creators />} />
          <Route path="/creator-profile" element={<CreatorProfile />} />
          <Route path="/live-auction" element={<LiveAuction />} />
          <Route path="/faq" element={<Faqs />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/TradeCommodity" element={<TradeCommodity />} />
          <Route path="/create-item" element={<CreateItem />} />
          <Route path="/product-details" element={<ProductDetails />} />

          <Route path="/product-NBA1" element={<ProductNBA1 />} />
          <Route path="/product-NBA2" element={<ProductNBA2 />} />
          <Route path="/product-DNA2" element={<ProductDNA2/>} />
          <Route path="/product-DNA3" element={<ProductDNA3/>} />

          <Route
            path="/product-auction-details"
            element={<AuctionItemDetails />}
          />
          <Route path="/blog-grid" element={<BlogGrid />} />
          <Route path="/blog-list" element={<BlogList />} />
          <Route path="/blog-details" element={<BlogDetails />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Routers;
