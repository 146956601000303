import React from "react";
import { Link } from "react-router-dom";

const CollectionAlbumTwo = () => {
  return (
    <div className="trending-nft ptb-120">
      <div className="container">
        <div className="title-top">
          <h2>Trending NFTs Collection.</h2>
        </div>
        <div className="trending-collection">
          <div className="row g-4 justify-content-center">
            <div className="col-lg-4 col-sm-6">
              <div className="trending-column">
                <div className="column-wrapper">
                  <div className="top-thumbnails">
                    <div className="row">
                      <div className="col">
                        <div className="figure-img">
                          <Link to="/product-details">
                            <img
                              src="assets/img/gallery-1.jpg"
                              className="img-fluid"
                              alt="gallery"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="col">
                        <div className="figure-img">
                          <Link to="product-details">
                            <img
                              src="assets/img/gallery-2.jpg"
                              className="img-fluid"
                              alt="gallery"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="figure-img">
                          <Link to="/product-details">
                            <img
                              src="assets/img/gallery-3.jpg"
                              className="img-fluid"
                              alt="gallery"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="profile-img">
                    <img
                      src="assets/img/profile-1.jpg"
                      className="img-fluid"
                      alt="profile"
                    />
                  </div>
                  <div className="bottom-title">
                    <div className="title-heading">
                      <Link to="/explore-collection">
                        <h6>Superplastic</h6>
                      </Link>
                    </div>
                    <div className="count-btn">
                      <span>10 items</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="trending-column">
                <div className="column-wrapper">
                  <div className="top-thumbnails">
                    <div className="row">
                      <div className="col">
                        <div className="figure-img">
                          <Link to="/product-details">
                            <img
                              src="assets/img/gallery-4.jpg"
                              className="img-fluid"
                              alt="gallery"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="col">
                        <div className="figure-img">
                          <Link to="/product-details">
                            <img
                              src="assets/img/gallery-5.jpg"
                              className="img-fluid"
                              alt="gallery"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="figure-img">
                          <Link to="/product-details">
                            <img
                              src="assets/img/gallery-6.jpg"
                              className="img-fluid"
                              alt="gallery"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="profile-img">
                    <img
                      src="assets/img/profile-2.jpg"
                      className="img-fluid"
                      alt="profile"
                    />
                  </div>
                  <div className="bottom-title">
                    <div className="title-heading">
                      <Link to="/explore-collection">
                        <h6>Apes trilogy</h6>
                      </Link>
                    </div>
                    <div className="count-btn">
                      <span>10 items</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="trending-column">
                <div className="column-wrapper">
                  <div className="top-thumbnails">
                    <div className="row">
                      <div className="col">
                        <div className="figure-img">
                          <Link to="/product-details">
                            <img
                              src="assets/img/gallery-7.jpg"
                              className="img-fluid"
                              alt="gallery"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="col">
                        <div className="figure-img">
                          <Link to="/product-details">
                            <img
                              src="assets/img/gallery-8.jpg"
                              className="img-fluid"
                              alt="gallery"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="figure-img">
                          <Link to="/product-details">
                            <img
                              src="assets/img/gallery-9.jpg"
                              className="img-fluid"
                              alt="gallery"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="profile-img">
                    <img
                      src="assets/img/profile-3.jpg"
                      className="img-fluid"
                      alt="profile"
                    />
                  </div>
                  <div className="bottom-title">
                    <div className="title-heading">
                      <Link to="/explore-collection">
                        <h6>Pixelmon</h6>
                      </Link>
                    </div>
                    <div className="count-btn">
                      <span>10 items</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="explore-btn">
            <div className="primary-btn">
              <Link to="/explore-collections">Explore more</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionAlbumTwo;
